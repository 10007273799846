import { IonCard } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import IEstate from '../../interfaces/IEstate';
import { capitalize } from '../../utils/util';
import { getEstateStatus } from '../../utils/estateUtil';
import ImageFallback from '../core/ImageFallback';
import './EstateCard.scss';
import StatusLabel from '../base/StatusLabel';

const EstateCard: React.FC<IEstate> = (estate) => {
    const history = useHistory();
    const viewEstate = () => {
        history.push(`/estate/${estate.id}/overview`);
    };

    const getParsedData = () => {
        let data: any = {};
        data.name = estate.name;
        data.pictureUrl =
            estate.pictures && estate.pictures.length > 0 ? estate.pictures[0].urlLarge : './assets/img/house-noimage.png';
        data.agencyPicture = estate.officeLogoUrl || estate.clientLogoUrl;
        data.zipCity = estate.zip || '';
        if (estate.city) {
            data.zipCity += ' ' + estate.city;
        }
        if (!data.zipCity) {
            data.zipCity = '-';
        }
        data.address = estate.address || '';
        if (estate.number) {
            data.address += ' ' + estate.number;
        }
        if (!estate.address) {
            data.address = '-';
        }
        data.status = getEstateStatus(estate);
        return data;
    };
    const estateCardData = getParsedData();

    return (
        <IonCard className="estate-card" onClick={viewEstate}>
            <div className="card-header">
                <ImageFallback
                    className="estate-img"
                    alt={estateCardData.name}
                    src={estateCardData.pictureUrl || ''}
                    fallback="./assets/img/house-noimage.png"
                />
            </div>
            <div className="card-content">
                <div className="card-title">{estateCardData.address}</div>
                <div className="card-subtitle">{estateCardData.zipCity}</div>
            </div>
            <div className="card-footer">
                <div className="status-wrapper">
                    <StatusLabel label={capitalize(estateCardData.status.name)} color={estateCardData.status.color} />
                    {/* <StatusLabel label={capitalize(estateCardData.status.name)} color={estateCardData.status.color} /> */}
                </div>
                <div className="agency-logo">
                    <ImageFallback
                        alt={estateCardData.name}
                        src={estateCardData.agencyPicture}
                        fallback="./assets/img/no-photo-available.png"
                    />
                </div>
            </div>
        </IonCard>
    );
};

export default EstateCard;
