import { useTranslation } from 'react-i18next';
import {
    Check24,
    Cross,
    DocumentIcon,
    Edit24,
    EmailIcon,
    EstateMail,
    FileAdd,
    HistoryImportIcon,
    Info24,
    Smartphone,
    VerticalDots24
} from '../../assets/svg-icons';
import { capitalize, isMobile } from '../../utils/util';
import './HistoryItem.scss';

const HistoryItem: React.FC<{
    activity: any;
    index: number;
    isLast: boolean;
}> = ({ activity, index, isLast }) => {
    const { t } = useTranslation();

    /**
     * Function used to get the proper history icon
     *
     * @param {string} icon the activity icon type
     * @param {string} color the color of the icon
     * @param {string} text the initials of the related history type
     */
    const getHistoryIcon = (icon: string, color?: string, text?: string) => {
        let historyIcon;
        switch (icon) {
            case 'mail':
                historyIcon = <EmailIcon color={color ? color : '#006AC9'} />;
                break;

            case 'smartphone':
                historyIcon = <Smartphone color={color ? color : '#006AC9'} />;
                break;

            case 'house':
                historyIcon = <EstateMail color={color ? color : '#006AC9'} />;
                break;

            case 'dots':
                historyIcon = <VerticalDots24 color={color ? color : '#006AC9'} />;
                break;

            case 'import':
                historyIcon = <HistoryImportIcon color={color ? color : '#006AC9'} />;
                break;

            case 'file':
                historyIcon = <FileAdd color={color ? color : '#006AC9'} />;
                break;

            case 'info':
                historyIcon = <Info24 color={color ? color : '#006AC9'} />;
                break;

            case 'check':
                historyIcon = <Check24 color={color ? color : '#006AC9'} />;
                break;

            case 'close':
                historyIcon = <Cross color={color ? color : '#006AC9'} />;
                break;

            case 'edit':
                historyIcon = <Edit24 color={color ? color : '#006AC9'} />;
                break;

            case 'text':
                historyIcon = (
                    <span className="history-text-icon" style={{ color: color }}>
                        {text}
                    </span>
                );
                break;

            default:
                historyIcon = <DocumentIcon color={color ? color : '#006AC9'} />;
                break;
        }
        return historyIcon;
    };

    return isMobile() ? (
        <div key={activity.id} className="history-item-wrapper history-item-mobile">
            <div className="history-item history-item-mobile">
                <div className="history-icon history-icon-mobile p-mr-3">
                    {getHistoryIcon(activity.icon.icon, activity.icon.color, activity.icon.actionInitials)}
                </div>

                <div className="history-info-mobile" id={'activity-' + index}>
                    <div className="info-header">
                        <div className="info-title">{activity.subject}</div>
                        {activity.type === 'calendar' && <div className="info-message">{activity.message}</div>}
                    </div>

                    <div className="info-footer">
                        <div className="info-user">{activity.userName}</div>
                        <div className="info-date">
                            <span>{activity.date}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div key={activity.id} className={isLast ? 'last-item history-item-wrapper' : 'history-item-wrapper'}>
            <div className="history-item">
                <span className="app-tooltip history-icon history-icon-desktop p-mr-3" data-tooltip-content={activity.tooltip}>
                    {getHistoryIcon(activity.icon.icon, activity.icon.color, activity.icon.actionInitials)}
                </span>

                <div className="history-info-desktop" id={'activity-' + index}>
                    <div className="info-header">
                        <div className="info-date">
                            <span>
                                {capitalize(t('Date'))}: {activity.date}
                            </span>
                        </div>
                    </div>

                    <div className="info-title">{activity.subject}</div>
                    {activity.type === 'calendar' && <div className="info-message">{activity.message}</div>}

                    <div className="info-user">{activity.userName}</div>
                </div>
            </div>

            <div className={'history-separator' + (isLast ? ' is-last' : '')}></div>
        </div>
    );
};

export default HistoryItem;
