import { useIonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../utils/util';
interface IDialog {
    type: 'success' | 'info' | 'confirm' | 'warn' | 'error';
    title?: string;
    message?: string;
}

const useDialog = () => {
    const [presentAlert] = useIonAlert();
    const { t } = useTranslation();

    const setDialog = (params: IDialog) => {
        const getDefaultTitle = () => {
            let title = '';
            switch (params.type) {
                case 'success':
                    title = t('A_TitleSuccess');
                    break;

                case 'info':
                    title = t('A_TitleInfo');
                    break;

                case 'warn':
                    title = t('A_TitleWarn');
                    break;

                case 'confirm':
                    title = t('A_TitleConfirm');
                    break;

                case 'error':
                    title = t('A_TitleError');
                    break;
            }
            return title;
        };

        return new Promise(async (resolve) => {
            return presentAlert({
                header: params.title || getDefaultTitle(),
                message: params.message || '',
                buttons:
                    params.type === 'confirm'
                        ? [
                              {
                                  text: capitalize(t('Cancel')),
                                  role: 'cancel',
                                  handler: () => resolve(false)
                              },
                              {
                                  text: capitalize(t('Confirm')),
                                  role: 'confirm',
                                  handler: () => resolve(true)
                              }
                          ]
                        : [
                              {
                                  text: capitalize(t('Ok')),
                                  role: 'confirm',
                                  handler: () => resolve(true)
                              }
                          ]
            });
        });
    };
    return setDialog;
};
export default useDialog;
