import { capitalize } from '../../utils/util';
const style = {
    padding: '3px 13px',
    borderRadius: '3px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px'
};
const StatusLabel: React.FC<{ label: string; color: string }> = ({ label, color }) => {
    return (
        <div className="status-label" style={{ ...style, color, backgroundColor: color + '33' }}>
            {capitalize(label)}
        </div>
    );
};
export default StatusLabel;
