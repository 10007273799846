import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import { ActivityIcon, DocumentIcon, HouseIcon, OfferIcon, OverviewIcon } from '../../assets/svg-icons';
import { isMobile, capitalize } from '../../utils/util';
import './EstateDesktopNav.scss';
const EstateDesktopNav: React.FC = () => {
    const { t } = useTranslation();
    const { estateId } = useParams<{ estateId: string }>();
    const history = useHistory();
    if (isMobile()) {
        return <></>;
    }
    const goToView = (view: string) => history.push(`/estate/${estateId}/${view}`);
    const pageName = window.location.pathname.split('/')[3];

    return (
        <div className="profile-buttons">
            <IonButton
                className={pageName === 'overview' ? 'profile-button selected' : 'profile-button'}
                fill="clear"
                size="small"
                onClick={() => goToView('overview')}
            >
                {<OverviewIcon />}
                <span className="button-label">{t('MRS_Overview')}</span>
            </IonButton>
            <IonButton
                className={pageName === 'view' ? 'profile-button selected' : 'profile-button'}
                fill="clear"
                size="small"
                onClick={() => goToView('view')}
            >
                {<HouseIcon />}
                <span className="button-label">{capitalize(t('MRS_Property'))}</span>
            </IonButton>
            <IonButton
                className={pageName === 'document' ? 'profile-button selected' : 'profile-button'}
                fill="clear"
                size="small"
                onClick={() => goToView('document')}
            >
                {<DocumentIcon />}
                <span className="button-label">{capitalize(t('MRS_Document'))}</span>
            </IonButton>
            <IonButton
                className={pageName === 'offers' ? 'profile-button selected' : 'profile-button'}
                fill="clear"
                size="small"
                onClick={() => goToView('offers')}
            >
                {<OfferIcon />}
                <span className="button-label">{t('MRS_Offers')}</span>
            </IonButton>
            <IonButton
                className={pageName === 'activity' ? 'profile-button selected' : 'profile-button'}
                fill="clear"
                size="small"
                onClick={() => goToView('activity')}
            >
                {<ActivityIcon />}
                <span className="button-label">{t('MRS_Activity')}</span>
            </IonButton>
        </div>
    );
};
export default EstateDesktopNav;
