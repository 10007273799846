import { useContext } from 'react';
import useSafeEffect from './useSafeEffect';
import AppContext from '../contexts/AppContext';
/**
 * hook used to catch the event emitted by IonRefresher
 *
 * @param {Function} setRefreshDone callback function
 * @param {string} pathName path
 * @param {string} [type] type
 */
const useIonRefresher = (setRefreshDone: Function, pathName: string, type?: string) => {
    const appState = useContext(AppContext);
    const urlData = window.location.pathname.split('/');
    return useSafeEffect(() => {
        if (!appState?.state.refreshEvent) {
            return;
        }
        if (!setRefreshDone) {
            appState?.finalizeRefreshEvent();
            return;
        }
        if (urlData[1] !== pathName) {
            return;
        }
        if (pathName === 'estate' && urlData[3] !== type) {
            return;
        }
        setRefreshDone(appState?.finalizeRefreshEvent);
    }, [appState?.state.refreshEvent]);
};
export default useIonRefresher;
