import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';
import { capitalize, formatUserPrice, isMobile, toAddressLine1, toAddressLine2 } from '../../../utils/util';
import { getEstateStatus, getEstateFeatures } from '../../../utils/estateUtil';
import './MyProperty.scss';
import i18n from '../../../lib/i18n';
import ImageSwiper from '../../base/ImageSwiper';
import EstateMainContext from '../../../contexts/EstateMainContext';
import StatusLabel from '../../base/StatusLabel';
import { LocationPin, PlayIcon, VirtualTourIcon } from '../../../assets/svg-icons';
import UserProfile from '../../../lib/userProfile';
import useIonRefresher from '../../../hooks/useIonRefresher';

interface IMyProperty {
    reloadEstateData: () => void;
}

const MyProperty: React.FC<IMyProperty> = ({ reloadEstateData }) => {
    const descriptionRef = useRef<any>();
    const { t } = useTranslation();
    const estateData = useContext(EstateMainContext);
    const estateStatus = getEstateStatus(estateData);
    const estateDescription = estateData.longDescription?.find((x: any) => x.languageId === UserProfile.LanguageId);
    const estateFeatures = getEstateFeatures(estateData, t);
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [readMoreOn, setReadMoreOn] = useState(false);
    const [readMoreLessVisible, setReadMoreLessVisible] = useState(false);
    let energyClassUrl;
    if (estateData.energyClass) {
        energyClassUrl = `${UserProfile.WhiseUrl}/ec/small_${estateData.energyClass}`;
    }

    useIonRefresher(
        async (seRefreshDone: Function) => {
            await reloadEstateData();
            seRefreshDone();
        },
        'estate',
        'view'
    );

    const getSubdetailValue = (subdetail: { id: any; type: any; group?: string; label?: string; value: any }) => {
        let value = subdetail.value;

        if (subdetail.type === 'yes/no') {
            value = value === '1' ? capitalize(t('Yes')) : capitalize(t('No'));
        }

        if (subdetail.type === 'datetime') {
            value = value.slice(0, 10);
        }

        if (subdetail.type === 'length') {
            value += ' m';
        }

        if (subdetail.type === 'surface') {
            value += ' m²';
        }

        if (subdetail.type === 'volume') {
            value += ' m³';
        }

        if (subdetail.type === 'price') {
            value += ' €';
        }

        if (subdetail.type === 'liter') {
            value += ' l';
        }

        if (subdetail.id === 2089) {
            value += ' kwh/m²/y';
        }

        return value;
    };
    useEffect(() => {
        if (!isMounted && estateData.longDescription && estateDescription) {
            setIsMounted(true);
        }
        //eslint-disable-next-line
    }, [estateDescription, estateData]);

    useEffect(() => {
        if (descriptionRef?.current?.scrollHeight > 0 && isMounted) {
            let value = false;
            if (descriptionRef?.current?.scrollHeight > 165) {
                value = true;
            }
            setReadMoreLessVisible(value);
        }
        //eslint-disable-next-line
    }, [isMounted]);

    return (
        <div className="my-property">
            <IonGrid className="ion-no-padding">
                {estateData?.pictures && (
                    <IonRow className={'page-section' + (isMobile() ? ' mobile' : '')}>
                        <IonCol>
                            <IonCard className="pictures-card custom-card">
                                <IonCardContent>
                                    <ImageSwiper
                                        urls={estateData.pictures}
                                        imagesPerView={3}
                                        initialSlide={estateData.pictures.length > 1 ? 1 : 0}
                                    />
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                )}
                <IonRow className={'page-section' + (isMobile() ? ' mobile' : '')}>
                    <IonCol size="12" sizeXl={!isMobile() && estateFeatures && estateFeatures.length > 0 ? '9' : '12'}>
                        <IonCard className="property-card custom-card">
                            <IonCardContent>
                                <div className="margin-bottom" style={{ marginTop: '8px' }}>
                                    {estateData.linkVirtualVisit && (
                                        <IonButton className="link-button" fill="clear">
                                            <PlayIcon />
                                            <a target="_blank" href={estateData.linkVirtualVisit} rel="noreferrer">
                                                {t('MRS_PlayVideo')}
                                            </a>
                                        </IonButton>
                                    )}
                                    {estateData.link3DModel && (
                                        <IonButton className="link-button" fill="clear">
                                            <VirtualTourIcon />
                                            <a target="_blank" href={estateData.link3DModel} rel="noreferrer">
                                                {t('MRS_TakeVirtualTour')}
                                            </a>
                                        </IonButton>
                                    )}
                                </div>
                                <div className="estate-info">
                                    <div className="space-between-align-center margin-bottom">
                                        <div className={'estate-name trim-overflow' + (isMobile() ? ' mobile' : '')}>
                                            {estateData.name}
                                        </div>
                                        <div className={'price' + (isMobile() ? ' mobile' : '')}>
                                            {estateData &&
                                                estateData.price &&
                                                formatUserPrice(estateData.price, null, estateData.currency)}
                                        </div>
                                    </div>
                                    {isMobile() && (
                                        <div className="address margin-bottom">
                                            <LocationPin />
                                            <div className="address-text">
                                                <div>
                                                    {estateData &&
                                                        toAddressLine1(estateData.address, estateData.number, estateData.box)}
                                                </div>
                                                <div>{estateData && toAddressLine2(estateData.zip, estateData.city)}</div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="space-between-align-center">
                                        {!isMobile() && (
                                            <div className="address">
                                                <LocationPin />
                                                <div className="address-text">
                                                    {estateData &&
                                                        toAddressLine1(estateData.address, estateData.number, estateData.box) +
                                                            ' - '}
                                                    {estateData && toAddressLine2(estateData.zip, estateData.city)}
                                                </div>
                                            </div>
                                        )}
                                        <StatusLabel label={capitalize(estateStatus.name)} color={estateStatus.color} />
                                    </div>
                                </div>
                                {isMobile() && ((estateFeatures && estateFeatures.length > 0) || energyClassUrl) && (
                                    <IonGrid className="features-mobile">
                                        <IonRow>
                                            {energyClassUrl && (
                                                <IonCol
                                                    size="12"
                                                    className={'features-item' + (isMobile() ? ' mobile' : '')}
                                                    key="energyClass"
                                                >
                                                    <div className="features-content">
                                                        {t('EnergyCertificates') + ': '}
                                                        <img src={energyClassUrl} alt=""></img>
                                                    </div>
                                                </IonCol>
                                            )}
                                            {estateFeatures.map((x) => {
                                                return (
                                                    <IonCol
                                                        size="12"
                                                        className={'features-item' + (isMobile() ? ' mobile' : '')}
                                                        key={x.id}
                                                    >
                                                        <div className="features-content">
                                                            {x.icon()} {x.label + ': '} {x.value}
                                                        </div>
                                                    </IonCol>
                                                );
                                            })}
                                        </IonRow>
                                    </IonGrid>
                                )}
                                <div className="divider"></div>
                                {estateDescription && (
                                    <div className="description">
                                        <div className="description-title">{capitalize(t('Description'))}</div>
                                        <div
                                            ref={descriptionRef}
                                            className={
                                                readMoreOn ? 'description-content expanded' : 'description-content collapsed'
                                            }
                                            dangerouslySetInnerHTML={{ __html: estateDescription.content }}
                                        ></div>
                                        {readMoreLessVisible && (
                                            <>
                                                {!readMoreOn && <div className="description-gradient"></div>}
                                                <div className="read-more">
                                                    <span onClick={() => setReadMoreOn(!readMoreOn)}>
                                                        {readMoreOn ? t('MRS_ReadLess') : t('MRS_ReadMore')}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                                {estateData.details && (
                                    <div className="subdetails">
                                        <div className="description-title">{capitalize(t('Subdetails'))}</div>
                                        <div className="subdetail-list">
                                            {estateData.details.map((x, index) => {
                                                let value = getSubdetailValue(x);

                                                if (x.id === 1849 || x.id === 1850) {
                                                    return null;
                                                }

                                                return (
                                                    <div
                                                        key={'subdetail-' + index}
                                                        className="subdetail-list-item space-between-align-center"
                                                    >
                                                        <span>{capitalize(x.label)}</span>
                                                        <span>{value}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    {!isMobile() && ((estateFeatures && estateFeatures.length > 0) || energyClassUrl) && (
                        <IonCol size="12" sizeXl="3">
                            <IonCard
                                className={'feature-card custom-card no-margin-on-small-screen' + (isMobile() ? ' mobile' : '')}
                            >
                                <IonCardHeader>
                                    <IonCardTitle>{capitalize(i18n.t('MainFeatures'))}</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {estateFeatures && (
                                        <div className="features-desktop">
                                            {energyClassUrl && (
                                                <div className="features-item" key="energyClass">
                                                    <div className="features-label">{t('EnergyCertificates')}</div>
                                                    <div className="features-content">
                                                        <img className="energy-class" src={energyClassUrl} alt=""></img>
                                                    </div>
                                                </div>
                                            )}
                                            {estateFeatures.map((x) => {
                                                return (
                                                    <div className="features-item" key={x.id}>
                                                        <div className="features-label">{x.label}</div>
                                                        <div className="features-content">
                                                            <span className="features-icon">{x.icon()}</span>
                                                            <span className="features-value">{x.value}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    )}
                </IonRow>
            </IonGrid>
        </div>
    );
};

export default MyProperty;
