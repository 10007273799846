import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NoResults from '../base/NoResults';
import { WhiseLogo } from '../../assets/svg-icons';
import { capitalize } from '../../utils/util';
import './NoAccessPage.scss';
import { logout } from '../../utils/appUtil';

const NoAccessPage: React.FC = () => {
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            logout();
        }, 1000);
    }, []);

    return (
        <div className="no-access-page">
            <NoResults title={capitalize(t('NA_A_NoAccess'))} info={''} icon={<WhiseLogo />} iconClassName="no-access-icon" />
        </div>
    );
};

export default NoAccessPage;
