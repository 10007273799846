import { IonIcon } from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import { isMobile } from '../../utils/util';

interface ITooltipInfo {
    text: string;
}

const style = { fontSize: '22px', color: '#006ac9', marginLeft: '3px' };

const TooltipInfo: React.FC<ITooltipInfo> = ({ text }) => {
    return isMobile() ? (
        <></>
    ) : (
        <IonIcon
            icon={helpCircleOutline}
            style={style}
            className="app-tooltip"
            // data-tooltip-content={text}
            data-tooltip-html={text}
            data-tooltip-variant="info"
        />
    );
};
export default TooltipInfo;
