import IEstate from '../interfaces/IEstate';
import Service from './_base';
import { trackPromise } from 'react-promise-tracker';
import IDocument from '../interfaces/IDocument';

class EstateService extends Service {
    async getEstatesData(): Promise<IEstate[]> {
        let estateData: any;
        try {
            estateData = await trackPromise(this.client.get('estates'));
        } catch {}
        let estates = estateData?.data?.estates || [];
        return estates;
    }

    async getEstateById(estateId: number): Promise<any | undefined> {
        let estate: any = await trackPromise(this.client.get('estates/info?estateId=' + estateId));
        return estate?.data;
    }

    async getEstateData(estateId: number): Promise<IEstate | undefined> {
        let estates: IEstate[] = await this.getEstatesData();
        return estates.find((x: IEstate) => x.id === estateId);
    }

    getEstateDataDemo(): any {
        return {
            purposeId: 1,
            purposeStatus: 1,
            rank: 4,
            displayStatusId: 1,
            categoryId: 1,
            id: 4746617,
            name: 'Estate or subproject level 2',
            officeId: 1,
            clientId: 1,
            subcategoryId: 1,
            parentId: 4746616,
            address1: 'Avenue de Tervueren',
            zip: '1040',
            city: 'Etterbeek',
            number: '13',
            countryId: 1,
            price: 111000,
            statusId: 1,
            pictureUrl: 'assets/img/estate_1.jpg',
            agencyPicture: 'assets/img/agency_1.jpg',
            agencyName: 'Label Immo',
            owner: {
                fullName: 'Joffrey Seynaeve',
                address: 'Rue de l’Antque',
                zip: '4500',
                city: 'Huy',
                number: '3',
                pictureUrl: 'assets/img/owner.jpg'
            }
        };
    }

    async getEstateDocuments(estateId: number): Promise<{
        isValidRequest: boolean;
        questionnaire: IDocument[];
        providedByOwner: IDocument[];
        providedByAgent: IDocument[];
    }> {
        let response: any;
        try {
            response = await trackPromise(
                this.client.post('documents/list', {
                    EstateId: estateId
                })
            );
        } catch {}

        return response?.data;
    }

    async deleteEstateDocument(estateId: number, documentId: number): Promise<{ isValidRequest: boolean }> {
        let response: any;
        try {
            response = await trackPromise(
                this.client.delete('documents/delete', {
                    data: {
                        EstateId: estateId,
                        DocumentIdList: [documentId]
                    }
                })
            );
        } catch {}

        return response?.data;
    }

    async getMatchCount(body: any) {
        let response: any;
        try {
            response = await trackPromise(this.client.post(`estates/marketinfo/matchingcount`, body));
        } catch {}

        return response?.data;
    }

    async getNearbyEstates(body: any) {
        let response: any;
        try {
            response = await trackPromise(this.client.post(`estates/marketinfo/nearbyestates`, body));
        } catch {}

        return response?.data;
    }
    async getNearbyEstatesMap(body: any) {
        let response: any;
        try {
            response = await trackPromise(this.client.post(`estates/marketinfo/nearbyestates/map`, body));
        } catch {}

        return response?.data;
    }
    async getNearbyEstateInfo(body: any) {
        let response: any;
        try {
            response = await trackPromise(this.client.post(`estates/marketinfo/nearbyestates/info`, body));
        } catch {}

        return response?.data;
    }
}
//eslint-disable-next-line
export default new EstateService();
