import { useParams, useHistory } from 'react-router-dom';
import { IonTabBar, IonTabButton } from '@ionic/react';
import { HouseIcon, DocumentIcon, OfferIcon, OverviewIcon, ActivityIcon } from '../../assets/svg-icons';
import { isIOS, isMobile } from '../../utils/util';
import './EstateMobileNav.scss';

const EstateMobileNav: React.FC = () => {
    const { estateId, type } = useParams<{ estateId: string; type: string }>();
    const history = useHistory();
    if (!isMobile() || !window.location.pathname.includes('estate')) {
        return <></>;
    }
    const goTo = (section: string) => {
        history.push(`/estate/${estateId}/${section}`);
    };
    return (
        <div>
            <IonTabBar slot="bottom" className={'estate-mobile-nav' + (isIOS() === true ? ' ios-device' : '')}>
                <IonTabButton
                    className={type === 'overview' ? 'tab-selected' : ''}
                    tab="overview"
                    onClick={() => goTo('overview')}
                >
                    <OverviewIcon />
                </IonTabButton>

                <IonTabButton className={type === 'view' ? 'tab-selected' : ''} tab="view" onClick={() => goTo('view')}>
                    <HouseIcon />
                </IonTabButton>

                <IonTabButton
                    className={type === 'document' ? 'tab-selected' : ''}
                    tab="document"
                    onClick={() => goTo('document')}
                >
                    <DocumentIcon />
                </IonTabButton>

                <IonTabButton className={type === 'offers' ? 'tab-selected' : ''} tab="offers" onClick={() => goTo('offers')}>
                    <OfferIcon />
                </IonTabButton>

                <IonTabButton
                    className={type === 'activity' ? 'tab-selected' : ''}
                    tab="activity"
                    onClick={() => goTo('activity')}
                >
                    <ActivityIcon />
                </IonTabButton>
            </IonTabBar>
        </div>
    );
};
export default EstateMobileNav;
