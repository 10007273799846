import GeneralLayout from '../components/core/GeneralLayout';
import NoAccessPage from '../components/core/NoAccessPage';
import Profile from '../components/profile/Profile';
import useDocumentTitle from '../hooks/useDocumentTitle';
import UserProfile from '../lib/userProfile';

const ProfilePage: React.FC = () => {
    useDocumentTitle();
    return UserProfile.AgentAccessInfo ? (
        <NoAccessPage />
    ) : (
        <GeneralLayout>
            <Profile />
        </GeneralLayout>
    );
};

export default ProfilePage;
