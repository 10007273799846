import { capitalize, getInitials, toFullName } from './util';
import { LogCategory } from '../commons';
import i18next from 'i18next';
import moment from 'moment';

export const getActivityIcon = (categoryId: number, actionName: string, type: string) => {
    let icon = '',
        color = '',
        actionInitials = '';

    switch (type) {
        case 'audit':
            icon = 'edit';
            color = '#3C88D4';
            break;

        case 'calendar':
            icon = 'text';
            actionInitials = getInitials(actionName).toUpperCase();
            color = '#3C88D4';
            break;

        case 'history':
            switch (categoryId) {
                case LogCategory.MailSent:
                    icon = 'email';
                    color = '#3C88D4';
                    break;

                case LogCategory.AutomaticEmail:
                    icon = 'email';
                    color = '#3AB75D';
                    break;

                case LogCategory.AutomaticSMS:
                    icon = 'smartphone';
                    color = '#FE881F';
                    break;

                case LogCategory.ManualMatchingInfoSentByMail:
                    icon = 'house';
                    color = '#D52728';
                    break;

                case LogCategory.AutomaticMatching:
                    icon = 'house';
                    color = '#D52728';
                    break;

                case LogCategory.OtherAutomaticAction:
                    icon = 'dots';
                    color = 'green';
                    break;

                case LogCategory.ContactCreatedFromOwnWebsite:
                    icon = 'import';
                    color = '#D52728';
                    break;

                case LogCategory.ContactCreatedFromMediaWebsite:
                    icon = 'import';
                    color = '#D52728';
                    break;

                case LogCategory.DocumentCreated:
                case LogCategory.BrochureCreated:
                case LogCategory.AfficheCreated:
                case LogCategory.LetterCreated:
                case LogCategory.FaxCreated:
                case LogCategory.ESignature:
                    icon = 'file';
                    color = '#D52728';
                    break;

                case LogCategory.ContactCreatedByCleaningDoubles:
                case LogCategory.WH2_Automatic_Action:
                    icon = 'info';
                    color = 'green';
                    break;

                case LogCategory.ManualMediaActivation:
                    icon = 'check';
                    color = '#D52728';
                    break;

                case LogCategory.ManualMediaDeactivation:
                    icon = 'close';
                    color = '#D52728';
                    break;

                case LogCategory.ContactUnsubscribed:
                    icon = 'info';
                    color = '#D52728';
                    break;

                case LogCategory.Notification:
                    icon = 'info';
                    color = '#D52728';
                    break;
                case LogCategory.ContactUpdatedManually:
                    icon = 'import';
                    color = '#D52728';
                    break;
                case LogCategory.ContactUpdatedFromOwnWebsite:
                    icon = 'import';
                    color = '#D52728';
                    break;
                case LogCategory.ContactUpdatedFromColleagueWebsite:
                    icon = 'import';
                    color = '#D52728';
                    break;
                case LogCategory.ContactUpdatedFromMediaWebsite:
                    icon = 'import';
                    color = '#D52728';
                    break;
                case LogCategory.ContactUpdatedFromOtherApp:
                    icon = 'import';
                    color = '#D52728';
                    break;
                case LogCategory.ContactCreatedFromOtherApp:
                    icon = 'import';
                    color = '#D52728';
                    break;

                case LogCategory.ChecklistCreated:
                case LogCategory.ChecklistDeleted:
                    icon = 'text';
                    actionInitials = getInitials(actionName).toUpperCase();
                    color = '#000';
                    break;

                case LogCategory.TelephoneIngoingCall:
                    icon = 'text';
                    actionInitials = getInitials(actionName).toUpperCase();
                    color = '#A8A030';
                    break;

                case LogCategory.TelephoneOutgoingCall:
                    icon = 'text';
                    actionInitials = getInitials(actionName).toUpperCase();
                    color = '#EF0909';
                    break;

                default:
                    icon = 'dots';
                    break;
            }
            break;

        case 'media':
            icon = 'info';
            color = '#006ac9';
            break;

        case 'task':
            icon = 'text';
            let actionText = '';

            switch (actionName) {
                case 'assigned':
                    actionText = i18next.t('TaskAssigned');
                    break;

                case 'inprogress':
                    actionText = i18next.t('TaskInProgress');
                    break;

                case 'done':
                    actionText = i18next.t('TaskDone');
                    break;

                case 'cancelled':
                    actionText = i18next.t('TaskCancelled');
                    break;

                case 'closed':
                    actionText = i18next.t('TaskClosed');
                    break;

                default:
                    break;
            }

            actionInitials = getInitials(actionText).toUpperCase();
            color = '#3C88D4';
            break;

        default:
            break;
    }

    return { icon, color, actionInitials };
};

export const formatActivities = (activityList: any[]) => {
    let formattedActivities = activityList.map((x) => {
        let subject = x.subject;
        if (x.type === 'calendar') {
            let subjectParts = subject.split('-');
            // if (subjectParts[0]) {
            //     subject = subjectParts[0];
            // }

            subjectParts = subjectParts.filter((x: string) => !/\d/.test(x));
            subject = subjectParts.join('-');

            // // build acronym
            // if (subjectParts[1] && subjectParts.length > 2) {
            //     subject = subject + '-';

            //     const words = subjectParts[1].split(' ');
            //     let name = '';
            //     if (words[0].length === 0) {
            //         words.shift();
            //     }
            //     if (words[0]) {
            //         name += capitalize(words[0].toLowerCase());
            //     }
            //     if (words[1]) {
            //         name += ' ' + words[1][0].toUpperCase();
            //     }

            //     subject += ' ' + name;
            // }
        }

        return {
            message: x.message,
            subject: subject,
            date: moment(x.dateTime).format('DD/MM/YYYY HH:mm'),
            icon: getActivityIcon(
                x.categoryId ? x.categoryId : x.actionTypeId,
                x.category ? x.category : x.actionType ? x.actionType : '',
                x.type
            ),
            title: subject ? subject : x.message ? x.message : '',
            userName: x.representative ? toFullName(x.representative.firstName, x.representative.name) : '',
            description: x.message,
            type: x.type,
            tooltip: capitalize(x.category ? x.category : x.actionType ? x.actionType : '')
        };
    });

    return formattedActivities;
};
