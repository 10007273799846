import { useState, useRef, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { IonModal, IonHeader, IonToolbar, IonContent, IonButtons, IonButton, IonTitle, IonIcon, IonRange } from '@ionic/react';
import {
    addCircleOutline,
    removeCircleOutline,
    squareOutline,
    tabletLandscapeOutline,
    tabletPortraitOutline
} from 'ionicons/icons';
import './ImageCrop.scss';
import { capitalize, isIOS } from '../../utils/util';

enum Aspect {
    Portrait = 0.70921985815,
    Landscape = 1.41,
    Cube = 1
}

const ImageCrop = ({ isOpen, imageData, onConfirm, cropShape, onCancel }: any) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [aspect, setAspect] = useState(1);
    const [rotation, setRotation] = useState(0);
    const cropData = useRef<any>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isOpen) {
            setCrop({ x: 0, y: 0 });
            setZoom(1);
            setRotation(0);
            setAspect(1);
        }
    }, [isOpen]);

    const toggleAspect = () => {
        switch (aspect) {
            case Aspect.Cube:
                setAspect(Aspect.Portrait);
                break;

            case Aspect.Portrait:
                setAspect(Aspect.Landscape);
                break;

            case Aspect.Landscape:
                setAspect(Aspect.Cube);
                break;
        }
    };

    const onZoom = (value: number) => {
        if (zoom + value < 1 || zoom + value > 4) {
            return;
        }
        setZoom(zoom + value);
    };

    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        cropData.current = { croppedArea, croppedAreaPixels: { ...croppedAreaPixels, rotation } };
    };

    const confirm = () => {
        onConfirm(cropData.current.croppedAreaPixels);
    };

    const cancel = () => {
        onCancel();
    };

    const getAspectIcon = () => {
        switch (aspect) {
            case Aspect.Cube:
                return squareOutline;

            case Aspect.Portrait:
                return tabletPortraitOutline;

            case Aspect.Landscape:
                return tabletLandscapeOutline;
        }
    };

    const preventScroll = (ele: Element) => {
        const content = ele.closest('ion-content');
        if (content) {
            content.style.setProperty('--overflow', 'hidden');
        }
    };

    const allowScroll = (ele: Element) => {
        const content = ele.closest('ion-content');
        if (content) {
            content.style.setProperty('--overflow', 'inherit');
        }
    };

    return (
        <IonModal isOpen={isOpen}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={cancel}>{capitalize(t('Cancel'))}</IonButton>
                    </IonButtons>
                    <IonTitle>{t('Crop')}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={confirm}>
                            {capitalize(t('Confirm'))}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <div className={'crop-wrapper' + (isIOS() ? ' ios' : '')}>
                    <div className="crop-container">
                        <Cropper
                            image={imageData}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            cropShape={cropShape || 'rect'}
                            aspect={aspect || 1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                    <div className="controls">
                        <div className="range-input">
                            <div className="range-input-label">{t('Rotate')}</div>
                            <IonRange
                                min={-180}
                                max={180}
                                value={rotation}
                                onIonChange={({ detail }) => setRotation(detail.value as number)}
                                onIonKnobMoveStart={(e) => preventScroll(e.target)}
                                onIonKnobMoveEnd={(e) => allowScroll(e.target)}
                            ></IonRange>
                        </div>
                        <IonIcon icon={addCircleOutline} onClick={() => onZoom(1)} />
                        <IonIcon icon={removeCircleOutline} onClick={() => onZoom(-1)} />
                        <IonIcon icon={getAspectIcon()} onClick={toggleAspect} />
                    </div>
                </div>
            </IonContent>
        </IonModal>
    );
};

export default ImageCrop;
