import Service from './_base';
import { trackPromise } from 'react-promise-tracker';

class UsersService extends Service {

    async forgotPassword(email: string, returnUrl: string) {
        let response:any = await trackPromise(this.client.post('users/forgotpassword',{email, resetPageBaseUrl: returnUrl}));
        return response.data;
    }

    async resetPassword(email: string, token: string, password:string, confirmPassword:string){
        let response:any = await trackPromise(this.client.post('users/resetpassword',{email, token, password,confirmPassword}));
        return response.data;
    }
}
//eslint-disable-next-line
export default new UsersService();
