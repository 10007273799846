import { useTranslation } from 'react-i18next';
import { WhiseLogo } from '../../assets/svg-icons';
import './PoweredBy.scss';
const PoweredBy: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="powered-by-wrapper">
            <div className="powered-label">{t('A_PoweredBy')}</div>
            <WhiseLogo className="powered-logo" />
        </div>
    );
};
export default PoweredBy;
