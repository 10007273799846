import React, { useState, useRef } from 'react';
import { IonIcon, IonModal } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
// Import Swiper - START
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Pagination, Navigation, Zoom } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
// Import Swiper - END
import IEstatePicture from '../../interfaces/IEstatePicture';
import './ImageSwiper.scss';
import { isIOS, isMobile } from '../../utils/util';
import ImageFallback from '../core/ImageFallback';
import useSafeEffect from '../../hooks/useSafeEffect';

interface IImageSwiper {
    urls: IEstatePicture[];
    imagesPerView: number;
    fullScreenMode?: { isVisible: boolean; onClose: Function } | undefined;
    initialSlide: number;
}

const ImageSwiper: React.FC<IImageSwiper> = ({ urls = [], imagesPerView, fullScreenMode, initialSlide = 0 }) => {
    const [fullScreen, setFullScreen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(initialSlide);

    const history = useHistory();
    const modalRef = useRef<HTMLIonModalElement>(null);

    useSafeEffect(() => {
        fullScreenMode?.isVisible && openModal();
    }, [fullScreenMode?.isVisible]);

    const openModal = (index: number = 0) => {
        if (fullScreen) {
            return;
        }
        history.push(history.location.pathname + '?modalOpened=true');
        setActiveIndex(index);
        setFullScreen(true);
    };

    const closeModal = () => {
        history.goBack();
        setFullScreen(false);
        if (fullScreenMode) {
            setActiveIndex(0);
            fullScreenMode.onClose();
            return;
        }
        if (!isMobile() || imagesPerView !== 1) {
            let _newActiveIndex = activeIndex - 1;
            if (activeIndex === 0) {
                _newActiveIndex = 0;
            } else if (activeIndex === urls.length - 1) {
                _newActiveIndex = urls.length - imagesPerView + 1;
            }
            setActiveIndex(_newActiveIndex);
        }
        setActiveIndex(activeIndex);
    };

    const navigate = (direction: number) => {
        setActiveIndex(activeIndex + direction);
    };

    const dismiss = () => modalRef.current?.dismiss();

    const swiperComponent = (
        <Swiper
            initialSlide={activeIndex}
            slidesPerView={fullScreen || isMobile() ? 1 : imagesPerView}
            spaceBetween={30}
            keyboard={{
                enabled: true
            }}
            pagination={{
                clickable: true
            }}
            navigation={!isMobile()}
            modules={[Keyboard, Pagination, Navigation, Zoom]}
            className={isMobile() ? ' mobile' : ''}
            loop={true}
            zoom={true}
            onNavigationNext={() => navigate(1)}
            onNavigationPrev={() => navigate(-1)}
        >
            {urls?.length ? (
                urls.map((x, index) => (
                    <SwiperSlide key={index} onClick={() => openModal(index)} zoom={true}>
                        <ImageFallback
                            showBackground
                            src={fullScreen ? x.urlXXL || x.urlLarge || x.urlSmall : x.urlLarge || x.urlXXL || x.urlSmall}
                            alt={'img-' + index}
                            fallback="./assets/img/house-noimage.png"
                        />
                    </SwiperSlide>
                ))
            ) : (
                <SwiperSlide>
                    <ImageFallback alt={'no-image'} src="./assets/img/house-noimage.png" />
                </SwiperSlide>
            )}
        </Swiper>
    );

    return (
        <>
            <IonModal
                ref={modalRef}
                className={'swipper-fullscreen' + (isMobile() ? ' mobile' : '') + (isIOS() ? ' ios-device' : '')}
                isOpen={fullScreen}
                backdropDismiss={false}
                initialBreakpoint={isMobile() ? 1 : undefined}
                breakpoints={isMobile() ? [0, 1] : undefined}
                onWillDismiss={() => closeModal()}
            >
                {swiperComponent}
                <IonIcon className="close-btn" icon={closeOutline} onClick={dismiss}></IonIcon>
            </IonModal>
            {!fullScreenMode && !fullScreen ? swiperComponent : <></>}
        </>
    );
};
export default ImageSwiper;
