import { IonItem, IonModal } from '@ionic/react';
import { caretDown } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import AppTypeahead, { Item } from './AppTypeahead';

const ModalSelectInput: React.FC<{
    filters: string[] | undefined;
    setFilters: (arg0: string[]) => void;
    items: Item[];
    id: string;
    placeholder: string;
    modalTitle: string;
    isMultiSelect?: boolean;
}> = ({ filters, setFilters, items, id, placeholder, modalTitle, isMultiSelect = true }) => {
    const filterModal = useRef<HTMLIonModalElement>(null);
    const [selectedFiltersText, setSelectedFiltersText] = useState<string>('');

    useEffect(() => {
        if (filters && filters.length > 0) {
            filterChanged(filters);
        }
        //eslint-disable-next-line
    }, [filters]);

    const formatData = (data: string[]) => {
        let texts: string[] = [];
        for (let value of data) {
            let item = items.find((x: { value: string }) => x.value === value);
            if (item && item.text) {
                texts.push(item.text);
            }
        }

        return texts.join(', ');
    };

    const filterChanged = (value: string[]) => {
        setFilters(value);
        setSelectedFiltersText(formatData(value));
        filterModal.current?.dismiss();
    };

    return (
        <>
            <IonItem
                lines="none"
                button={true}
                detail={true}
                detailIcon={caretDown}
                id={id}
                className="filter-component filter-mobile"
            >
                {selectedFiltersText ? (
                    <span className="filter-text">{selectedFiltersText}</span>
                ) : (
                    <span className="filter-placeholder">{placeholder}</span>
                )}
            </IonItem>
            <IonModal trigger={id} ref={filterModal}>
                <AppTypeahead
                    title={modalTitle}
                    items={items}
                    selectedItems={filters || []}
                    onSelectionCancel={() => {
                        filterModal.current?.dismiss();
                    }}
                    onSelectionChange={filterChanged}
                    search={false}
                    isMultiselect={isMultiSelect}
                />
            </IonModal>
        </>
    );
};

export default ModalSelectInput;
