import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonIcon
} from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IActivity from '../../../interfaces/IActivity';
import { isMobile } from '../../../utils/util';
import { formatActivities } from '../../../utils/activityUtil';
import HistoryItem from '../../core/HistoryItem';
import activityService from '../../../services/activity';
import './Activities.scss';
import './ActivityFilter.scss';
import useSafeEffect from '../../../hooks/useSafeEffect';
import useIonRefresher from '../../../hooks/useIonRefresher';
import NoResults from '../../base/NoResults';
import { ActivityIcon } from '../../../assets/svg-icons';
import ModalSelectInput from '../../core/ModalSelectInput';
import { Item } from '../../core/AppTypeahead';
import { closeOutline } from 'ionicons/icons';

const Activities: React.FC<{ estateId: number }> = ({ estateId }) => {
    const [activities, setActivities] = useState<IActivity[]>();
    const [filters, setFilters] = useState<string[]>([]);
    const { t } = useTranslation();

    useSafeEffect(() => {
        load();
        //eslint-disable-next-line
        return () => {
            setActivities([]);
        };
        //eslint-disable-next-line
    }, [filters]);

    useIonRefresher(
        async (seRefreshDone: Function) => {
            await load();
            seRefreshDone();
        },
        'estate',
        'activity'
    );

    const load = async () => {
        let activityData: any[] = await activityService.getActivities(estateId, 30, filters);
        activityData = formatActivities(activityData);
        setActivities(activityData);
    };

    const filterItems: Item[] = [
        { text: t('Audit'), value: 'audit' },
        { text: t('Calendar'), value: 'calendar' },
        { text: t('History'), value: 'history' },
        { text: t('Media'), value: 'media' },
        { text: t('Task'), value: 'task' }
    ];

    return (
        <>
            <IonCard className={'page-section' + (isMobile() ? ' mobile' : '')}>
                <IonCardHeader className={isMobile() ? 'mobile' : 'desktop'}>
                    <IonCardTitle>{t('MRS_LastActions')}</IonCardTitle>
                    <div>
                        {isMobile() ? (
                            <ModalSelectInput
                                filters={filters}
                                setFilters={setFilters}
                                items={filterItems}
                                id="filter-activity-list"
                                placeholder={t('Filter')}
                                modalTitle={t('History')}
                            />
                        ) : (
                            <>
                                {filters?.length > 0 && (
                                    <IonButton fill="clear" className="filter-clear-button" onClick={() => setFilters([])}>
                                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                                    </IonButton>
                                )}
                                <IonSelect
                                    className="filter-component"
                                    placeholder={t('Filter')}
                                    multiple={true}
                                    onIonChange={(e) => setFilters(e.detail.value)}
                                    interface="popover"
                                    value={filters}
                                >
                                    <IonSelectOption value="audit">{t('Audit')}</IonSelectOption>
                                    <IonSelectOption value="calendar">{t('Calendar')}</IonSelectOption>
                                    <IonSelectOption value="history">{t('History')}</IonSelectOption>
                                    <IonSelectOption value="media">{t('Media')}</IonSelectOption>
                                    <IonSelectOption value="task">{t('Task')}</IonSelectOption>
                                </IonSelect>
                            </>
                        )}
                    </div>
                </IonCardHeader>
                <IonCardContent className="no-padding">
                    {activities?.length === 0 ? (
                        <NoResults icon={<ActivityIcon />} info={''} />
                    ) : (
                        <div
                            className="history-scrollable-listing"
                            // style={{ width: '100%', height: `calc(100vh - ${theme.dimensions.navbarHeight} - 171px)` }}
                        >
                            <div className="history-list">
                                {activities?.map((activity, index) => {
                                    return (
                                        <HistoryItem
                                            key={'history-' + index}
                                            activity={activity}
                                            index={index}
                                            isLast={index === activities.length - 1}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </IonCardContent>
            </IonCard>
        </>
    );
};
export default Activities;
