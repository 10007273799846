import { useEffect, useRef } from 'react';
const useSafeEffect = (callback: Function, dependencies: any[]) => {
    const isMountedRef = useRef({ isMounted: false, dependencies: '' });

    if (isMountedRef.current.dependencies !== JSON.stringify(dependencies)) {
        isMountedRef.current.isMounted = false;
        isMountedRef.current.dependencies = JSON.stringify(dependencies);
    }

    useEffect(() => {
        if (!isMountedRef.current.isMounted) {
            isMountedRef.current.isMounted = true;
            return callback();
        }
        return undefined;
        // eslint-disable-next-line
    }, dependencies);
};

export default useSafeEffect;
