import {
    IonButton,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonTitle,
    useIonLoading,
    useIonAlert
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addCircleOutline, closeOutline } from 'ionicons/icons';

import documentService from '../../../services/document';
import IQuestionGroup from '../../../interfaces/IQuestionGroup';

import { capitalize, cloneDeep, isMobile } from '../../../utils/util';
import IQuestionSingleChoice from '../../../interfaces/IQuestionSingleChoice';
import ISingleChoiceItem from '../../../interfaces/IQuestionSingleChoiceItem';

import { TrashIcon } from '../../../assets/svg-icons';
import { BaseDocumentType } from '../../../commons';
import IQuestion from '../../../interfaces/IQuestion';

import './Questionnaire.scss';

const Questionnaire: React.FC<{
    estateId: number;
    baseDocumentTypeId: number;
    questionnaireName: string;
    isVisible: boolean;
    onClose: any;
    onGeneratePdf: any;
    onQuestionnaireLoaded: any;
}> = ({ estateId, baseDocumentTypeId, questionnaireName, isVisible, onClose, onGeneratePdf, onQuestionnaireLoaded }) => {
    const [questionList, setQuestionList] = useState<IQuestionGroup[]>([]);
    const [questionnaireData, setQuestionnaireData] = useState<{ baseDocumentTypeId: number; languageId: string }>({
        baseDocumentTypeId: 0,
        languageId: 'en-GB'
    });
    const [scrollList, setScrollList] = useState<boolean>(false);
    const { t } = useTranslation();
    const [present, dismiss] = useIonLoading();
    const [showAlert] = useIonAlert();

    useEffect(() => {
        if (baseDocumentTypeId && estateId) {
            loadQuestionaire();
        }
        //eslint-disable-next-line
    }, [estateId, baseDocumentTypeId]);

    useEffect(() => {
        if (scrollList) {
            setTimeout(() => {
                let element = document.querySelector('.question-list');
                if (element) {
                    let item: any = element.querySelector('.question-item:last-child input');
                    if (item) {
                        item.focus();
                    }

                    if (element && element.shadowRoot) {
                        // mobile scrolling
                        let innerScroll = element.shadowRoot.querySelector('.inner-scroll');
                        if (innerScroll) {
                            innerScroll.scrollTop = innerScroll.scrollHeight;
                        }
                    } else {
                        // desktop scrolling
                        element.scrollTop = element.scrollHeight;
                    }
                }
            }, 200);

            setScrollList(false);
        }
        //eslint-disable-next-line
    }, [scrollList]);

    const loadQuestionaire = async () => {
        present({ message: t('Loading'), spinner: 'circles' });
        let qData = await documentService.getQuestionnaire(estateId, baseDocumentTypeId);
        setQuestionnaireData({ baseDocumentTypeId: qData.baseDocumentTypeId, languageId: qData.languageId });
        // if no group or no questions in the group
        if (
            baseDocumentTypeId === BaseDocumentType.ListOfItems &&
            (!qData.questionGroups || !qData.questionGroups[0].questions)
        ) {
            qData.questionGroups = [{ name: t('MRS_ListOfThings'), questions: [] }];
        }
        setQuestionList(qData.questionGroups);
        dismiss();
        setTimeout(() => {
            onQuestionnaireLoaded();
        }, 250);
    };

    const getSelectedAnswer = (groupIndex: number, questionIndex: number) => {
        let answerList = questionList[groupIndex].questions[questionIndex].singleChoice?.items || [];
        let answerIndex;
        for (answerIndex = 0; answerIndex < answerList.length; answerIndex++) {
            if (answerList[answerIndex].isChecked) {
                break;
            }
        }
        return getItemId(groupIndex, questionIndex, answerIndex);
    };
    const getItemId = (groupIndex: number, questionIndex?: number, answerIndex?: number): string => {
        let itemId = `group-${groupIndex}`;
        if (questionIndex !== undefined) {
            itemId += `-question-${questionIndex}`;
        }
        if (answerIndex !== undefined) {
            itemId += `-answer-${answerIndex}`;
        }
        return itemId;
    };
    const updateQuestionnaire = (groupIndex: number, questionIndex: number, value: string, updateQuestionName?: boolean) => {
        setQuestionList((prevState: IQuestionGroup[]) => {
            let nextState = cloneDeep(prevState);
            let questionItem = nextState[groupIndex].questions[questionIndex];
            if (questionItem.freeText) {
                questionItem.freeText.answer = value;
            } else if (questionItem.singleChoice) {
                if (updateQuestionName) {
                    questionItem.singleChoice.name = value;
                } else {
                    let matches = value.match(/-(\d)$/);
                    let selectedAnswerIndex = 0;
                    if (matches && matches.length > 1) {
                        selectedAnswerIndex = parseInt(matches[1]);
                    }

                    for (let answerIndex = 0; answerIndex < questionItem.singleChoice.items.length; answerIndex++) {
                        questionItem.singleChoice.items[answerIndex].isChecked = selectedAnswerIndex === answerIndex;
                    }
                }
            }
            return nextState;
        });
    };

    const addNewQuestion = () => {
        setQuestionList((prevState) => {
            // clone the question list
            let nextStateQuestions = prevState.length > 0 ? cloneDeep(prevState[0].questions) : [];
            let question: IQuestionSingleChoice = {
                name: '',
                items: [
                    {
                        name: capitalize(t('Yes'))
                    },
                    {
                        name: capitalize(t('No'))
                    },
                    {
                        name: capitalize(t('MRS_WithoutAnswer')),
                        isChecked: true
                    }
                ]
            };

            let questionItem: IQuestion = { singleChoice: question };
            nextStateQuestions.push(questionItem);

            let nextState = { name: t('MRS_ListOfThings'), questions: nextStateQuestions };
            return [nextState];
        });
        setScrollList(true);
    };

    const onDeleteQuestion = (questionIndex: number) => {
        showConfirmation(t('Warning'), t('MRS_ConfirmDeleteQuestionnaireQuestion'), () => {
            deleteQuestion(questionIndex);
        });
    };

    const deleteQuestion = (questionIndex: number) => {
        setQuestionList((prevState) => {
            // clone the question list
            let nextStateQuestions = prevState.length > 0 ? cloneDeep(prevState[0].questions) : [];
            nextStateQuestions.splice(questionIndex, 1);
            let nextState = { name: t('MRS_ListOfThings'), questions: nextStateQuestions };
            return [nextState];
        });
        setScrollList(true);
    };

    const renderQuestionList = () => {
        return (
            questionList &&
            questionList.map((x, groupIndex) => (
                <React.Fragment key={getItemId(groupIndex)}>
                    <IonCardHeader>
                        <IonCardTitle>
                            {groupIndex + 1}. {x.name}
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="no-padding">
                        {x.questions &&
                            x.questions.map((question, questionIndex) => (
                                <div className="question-item" key={getItemId(groupIndex, questionIndex)}>
                                    <div className="question-name">
                                        {question.singleChoice && !isUserItemList && (
                                            <span>
                                                <span className="question-number">
                                                    {groupIndex + 1}.{questionIndex + 1}
                                                </span>
                                                {question.singleChoice.name}
                                            </span>
                                        )}
                                        {question.singleChoice && isUserItemList && (
                                            <IonItem lines={isMobile() ? 'full' : 'none'}>
                                                <span className="question-number">
                                                    {groupIndex + 1}.{questionIndex + 1}
                                                </span>
                                                <IonInput
                                                    placeholder={t('MRS_CompleteQuestion')}
                                                    value={question.singleChoice.name}
                                                    onIonChange={(ev: any) =>
                                                        updateQuestionnaire(groupIndex, questionIndex, ev.target.value, true)
                                                    }
                                                ></IonInput>
                                                <IonButton
                                                    fill="clear"
                                                    onClick={() => {
                                                        onDeleteQuestion(questionIndex);
                                                    }}
                                                >
                                                    <TrashIcon color="#DB1B24" />
                                                </IonButton>
                                            </IonItem>
                                        )}
                                        {question.freeText && (
                                            <span>
                                                <span className="question-number">
                                                    {groupIndex + 1}.{questionIndex + 1}
                                                </span>
                                                {question.freeText.name}
                                            </span>
                                        )}
                                    </div>
                                    <div className="answer-list">
                                        {question.singleChoice && (
                                            <IonRadioGroup
                                                value={getSelectedAnswer(groupIndex, questionIndex)}
                                                className={isUserItemList ? 'user-item' : ''}
                                            >
                                                {question.singleChoice.items.map(
                                                    (answer: ISingleChoiceItem, answerIndex: number) => (
                                                        <IonItem
                                                            className="answer"
                                                            lines={isMobile() ? 'full' : 'none'}
                                                            key={getItemId(groupIndex, questionIndex, answerIndex)}
                                                        >
                                                            <IonRadio
                                                                value={getItemId(groupIndex, questionIndex, answerIndex)}
                                                                onClick={(ev: any) => {
                                                                    updateQuestionnaire(
                                                                        groupIndex,
                                                                        questionIndex,
                                                                        ev.target.value
                                                                    );
                                                                }}
                                                                slot="start"
                                                            />
                                                            <IonLabel>{answer.name}</IonLabel>
                                                        </IonItem>
                                                    )
                                                )}
                                            </IonRadioGroup>
                                        )}
                                        {question.freeText && (
                                            <IonItem lines={isMobile() ? 'full' : 'none'}>
                                                <IonInput
                                                    placeholder={t('MRS_YourAnswer')}
                                                    value={question.freeText.answer}
                                                    onIonChange={(ev: any) =>
                                                        updateQuestionnaire(groupIndex, questionIndex, ev.target.value)
                                                    }
                                                ></IonInput>
                                            </IonItem>
                                        )}
                                    </div>
                                </div>
                            ))}
                    </IonCardContent>
                </React.Fragment>
            ))
        );
    };

    const renderQuestionAdd = () => {
        if (baseDocumentTypeId !== BaseDocumentType.ListOfItems) {
            return null;
        }
        return (
            <IonButton className="add-new-item" fill="clear" onClick={addNewQuestion}>
                <IonIcon icon={addCircleOutline}></IonIcon>
                {t('MRS_AddNewListItem')}
            </IonButton>
        );
    };

    const onModalClose = () => {
        setQuestionList([]);
        onClose();
    };

    const validateQuestionnaireUserData = (questionGroupsData: IQuestionGroup[]): boolean => {
        if (baseDocumentTypeId !== BaseDocumentType.ListOfItems) {
            return true;
        }
        let isValid = true;
        for (let questionGroup of questionGroupsData) {
            if (questionGroup.questions && questionGroup.questions.length > 0) {
                for (let question of questionGroup.questions) {
                    if (
                        question.singleChoice &&
                        (!question.singleChoice.name || question.singleChoice.name.trim().length === 0)
                    ) {
                        isValid = false;
                        break;
                    }
                }
            }
        }
        if (!isValid) {
            showNotification(t('Error'), t('MRS_PleaseFillAllQuestions'));
        }
        return isValid;
    };

    const onSaveDraft = async () => {
        let questionGroupsSaveData = getQuestionGroupsSaveData();
        if (!validateQuestionnaireUserData(questionGroupsSaveData)) {
            return;
        }
        saveDraft(questionGroupsSaveData);
    };

    const showServerError = (response: any) => {
        let message = t('ErrorOperationFailed');
        if (response && response.validationErrors) {
            message = response.validationErrors.map((x: any) => '<div>' + x.message + '</div>').join('');
        }
        showNotification(t('Error'), message);
    };

    // for user dynamic data, trim the question names
    const getQuestionGroupsSaveData = (): IQuestionGroup[] => {
        if (baseDocumentTypeId !== BaseDocumentType.ListOfItems) {
            return questionList;
        }

        let questionGroups: IQuestionGroup[] = cloneDeep(questionList);
        for (let question of questionGroups[0].questions) {
            if (question.singleChoice) {
                question.singleChoice.name = question.singleChoice.name.trim();
            }
        }
        return questionGroups;
    };

    const saveDraft = async (questionGroupsSaveData: IQuestionGroup[], disableNotification?: boolean) => {
        let qData = {
            baseDocumentTypeId: questionnaireData.baseDocumentTypeId,
            languageId: questionnaireData.languageId,
            questionGroups: questionGroupsSaveData
        };

        let response = await documentService.saveQuestionnaireDraft(estateId, qData);
        if (response.isValidRequest) {
            if (!disableNotification) {
                showNotification(t('Success'), t('OperationCompletedSuccessfully'));
                onModalClose();
            }
            return true;
        } else {
            showServerError(response);
            return false;
        }
    };

    const onGeneratePdfClick = async () => {
        showConfirmation(t('Info'), t('MRS_ConfirmGeneratePdf'), () => {
            // allow the confirm dialog to close so that the validation errors can be displayed
            setTimeout(generatePdf, 200);
        });
    };

    const generatePdf = async () => {
        let questionGroupsSaveData = getQuestionGroupsSaveData();
        if (!validateQuestionnaireUserData(questionGroupsSaveData)) {
            return;
        }
        let responseSave = await saveDraft(questionGroupsSaveData, true);
        if (!responseSave) {
            return;
        }
        let response = await documentService.generateQuestionnairePdf(estateId, questionnaireData.baseDocumentTypeId);
        if (response.isValidRequest) {
            showNotification(t('Success'), t('OperationCompletedSuccessfully'));
            onGeneratePdf(response.document);
            onModalClose();
        } else {
            showServerError(response);
        }
    };

    const showNotification = (type: string, message: string) => {
        showAlert({ header: type, message, buttons: ['OK'] });
    };

    const showConfirmation = (type: string, message: string, confirmHandler: any) => {
        showAlert({
            header: type,
            message,
            buttons: [
                t('No'),
                {
                    text: t('Yes'),
                    handler: confirmHandler
                }
            ]
        });
    };

    const isUserItemList = baseDocumentTypeId === BaseDocumentType.ListOfItems;

    return (
        <IonModal isOpen={isVisible} className="questionnaire-page" backdropDismiss={false}>
            <IonHeader>
                <IonTitle>{questionnaireName}</IonTitle>
                <IonButton fill="clear" onClick={onModalClose}>
                    <IonIcon className="close-btn" icon={closeOutline}></IonIcon>
                </IonButton>
            </IonHeader>
            {isMobile() && (
                <IonContent className="question-list">
                    {renderQuestionList()}
                    {renderQuestionAdd()}
                </IonContent>
            )}
            {!isMobile() && (
                <div className="question-list styled-scroll">
                    {renderQuestionList()}
                    {renderQuestionAdd()}
                </div>
            )}
            <IonFooter>
                <IonButton fill="outline" onClick={onSaveDraft}>
                    {t('MRS_SaveForLater')}
                </IonButton>
                <IonButton strong={true} onClick={onGeneratePdfClick}>
                    {t('MRS_CompleteQuestionnaire')}
                </IonButton>
            </IonFooter>
        </IonModal>
    );
};
export default Questionnaire;
