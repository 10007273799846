import useSafeEffect from './useSafeEffect';
const useDebounce = (func: Function, params: any, delay: number) => {
    useSafeEffect(() => {
        const handler = setTimeout(() => func(params), delay);
        return () => clearTimeout(handler);
        // eslint-disable-next-line
    }, Object.values(params));
};

export default useDebounce;
