import CryptoJS from 'crypto-js';

export const decodeBase64 = (enc: string) => {
    let parsedWordArray = CryptoJS.enc.Base64.parse(enc);
    let parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
    return parsedStr;
};

export const encodeBase64 = (value: string) => {
    var wordArray = CryptoJS.enc.Utf8.parse(value);
    var base64 = CryptoJS.enc.Base64.stringify(wordArray);
    return base64;
};
