import Service from './_base';
import { trackPromise } from 'react-promise-tracker';

class ProfileService extends Service {
    async changePassword(data: any): Promise<any> {
        let response: any = await trackPromise(this.client.post('users/changepassword', data));
        return response.data;
    }

    async uploadPicture(fileName: string, fileBlob: Blob): Promise<any> {
        let formData: FormData = new FormData();
        let streamModel: any = { filePath: fileName, fileName: fileName };
        let file = new File([fileBlob], fileName);
        formData.append('fileInfo', JSON.stringify(streamModel));
        formData.append('file', file);
        // let config = {
        //     headers: { 'content-type': 'multipart/form-data' }
        // };
        let response: any = await trackPromise(this.client.post('users/uploadpicture', formData));
        return response.data;
    }

    async updateProfile(data: { firstName: string; lastName: string; phone: string; languageId: string }): Promise<any> {
        let response: any = await trackPromise(this.client.post('users/profile', data));
        return response.data;
    }
}
//eslint-disable-next-line
export default new ProfileService();
