import React, { useState } from 'react';
import {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonSearchbar,
    IonToolbar
} from '@ionic/react';
import type { CheckboxCustomEvent } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './AppTypeahead.scss';
import { capitalize } from '../../utils/util';

export interface Item {
    text: string;
    value: string;
}

interface TypeaheadProps {
    items: Item[];
    selectedItems: string[];
    title?: string;
    onSelectionCancel?: () => void;
    onSelectionChange?: (items: string[]) => void;
    search: boolean;
    isMultiselect?: boolean;
}

function AppTypeahead(props: TypeaheadProps) {
    const { t } = useTranslation();
    const [filteredItems, setFilteredItems] = useState<Item[]>([...props.items]);
    const [workingSelectedValues, setWorkingSelectedValues] = useState<string[]>([...props.selectedItems]);

    const isChecked = (value: string) => {
        return workingSelectedValues.find((item) => item === value) !== undefined;
    };

    const cancelChanges = () => {
        const { onSelectionCancel } = props;
        if (onSelectionCancel !== undefined) {
            onSelectionCancel();
        }
    };

    const confirmChanges = () => {
        const { onSelectionChange } = props;
        if (onSelectionChange !== undefined) {
            onSelectionChange(workingSelectedValues);
        }
    };

    const searchbarInput = (ev: any) => {
        filterList(ev.target.value);
    };

    /**
     * Update the rendered view with
     * the provided search query. If no
     * query is provided, all data
     * will be rendered.
     */
    const filterList = (searchQuery: string | null | undefined) => {
        /**
         * If no search query is defined,
         * return all options.
         */
        if (searchQuery === undefined || searchQuery === null) {
            setFilteredItems([...props.items]);
        } else {
            /**
             * Otherwise, normalize the search
             * query and check to see which items
             * contain the search query as a substring.
             */
            const normalizedQuery = searchQuery.toLowerCase();
            setFilteredItems(
                props.items.filter((item) => {
                    return item.value.includes(normalizedQuery);
                })
            );
        }
    };

    const checkboxChange = (ev: CheckboxCustomEvent) => {
        const { checked, value } = ev.detail;

        if (props.isMultiselect) {
            if (checked) {
                setWorkingSelectedValues([...workingSelectedValues, value]);
            } else {
                setWorkingSelectedValues(workingSelectedValues.filter((item) => item !== value));
            }
        } else {
            if (checked) {
                setWorkingSelectedValues([value]);
            }
        }
    };

    return (
        <>
            <IonHeader className="ion-no-border" style={{ marginBottom: '10px' }}>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={cancelChanges}>{capitalize(t('Cancel'))}</IonButton>
                    </IonButtons>
                    <IonTitle>{props.title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={confirmChanges}>{capitalize(t('Done'))}</IonButton>
                    </IonButtons>
                </IonToolbar>
                {props.search && (
                    <IonToolbar style={{ padding: '0 15px 0 15px' }}>
                        <IonSearchbar
                            style={{
                                marginTop: '10px',
                                border: '1px solid rgba(134, 148, 159, 0.3)',
                                borderRadius: '10px',
                                padding: '0',
                                height: '40px',
                                fontSize: '14px'
                            }}
                            onIonInput={searchbarInput}
                        ></IonSearchbar>
                    </IonToolbar>
                )}
            </IonHeader>

            <IonContent>
                <IonList style={{ margin: 0, borderRadius: 0 }} inset={true}>
                    {filteredItems.map((item) => (
                        <IonItem key={item.value}>
                            <IonLabel>{item.text}</IonLabel>
                            <IonCheckbox
                                value={item.value}
                                checked={isChecked(item.value)}
                                onIonChange={checkboxChange}
                            ></IonCheckbox>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </>
    );
}
export default AppTypeahead;
