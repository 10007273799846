import Service from './_base';
import { trackPromise } from 'react-promise-tracker';
import IQuestionnaire from '../interfaces/IQuestionnaire';
import IUploadDocumentRequest from '../interfaces/IUploadDocumentRequest';

class DocumentService extends Service {
    async getOffers(estateId: number) {
        let response: any = null;
        try {
            response = await trackPromise(this.client.post('documents/offers', { estateId }));
        } catch (e) {}
        return response?.data || null;
    }

    async getQuestionnaire(estateId: number, baseDocumentTypeId: number): Promise<IQuestionnaire> {
        let response: any = null;
        try {
            response = await trackPromise(this.client.post('documents/questionlist', { estateId, baseDocumentTypeId }));
        } catch (e) {}
        return response?.data?.questionList || null;
    }

    async saveQuestionnaireDraft(estateId: number, questionList: IQuestionnaire): Promise<any> {
        let response: any = null;
        try {
            response = await trackPromise(this.client.post('documents/savedraftquestionlist', { estateId, questionList }));
        } catch (e) {}
        return response?.data || null;
    }

    async generateQuestionnairePdf(estateId: number, baseDocumentTypeId: number): Promise<any> {
        let response: any = null;
        try {
            response = await trackPromise(this.client.post('documents/generatepdf', { estateId, baseDocumentTypeId }));
        } catch (e) {}
        return response?.data || null;
    }

    async uploadDocument(estateId: number, documentsModels: IUploadDocumentRequest[], documentStreams: any[]) {
        let response: any = null;
        let fd = new FormData();

        let uploadData = { estateId, documents: documentsModels };
        fd.append('documents', JSON.stringify(uploadData));
        let i = 0;
        for (let documentStream of documentStreams) {
            fd.append('doc' + i.toString(), documentStream);
            i++;
        }

        try {
            response = await trackPromise(this.client.post('documents/upload', fd));
        } catch (e) {}
        return response?.data || null;
    }
}
//eslint-disable-next-line
export default new DocumentService();
