import IEstate from '../interfaces/IEstate';
import {
    Bathroom,
    Bedroom,
    Fronts,
    Furnished,
    Garage,
    Garden,
    Parking,
    Surface,
    Terrace,
    HabitableArea,
    GardenIcon
} from '../assets/svg-icons';
import estateOnline from '../assets/img/pin-estate-online.svg';
import bigEstateOnline from '../assets/img/big-pin-estate-online.svg';
import bigEstateOffline from '../assets/img/big-pin-estate-offline.svg';
import estateOffline from '../assets/img/pin-estate-offline.svg';
import IMarker from '../interfaces/IMarker';
import { capitalize, formatArea } from './util';
import { EstatePurposeStatus } from '../commons';
import { TFunction } from 'i18next';

export const getEstateStatus = (estate: IEstate | undefined) => {
    let statusName: string = '';
    let statusColor: string = '';

    if (estate?.purposeStatus) {
        statusName = estate.purposeStatus?.name || '';
        statusColor = estate.purposeStatus?.id === EstatePurposeStatus.ToSellSold ? '#CE0024' : '#48B865';
    }
    return { name: statusName, color: statusColor };
};

export const getEstateDetails = (estate: IEstate | null) => {
    if (!estate) {
        return [];
    }
    let details: any = [];
    if (estate.rooms) {
        details.push({
            icon: Bedroom,
            label: 'Bedrooms',
            valueFirst: true,
            value: estate.rooms
        });
    }
    if (estate.bathrooms) {
        details.push({
            icon: Bathroom,
            label: 'BathRooms',
            valueFirst: true,
            value: estate.bathrooms
        });
    }
    if (estate.fronts) {
        details.push({
            icon: Fronts,
            label: 'Fronts',
            valueFirst: false,
            value: estate.fronts
        });
    }
    if (estate.area) {
        details.push({
            icon: HabitableArea,
            label: 'Surface',
            valueFirst: false,
            value: formatArea(estate.area)
        });
    }
    if (estate.gardenArea) {
        details.push({
            icon: GardenIcon,
            label: 'Garden',
            valueFirst: false,
            value: formatArea(estate.gardenArea)
        });
    }
    // if (estate.officeName) {
    //     details.push({
    //         icon: LocationPin,
    //         label: 'Office',
    //         valueFirst: false,
    //         value: estate.officeName
    //     });
    // }
    return details;
};

export const getEstateFeatures = (estate: IEstate, t: TFunction<'translation', undefined>) => {
    let features: { label: string; id: string; value: string | number; icon: () => JSX.Element }[] = [];

    if (estate.rooms) {
        features.push({
            label: capitalize(t('Bedrooms')),
            id: 'bedrooms',
            value: estate.rooms,
            icon: Bedroom
        });
    }

    if (estate.bathrooms) {
        features.push({
            label: capitalize(t('Bathrooms')),
            id: 'bathrooms',
            value: estate.bathrooms,
            icon: Bathroom
        });
    }

    if (estate.furnished) {
        features.push({
            label: capitalize(t('Furnished')),
            id: 'furnished',
            value: estate.furnished === 1 ? capitalize(t('Yes')) : capitalize(t('No')),
            icon: Furnished
        });
    }

    if (estate.groundArea) {
        features.push({
            label: capitalize(t('GroundArea')),
            id: 'ground-area',
            value: estate.groundArea + ' m²',
            icon: Surface
        });
    }

    if (estate.area) {
        features.push({
            label: capitalize(t('HabitableSurface')),
            id: 'habitable-surface',
            value: estate.area + ' m²',
            icon: Surface
        });
    }

    if (estate.garden) {
        features.push({
            label: capitalize(t('Garden')),
            id: 'garden',
            value: estate.garden === 1 ? capitalize(t('Yes')) : capitalize(t('No')),
            icon: Garden
        });
    }

    if (estate.gardenArea) {
        features.push({
            label: capitalize(t('GardenArea')),
            id: 'garden-area',
            value: estate.gardenArea + ' m²',
            icon: Surface
        });
    }

    if (estate.terrace) {
        features.push({
            label: capitalize(t('Terrace')),
            id: 'terrace',
            value: estate.terrace === 1 ? capitalize(t('Yes')) : capitalize(t('No')),
            icon: Terrace
        });
    }

    if (estate.parking) {
        features.push({
            label: capitalize(t('Parking')),
            id: 'parking',
            value: estate.parking === 1 ? capitalize(t('Yes')) : capitalize(t('No')),
            icon: Parking
        });
    }

    if (estate.garage) {
        features.push({
            label: capitalize(t('Garage')),
            id: 'garage',
            value: estate.garage === 1 ? capitalize(t('Yes')) : capitalize(t('No')),
            icon: Garage
        });
    }

    if (estate.fronts) {
        features.push({
            label: capitalize(t('Fronts')),
            id: 'fronts',
            value: estate.fronts + '+',
            icon: Fronts
        });
    }

    return features;
};

const getMarkerIcon = (purposeStatusId: number, isSelected: boolean) => {
    let onlineIcon = estateOnline,
        offlineIcon = estateOffline;
    if (isSelected) {
        onlineIcon = bigEstateOnline;
        offlineIcon = bigEstateOffline;
    }
    return purposeStatusId === EstatePurposeStatus.ToSellSold ? offlineIcon : onlineIcon;
};

export const getMapMarkers = (
    estates: IEstate[],
    selectedEstateId: number | undefined,
    onMarkerClick: any,
    primaryMarker: { lat: number | undefined; lng: number | undefined; title: string; selected: boolean }
) => {
    let markers: IMarker[] = [
        {
            isSelected: selectedEstateId || !primaryMarker.selected ? false : true,
            infoMessage: primaryMarker.title,
            lat: primaryMarker.lat || 0,
            lng: primaryMarker.lng || 0,
            options: { zIndex: 1000 },
            onCloseClick: () => onMarkerClick(null, true)
        }
    ];
    estates?.forEach((x) => {
        let isSelected = x.id === selectedEstateId;
        if (x.coordinates) {
            markers.push({
                icon: getMarkerIcon(x.purposeStatus.id, isSelected),
                onClick: () => onMarkerClick(x, true),
                isSelected,
                infoMessage: capitalize(x?.subCategory?.name),
                infoWindowClassName: 'link',
                lat: x.coordinates?.lat,
                lng: x.coordinates?.lon
            });
        }
    });

    return markers;
};
