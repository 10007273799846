import { useTranslation } from 'react-i18next';
import './NoResults.scss';
interface INoResults {
    icon: any;
    title?: string;
    info: string;
    iconClassName?: string;
}
const NoResults: React.FC<INoResults> = ({ icon, title, info, iconClassName }) => {
    const { t } = useTranslation();
    return (
        <div className="no-results">
            <div>
                <div className={iconClassName || 'no-results-icon'}>{icon}</div>
                <div className="no-results-title"> {title || t('T_A_NoResultsFound')}</div>
                <div className="no-results-info"> {info}</div>
            </div>
        </div>
    );
};
export default NoResults;
