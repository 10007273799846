import { trackPromise } from 'react-promise-tracker';
import { LogCategory } from '../commons';
import IActivity from '../interfaces/IActivity';
import Service from './_base';

class ActivityService extends Service {
    getActivities = async (estateId: number, lastDays: number, filters?: any) => {
        let activityData: any;
        try {
            activityData = await trackPromise(this.client.post('estates/activities', { filter: { estateId, lastDays } }));
        } catch {}

        let activities: IActivity[] = [];

        if (activityData?.data?.calendarActivities && (filters?.length === 0 || filters?.indexOf('calendar') >= 0)) {
            activities = [
                ...activityData?.data?.calendarActivities.map((x: any) => {
                    return { ...x, type: 'calendar' };
                })
            ];
        }
        if (activityData?.data?.auditActivities && (filters?.length === 0 || filters?.indexOf('audit') >= 0)) {
            activities = [
                ...activities,
                ...activityData?.data?.auditActivities.map((x: any) => {
                    return { ...x, type: 'audit' };
                })
            ];
        }
        if (activityData?.data?.historyActivities && (filters?.length === 0 || filters?.indexOf('history') >= 0)) {
            activities = [
                ...activities,
                ...activityData?.data?.historyActivities.map((x: any) => {
                    return { ...x, type: 'history' };
                })
            ];
        }
        if (activityData?.data?.historyMediaActivities && (filters?.length === 0 || filters?.indexOf('media') >= 0)) {
            activities = [
                ...activities,
                ...activityData?.data?.historyMediaActivities.map((x: any) => {
                    return { ...x, type: 'media' };
                })
            ];
        }
        if (activityData?.data?.taskActivities && (filters?.length === 0 || filters?.indexOf('task') >= 0)) {
            activities = [
                ...activities,
                ...activityData?.data?.taskActivities.map((x: any) => {
                    return { ...x, type: 'task' };
                })
            ];
        }

        activities = activities.filter((x) => x.categoryId !== LogCategory.OtherAutomaticAction);

        return activities;
    };
}
//eslint-disable-next-line
export default new ActivityService();
