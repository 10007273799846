import { Redirect, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { IonApp, IonRouterOutlet, IonMenu, setupIonicReact } from '@ionic/react';
import { Tooltip } from 'react-tooltip';
import { IonReactRouter } from '@ionic/react-router';
import IAppState from './interfaces/IAppState';
import AppContext from './contexts/AppContext';
import Menu from './components/core/Menu';
import PrivateRoute from './components/core/PrivateRoute';
import useDidMount from './hooks/useDidMount';
import Smartbanner from './components/base/SmartBanner';

/* Theme variables */
import './theme/variables.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/** Import main scss file */
import './theme/theme.scss';

import DashboardPage from './pages/DashboardPage';
import EstatePage from './pages/EstatePage';
import UserProfile from './lib/userProfile';
import ProfilePage from './pages/ProfilePage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import PushNotificationPage from './pages/PushNotificationPage';
import { isMobile, isiPhone } from './utils/util';
import { getUpdatedState } from './utils/stateUtil';
import { useTranslation } from 'react-i18next';
import useAppLoader from './hooks/useAppLoader';

setupIonicReact({ mode: 'ios' });

declare global {
    interface Window {
        env: any;
    }
}

window.env = window.env || {};

const App: React.FC = () => {
    const [appState, setAppState] = useState<IAppState>({
        isAuthenticated: false,
        user: null,
        refreshEvent: null
    });
    const didMount = useDidMount();
    const { t } = useTranslation();
    useAppLoader(t);

    // update only user data
    const updateUserState = (data: any) => {
        let newState = { ...appState };
        newState.isAuthenticated = true;
        newState.user = getUpdatedState(appState.user, data);
        setAppState(newState);
    };

    //complete the event emitted by IonRefresher
    const finalizeRefreshEvent = () => {
        appState.refreshEvent?.detail.complete();
        setAppState({ ...appState, refreshEvent: null });
    };

    useEffect(() => {
        const loginData = UserProfile.AccessToken;
        if (loginData) {
            setAppState({
                ...appState,
                isAuthenticated: true,
                user: {
                    pictureUrl: UserProfile.Picture,
                    fullName: UserProfile.FullName,
                    firstName: UserProfile.FirstName,
                    lastName: UserProfile.LastName
                },
                refreshEvent: null
            });
        }
        localStorage.setItem('showNotification', 'true');
        //eslint-disable-next-line
    }, []);

    if (!didMount) {
        return <></>;
    }

    return (
        <AppContext.Provider
            value={{
                state: appState,
                setState: setAppState,
                updateStateData: updateUserState,
                finalizeRefreshEvent
            }}
        >
            <IonApp style={{ paddingBottom: isiPhone() ? '10px' : '0' }}>
                <Smartbanner t={t} />
                <IonReactRouter>
                    <Tooltip anchorSelect=".app-tooltip" style={{ zIndex: 100000, maxWidth: '300px' }} />
                    {/* Mobile menu */}
                    {appState.isAuthenticated && isMobile() && (
                        <IonMenu contentId="main" type="overlay" side="end">
                            <Menu />
                        </IonMenu>
                    )}
                    <IonRouterOutlet id="main">
                        {/* When creating a new route paired with the useDocumentTitle hook, the value after the first / character will become the tab title */}
                        <Route path="/login" component={LoginPage} exact={true} />
                        <Route path="/forgot-password" component={ForgotPasswordPage} exact={true} />
                        <Route path="/" exact={true}>
                            <Redirect to="/dashboard" />
                        </Route>
                        <PrivateRoute path="/dashboard" component={DashboardPage} exact={true}></PrivateRoute>
                        <PrivateRoute path="/profile" component={ProfilePage} exact={true}></PrivateRoute>
                        <PrivateRoute path="/estate/:estateId/:type" component={EstatePage} exact={true}></PrivateRoute>
                        <PrivateRoute path="/notifications" component={PushNotificationPage} exact={true}></PrivateRoute>
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        </AppContext.Provider>
    );
};

export default App;
