import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IonModal, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import StatusLabel from '../base/StatusLabel';
import './EstatePreviewModal.scss';
import Separator from '../base/Separator';
import { capitalize, formatUserPrice, isIOS, isMobile } from '../../utils/util';
import EstateDetail from './EstateDetail';
import { getEstateDetails, getEstateStatus } from '../../utils/estateUtil';
import IEstate from '../../interfaces/IEstate';
import ImageSwiper from '../base/ImageSwiper';
import PoweredBy from '../base/PoweredBy';

const EstatePreviewModal: React.FC<{ estate: IEstate | null; onClose: Function; showDescription: Boolean }> = ({
    estate,
    onClose,
    showDescription
}) => {
    const details = getEstateDetails(estate);
    const { t } = useTranslation();
    const modalRef = useRef<HTMLIonModalElement>(null);

    const dismiss = () => modalRef.current?.dismiss();

    const description = estate?.sms || estate?.shortDescription;
    const status = estate && getEstateStatus(estate);

    return (
        <IonModal
            ref={modalRef}
            className={'estate-preview-modal' + (isMobile() ? ' mobile' : '')}
            isOpen={estate ? true : false}
            backdropDismiss={false}
            initialBreakpoint={isMobile() ? 1 : undefined}
            breakpoints={isMobile() ? [0, 1] : undefined}
            onWillDismiss={() => onClose()}
        >
            <div className={'estate-preview' + (isIOS() ? ' ios-device' : '')}>
                <ImageSwiper urls={estate?.pictures || []} imagesPerView={1} initialSlide={0} />
                <IonIcon className="close-btn" icon={closeOutline} onClick={() => dismiss()}></IonIcon>
                <div className="estate-preview-content">
                    <div className="estate-name">{capitalize(estate?.subCategory?.name)}</div>
                    <div className="price-status">
                        <div className="price">
                            {estate?.price
                                ? formatUserPrice(estate?.price || 0, null, estate?.currency)
                                : capitalize(t('MRS_ConfidentialPrice'))}
                        </div>
                        {status && <StatusLabel label={status.name} color={status.color} />}
                    </div>
                    <IonGrid className="details">
                        <IonRow>
                            {details.map((x: any, index: number) => (
                                <IonCol key={index} sizeXs="6" sizeSm="4">
                                    <EstateDetail t={t} detail={x} />
                                </IonCol>
                            ))}
                        </IonRow>
                    </IonGrid>
                    {showDescription && description && (
                        <>
                            <Separator />
                            <div
                                className="description"
                                dangerouslySetInnerHTML={{
                                    __html: description
                                }}
                            ></div>
                        </>
                    )}
                </div>
            </div>
            {!description && isMobile() && <PoweredBy />}
        </IonModal>
    );
};
export default EstatePreviewModal;
