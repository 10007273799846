import i18n from '../lib/i18n';
/**
 * Function to check if the current device is mobile
 *
 */
export const isMobile = (): boolean => {
    let userAgent = navigator.userAgent;
    if (userAgent == null || userAgent.length === 0) {
        return false;
    }

    let b =
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            userAgent
        );
    let v =
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            userAgent.substr(0, 4)
        );

    if (b || v) {
        return true;
    }

    return false;
};

/**
 * Capitalizes a string
 * @param str
 */
export const capitalize = (str = '') => {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const format = function (...params: string[]): string {
    let s = params[0],
        i = params.length - 1;

    while (i--) {
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), params[i + 1]);
    }

    return s;
};

const formatNumber = (n: any, decimalPlaces: any, thousandSeparator: any, decimalSeparator: any, currencySymbol: any) => {
    if (typeof n == 'undefined') {
        return '';
    }

    decimalPlaces = isNaN((decimalPlaces = Math.abs(decimalPlaces))) ? 2 : decimalPlaces;
    decimalSeparator = decimalSeparator === undefined ? '.' : decimalSeparator;
    thousandSeparator = thousandSeparator === undefined ? ',' : thousandSeparator;

    let sign = n < 0 ? '-' : '',
        i = parseInt((n = Math.abs(+n || 0).toFixed(decimalPlaces))) + '',
        j = i.length > 3 ? i.length % 3 : 0;

    let decimals = Math.abs(n - parseInt(i))
        .toFixed(decimalPlaces)
        .slice(2);

    let formattedPrice = '';

    if (decimals !== '00') {
        formattedPrice =
            sign +
            (j ? i.substr(0, j) + thousandSeparator : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSeparator) +
            (decimalPlaces ? decimalSeparator + decimals : '') +
            (currencySymbol ? ' ' + currencySymbol : '');
    } else {
        formattedPrice =
            sign +
            (j ? i.substr(0, j) + thousandSeparator : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSeparator) +
            (currencySymbol ? ' ' + currencySymbol : '');
    }

    return formattedPrice;
};

export const formatUserPrice = (x: number, allow0 = null, currencySymbol?: string) => {
    if (!x && !allow0) {
        return '';
    }
    return formatNumber(x, 2, '.', ',', currencySymbol || '€');
};

export const formatArea = (x: number) => {
    return formatNumber(x, 2, '.', ',', 'm²');
};

export const formatDistance = (x: number) => {
    return formatNumber(x, 2, '.', ',', 'km');
};

export const getBrowserInfo = () => {
    let ua = navigator.userAgent,
        tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/);
        if (tem != null) {
            return { name: 'Opera', version: tem[1] };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return {
        name: M[0],
        version: M[1]
    };
};

export const isInside = () => {
    return window.location.toString().indexOf('/public-page') < 0 && window.location.toString().indexOf('/login') < 0;
};

export const formatAPIErrorMessages = (errors: any) => {
    let messages = [];
    let errorMessage;
    for (let error of errors) {
        if ((error.resource === 'User' || error.resource === 'Office') && error.property) {
            messages.push({ property: error.property[0].toLowerCase() + error.property.slice(1), message: error.message });
            continue;
        } else if (error.message) {
            if (error.message.indexOf('V_') >= 0) {
                errorMessage = i18n.t(error.message).replace('{PropertyName}', i18n.t(error.property));
            } else {
                errorMessage = error.message;
            }
        } else {
            errorMessage = error;
        }
        if (errorMessage) {
            messages.push(errorMessage);
        }
    }
    return messages;
};

export const formatAddress = (
    address: string | null | undefined,
    number: string | null | undefined,
    box: string | null | undefined
) => {
    if (address) {
        let value = address;
        if (number) {
            value += ' ' + number;
        }
        if (box) {
            value += ` ${i18n.t('Box')} ${box}`;
        }
        return value;
    }
    return '';
};

export const toAddressLine2 = (zip: string | null | undefined, city: string | null | undefined) => {
    let address = '';

    if (zip && zip !== '') {
        address += zip;
    }

    if (city && city !== '') {
        if (address.length) {
            address += ' ';
        }

        address += city;
    }

    return address;
};

export const toAddressLine1 = (
    address1: string | null | undefined,
    nr: string | null | undefined,
    box?: string | null | undefined,
    floor?: string | null | undefined
) => {
    // add address
    let address = '';

    if (address1 && address1 !== '' && address1 !== '-') {
        address += address1;
    }

    // add number
    if (nr && nr !== '' && nr !== '-') {
        if (address.length) {
            address += ' ';
        }

        address += nr;
    }

    // add box
    if (box && box !== '' && box !== '-') {
        if (address.length) {
            address += ' ';
        }

        address += `${i18n.t('Box').toLowerCase()} ${box}`;
    }

    // add floor
    if (floor && floor !== '' && floor !== '-') {
        if (address.length) {
            address += ' ';
        }

        address += `${i18n.t('Floor').toLowerCase()} ${floor}`;
    }

    return address.toString();
};

export const getInitials = (s: string) => {
    if (!s) {
        return '';
    }
    let parts = s.split(' ');
    let result = parts[0].charAt(0).toUpperCase();
    if (parts.length > 1) {
        result += parts[1].charAt(0).toUpperCase();
    }
    return result;
};

export const toFullName = (firstName: string, lastName: string) => {
    return `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName || ''}`.replace(/,/g, '');
};

export const cloneDeep = (data: any) => {
    if (data === null || typeof data !== 'object' || 'isActiveClone' in data) return data;

    var temp = data.constructor();

    for (var key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            data['isActiveClone'] = null;
            temp[key] = cloneDeep(data[key]);
            delete data['isActiveClone'];
        }
    }
    return temp;
};

export const getQueryParams = (url: string) => {
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params: any = {};
    //eslint-disable-next-line
    paramArr.map((param: string) => {
        const splitterIndex = param.indexOf('=');
        params[param.substring(0, splitterIndex)] = decodeURIComponent(param.substring(splitterIndex + 1));
    });
    return params;
};

/**
 * Image import helper
 * @param path
 * @returns {any}
 */
export const img = (path: string) => {
    try {
        return require(`../assets/img/${path}`);
    } catch {
        console.log('Could not find asset with path', path);
    }
};

/**
 * Checks if the device is ios
 * @returns {boolean}
 */
export const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent);

/**
 * Checks if the device is iPhone
 * @returns {boolean}
 */
export const isiPhone = () => /iPhone/.test(navigator.userAgent);
