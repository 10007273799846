import { IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { capitalize } from '../../utils/util';
import './PasswordInput.scss';

const PasswordInput: React.FC<{ value: string; onChange: (e: any) => void; onKeyDown: (e: any) => void; dataCy?: string }> = ({
    value,
    onChange,
    onKeyDown,
    dataCy
}) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <IonItem lines="none" data-cy={dataCy}>
            <IonLabel position="stacked">{capitalize(t('Password'))}</IonLabel>
            <div style={{ display: 'flex' }}>
                <IonInput
                    data-cy={`input-${dataCy}`}
                    className="password-input"
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    clearOnEdit={false}
                    onIonChange={onChange}
                    placeholder={t('MRS_EnterPassword')}
                    onKeyDown={onKeyDown}
                ></IonInput>
                <IonIcon
                    data-cy={`eye-icon-${dataCy}`}
                    className="eye-icon"
                    color="primary"
                    icon={showPassword ? eyeOffOutline : eyeOutline}
                    onClick={() => {
                        setShowPassword(!showPassword);
                    }}
                ></IonIcon>
            </div>
        </IonItem>
    );
};

export default PasswordInput;
