import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../utils/util';
const useDocumentTitle = (title?: string) => {
    let location = useLocation();
    const { t } = useTranslation();
    useEffect(() => {
        document.title =
            'CONNEKTO ' + (title ? capitalize(t(title)) : capitalize(t(capitalize(location.pathname.split('/')[1]))));
        //eslint-disable-next-line
    }, [location]);
};
export default useDocumentTitle;
