export const LogCategory = {
    None: 0,
    WH2_Automatic_Action: 1,
    Edit: 10,
    EstateCreatedManually: 38,
    EstateCopiedFromColleagues: 39,
    ContactCreatedManually: 40,
    ContactCreatedFromOwnWebsite: 41,
    ContactCreatedFromColleagueWebsite: 42,
    ContactCreatedFromMediaWebsite: 43,
    ContactUnsubscribed: 44,
    OrderCreated: 45,
    BrochureCreated: 46,
    AfficheCreated: 47,
    LetterCreated: 48,
    FaxCreated: 49,
    DocumentCreated: 50,
    MailSent: 51,
    Skipped: 52,
    EstateInfoSentByMail: 53,
    ManualMatchingInfoSentByMail: 54,
    ManualMatchingInfoSentByDocument: 55,
    AutomaticMatching: 56,
    EstateSentByTransferToMedia: 57,
    EstateUpdatedOnMedia: 58,
    EstatesRemovedByTransferFromMedia: 59,
    EstateSentByMailToMedia: 60,
    EstatesRemovedByMailFromMedia: 61,
    EstateSentByAutomaticMailToMedia: 62,
    EstatesRemovedByAutomaticMailFromMedia: 63,
    OtherAutomaticAction: 64,
    ExternalEdit: 70,
    MailReceived: 71,
    ContactCreatedByCleaningDoubles: 72,
    ManualMediaActivation: 73,
    ManualMediaDeactivation: 74,
    AutomaticEmail: 76,
    AutomaticSMS: 77,
    Notification: 78,
    ContactUpdatedManually: 85,
    ContactUpdatedFromOwnWebsite: 86,
    ContactUpdatedFromColleagueWebsite: 87,
    ContactUpdatedFromMediaWebsite: 88,
    ContactUpdatedFromOtherApp: 89,
    ContactCreatedFromOtherApp: 90,
    ChecklistCreated: 91,
    ChecklistDeleted: 92,
    TelephoneIngoingCall: 93,
    TelephoneOutgoingCall: 94,
    ESignature: 95
};

export const EstatePurposeStatus = {
    ToSellForSale: 1,
    ToRentForRent: 2,
    ToSellSold: 3,
    ToRentHired: 4,
    ToSellOption: 5,
    ToRentOption: 6,
    ToSellOutOfSales: 8,
    ToRentOutOfRent: 9,
    ToSellSuspendedSales: 10,
    ToRentSuspendedRent: 11,
    ToSellOptionOwnerSales: 12,
    ToRentOptionOwnerRent: 13,
    ToSellSoldSubjectToContract: 14,
    LifeAnnuityForSale: 15,
    LifeAnnuityOption: 16,
    LifeAnnuitySold: 17,
    Prospection: 19,
    Preparation: 20,
    Reserved: 21,
    DraftContract: 22,
    ProspectionRent: 23,
    EstimateSell: 24,
    EstimateRent: 25,
    EstimateLifeAnnuity: 26,
    ToRentPreparation: 27,
    LifeAnnuityPreparation: 28
};

export const EstateStatus = {
    Prospection: 0,
    Sales: 1,
    CompromisAct: 2,
    EndMandate: 3
};

export const BaseContactType = {
    Owner: 3,
    Tenant: 6,
    Buyer: 12,
    Notary: 13,
    NotaryBuyer: 23,
    PotentialBuyer: 4,
    PotentialTenant: 5,
    Freemium_LookingForSoftware: 32,
    Freemium_WantToTrain: 33,
    Freemium_Designer: 34,
    Freemium_Other: 35,
    Freemium_UserExperience_Novice: 42,
    Freemium_UserExperience_Expert: 43,
    Freemium_NbOfUsers_UpTo2: 44,
    Freemium_NbOfUsers_2To30: 45,
    Freemium_NbOfUsers_MoreThan30: 46
};

export const BaseDocumentType = {
    Other: 40,
    QuestionnaireA: 89,
    QuestionnaireB: 90,
    ListOfItems: 91
};

export const EstateDisplayStatus = {
    Offline: 1,
    Online: 2,
    EstateOfTheMonth: 3,
    Realisations: 4,
    OnlineWebsiteOnly: 5
};
