import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { arrowDownOutline, arrowUpOutline, trashOutline } from 'ionicons/icons';
import ImageCrop from './ImageCrop';
import './DocumentScanner.scss';
import { capitalize, isIOS } from '../../utils/util';
import { getBase64DataFromBlob, cropImage, validateFiles } from '../../utils/fileUtil';
import pdfGenerator from '../../services/pdfGenerator';
import useSafeEffect from '../../hooks/useSafeEffect';

interface IDocumentScanner {
    isOpen: boolean;
    onConfirm: Function;
    onCancel: Function;
    validExtensions: string[];
    minFileSize: number;
    maxFileSize: number;
}

interface IScannedImage {
    base64: string;
    fileData: File;
}

const DocumentScanner: React.FC<IDocumentScanner> = ({
    isOpen,
    validExtensions,
    minFileSize,
    maxFileSize,
    onConfirm,
    onCancel
}) => {
    const [scannedImages, setScannedImages] = useState<IScannedImage[]>([]);
    const [cropData, setCropData] = useState<{ isOpen: boolean; imageData: string | null; fileData: Blob | null }>({
        isOpen: false,
        imageData: null,
        fileData: null
    });

    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [showAlert] = useIonAlert();
    const history = useHistory();
    const location = useLocation();

    useSafeEffect(() => {
        if (cropData.isOpen && !location.search.includes('imageCropOpen=true')) {
            setCropData({
                isOpen: false,
                imageData: null,
                fileData: null
            });
        }
    }, [location]);

    useSafeEffect(() => {
        if (!isOpen) {
            setScannedImages([]);
            setCropData({
                isOpen: false,
                imageData: null,
                fileData: null
            });
        }
    }, [isOpen]);

    const onUpload = () => {
        inputRef?.current?.click();
    };

    const onFileSelect = async (filesData: any) => {
        if (filesData && filesData.files && filesData.files.length > 0) {
            let imageBase64 = await getBase64DataFromBlob(filesData.files[0]);
            if (imageBase64) {
                setCropData({ isOpen: true, imageData: imageBase64, fileData: filesData.files[0] });
                history.push(history.location.pathname + history.location.search + '&imageCropOpen=true');
            }

            // if (inputRef && inputRef.current) {
            //     inputRef.current?.clear();
            // }
        }
    };

    /**
     * Validate files and add them in the state file
     * then triggers the fileChanged callback
     * @param files list of files to add to state
     *
     */
    const addFiles = async (files: File[]) => {
        let validation = { hasErrors: false, errors: null, validFileList: [] };
        if (files && files.length) {
            validation = validateFiles(files, validExtensions, minFileSize, maxFileSize, true);
        }

        await onFileSelect({ files: validation.validFileList, hasErrors: validation.hasErrors, errors: validation.errors });
    };

    const handleFileChange = async (ev: any) => {
        let targetFileList = ev.target.files;
        await addFiles(targetFileList);
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const onCropConfirm = async (cropInfo: any) => {
        let processSuccess = false;
        if (cropData.fileData) {
            let imageCropped = await cropImage(cropData.fileData, cropInfo);
            if (imageCropped) {
                let imageBase64 = await getBase64DataFromBlob(imageCropped);
                if (imageBase64) {
                    setScannedImages([...scannedImages, { base64: imageBase64, fileData: new File([imageCropped], '') }]);
                    processSuccess = true;
                }
            }
            setCropData({ isOpen: false, imageData: null, fileData: null });
        }
        if (!processSuccess) {
            showAlert({ header: t('Error'), message: capitalize(t('OperationFailed')), buttons: ['OK'] });
        }
        history.goBack();
    };

    const onCropCancel = () => history.goBack();

    const onGenerate = async () => {
        try {
            const document = await trackPromise(pdfGenerator.generatePdfFromImages(scannedImages.map((x) => x.fileData)));
            onConfirm(document);
        } catch (e) {
            showAlert({ header: t('Error'), message: capitalize(t('FailedToGenerate')), buttons: ['OK'] });
        }
    };

    const moveItemUp = (index: number) => {
        const itemToMove = scannedImages[index];
        let newState = [...scannedImages];
        newState.splice(index, 1);
        newState.splice(index - 1, 0, itemToMove);
        setScannedImages(newState);
    };

    const onDelete = (index: number) => {
        let newState = [...scannedImages];
        newState.splice(index, 1);
        setScannedImages(newState);
    };

    return (
        <>
            <IonModal isOpen={isOpen}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => onCancel()}>{capitalize(t('Cancel'))}</IonButton>
                        </IonButtons>
                        <IonTitle>{t('GeneratePdf')}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton strong={true} onClick={() => onGenerate()}>
                                {capitalize(t('Generate'))}
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding document-scanner-content">
                    {scannedImages.map((x: any, index: number) => (
                        <div key={index} className="scanned-img-item">
                            <img src={x.base64} alt="scanned-img" />
                            <div className="img-toolbar">
                                <div>
                                    {index !== 0 ? (
                                        <IonIcon icon={arrowUpOutline} onClick={() => moveItemUp(index)} />
                                    ) : (
                                        <div></div>
                                    )}
                                    {index !== scannedImages.length - 1 ? (
                                        <IonIcon icon={arrowDownOutline} onClick={() => moveItemUp(index + 1)} />
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                <IonIcon className="trash-icon" icon={trashOutline} onClick={() => onDelete(index)} />
                            </div>
                        </div>
                    ))}
                    <IonButton
                        fill="outline"
                        style={{ width: '100%', marginBottom: isIOS() ? '15px' : 'unset' }}
                        onClick={() => onUpload()}
                    >
                        {t('MRS_TakePicture')}
                    </IonButton>
                </IonContent>
            </IonModal>
            <input
                type="file"
                ref={inputRef}
                hidden
                multiple={false}
                accept="image/*"
                capture="user"
                onChange={handleFileChange}
            />
            <ImageCrop
                isOpen={cropData?.isOpen}
                imageData={cropData?.imageData}
                cropShape="rect"
                aspect={1}
                onConfirm={onCropConfirm}
                onCancel={onCropCancel}
            />
        </>
    );
};
export default DocumentScanner;
