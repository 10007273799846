import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { mailUnreadOutline, shieldCheckmarkOutline } from 'ionicons/icons';
import { IonPage, IonInput, IonItem, IonLabel, IonAlert, IonIcon } from '@ionic/react';
import './Login.scss';
import './ForgotPassword.scss';
import PoweredBy from '../base/PoweredBy';
import ButtonLoad from '../core/ButtonLoad';
import { ConnektoLogoWhite } from '../../assets/svg-icons';
import usersService from '../../services/users';
import { getQueryParams } from '../../utils/util';
import useSafeEffect from '../../hooks/useSafeEffect';

enum ForgotPasswordSteps {
    SendResetEmail = 1,
    ResetEmailSent = 2,
    ResetPassword = 3,
    ProcessFinished = 4
}

const ForgotPassword: React.FC = () => {
    const { t } = useTranslation();
    const [step, setStep] = useState<ForgotPasswordSteps>(ForgotPasswordSteps.SendResetEmail);
    const [reCaptchaVerified, setReCaptchaVerified] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [alert, setAlert] = useState<any>({ visible: false, message: '' });

    useSafeEffect(() => {
        let params = getQueryParams(window.location.href);
        if (params.email && params.token) {
            setStep(ForgotPasswordSteps.ResetPassword);
            setToken(params.token);
            setEmail(params.email);
        } else {
            setStep(ForgotPasswordSteps.SendResetEmail);
        }
    }, []);

    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    };

    const handleVerify = (token: string | null) => {
        setReCaptchaVerified(token ? true : false);
    };

    const sendResetEmail = async () => {
        if (!email) {
            setAlert({ visible: true, message: t('FPWEmailRequired') });
            return;
        }
        if (!validateEmail(email)) {
            setAlert({ visible: true, message: t('V_Email').replace('{PropertyValue}', email) });
            return;
        }
        if (!reCaptchaVerified) {
            setAlert({ visible: true, message: t('FPWCaptcha') });
            return;
        }
        try {
            let response = await usersService.forgotPassword(email, window.location.href);
            if (response.isValidRequest) {
                setStep(ForgotPasswordSteps.ResetEmailSent);
            } else if (response?.validationErrors) {
                setAlert({ visible: true, message: t('FPWEmailNotFound') });
            }
        } catch (e) {
            setAlert({ visible: true, message: t('FPWEmailNotFound') });
        }
    };

    const resetPassword = async () => {
        if (password !== confirmPassword) {
            setAlert({ visible: true, message: t('FPWPassNotMatch') });
            return;
        }
        try {
            let response = await usersService.resetPassword(email, token, password, confirmPassword);
            if (response.isValidRequest) {
                setStep(ForgotPasswordSteps.ProcessFinished);
            } else if (response?.validationErrors?.length) {
                setAlert({ visible: true, message: response.validationErrors.map((x: any) => x.message).join(', ') });
            }
        } catch (e) {
            setAlert({ visible: true, message: t('FPWEmailNotFound') });
        }
    };

    const goToLogin = async () => (window.location.href = '/login');

    const pageHeight = useMemo(() => Math.max(document.documentElement.clientHeight, window.innerHeight || 0) + 'px', []);

    const backToLoginButton = (
        <div data-cy="back-to-login-forgot-pass" className="forgot-password" onClick={goToLogin}>
            {t('MRS_GoToLogin')}
        </div>
    );

    return (
        <IonPage className="login-page forgot-password-wrapper" style={{ height: pageHeight }}>
            <IonAlert
                isOpen={alert.visible}
                onDidDismiss={() => setAlert({ visible: false })}
                cssClass="my-custom-class"
                header={t('A_TitleError')}
                message={alert.message}
                buttons={[t('Close')]}
            />
            <div data-cy="connekto-logo" className="login-header">
                <ConnektoLogoWhite />

                <div className="forgot-password-title">
                    {step === ForgotPasswordSteps.SendResetEmail && t('MRS_ForgotPass')}
                    {step === ForgotPasswordSteps.ResetEmailSent && t('MRS_CheckEmail')}
                    {step === ForgotPasswordSteps.ResetPassword && t('FPWResetPass')}
                    {step === ForgotPasswordSteps.ProcessFinished && t('MRS_PasswordUpdated')}
                </div>
            </div>

            <div className="login-form-wrapper">
                {step === ForgotPasswordSteps.SendResetEmail && (
                    <>
                        <IonItem data-cy="forgot-pass-email-component" lines="none">
                            <IonLabel data-cy="forgot-pass-email-label" position="stacked">
                                {t('MRS_Email')}
                            </IonLabel>
                            <IonInput
                                data-cy="forgot-pass-email-input"
                                type="email"
                                value={email}
                                onIonChange={(e) => setEmail(e.detail.value!)}
                                placeholder={t('MRS_EnterEmail')}
                            ></IonInput>
                        </IonItem>
                        <ReCAPTCHA
                            data-cy="forgot-pass-reCaptcha"
                            sitekey={window.env?.REACT_APP_ReCAPTCHA_KEY}
                            onChange={handleVerify}
                        />
                        <ButtonLoad className="btn-action" onClick={sendResetEmail} loading={false}>
                            {t('Continue')}
                        </ButtonLoad>
                        {backToLoginButton}
                    </>
                )}
                {step === ForgotPasswordSteps.ResetEmailSent && (
                    <div className="message-box mail-sent">
                        <IonIcon icon={mailUnreadOutline} />
                        <div
                            className="text"
                            dangerouslySetInnerHTML={{ __html: t('FPWEmailSent').replace('{0}', `<strong>${email}</strong>`) }}
                        ></div>
                        {backToLoginButton}
                    </div>
                )}
                {step === ForgotPasswordSteps.ResetPassword && (
                    <>
                        <IonItem lines="none">
                            <IonLabel position="stacked">{t('MRS_NewPassword')}</IonLabel>
                            <IonInput
                                type="password"
                                autocomplete="new-password"
                                value={password}
                                clearOnEdit={false}
                                onIonChange={(e) => setPassword(e.detail.value!)}
                                placeholder={t('MRS_NewPassword')}
                            ></IonInput>
                        </IonItem>
                        <IonItem lines="none">
                            <IonLabel position="stacked">{t('MRS_RepeatPassword')}</IonLabel>
                            <IonInput
                                type="password"
                                autocomplete="new-password"
                                value={confirmPassword}
                                clearOnEdit={false}
                                onIonChange={(e) => setConfirmPassword(e.detail.value!)}
                                placeholder={t('MRS_RepeatPassword')}
                            ></IonInput>
                        </IonItem>
                        <ButtonLoad
                            className="btn-action"
                            onClick={resetPassword}
                            loading={false}
                            disabled={!password?.length || !confirmPassword?.length}
                        >
                            {t('MRS_ChangePassword')}
                        </ButtonLoad>
                    </>
                )}
                {step === ForgotPasswordSteps.ProcessFinished && (
                    <div className="message-box process-finished">
                        <IonIcon icon={shieldCheckmarkOutline} />
                        <div className="text">{t('FPWUpdateSuccess')}</div>
                        <ButtonLoad className="btn-action" onClick={goToLogin} loading={false}>
                            {t('MRS_GoToLogin')}
                        </ButtonLoad>
                    </div>
                )}
            </div>
            <PoweredBy />
        </IonPage>
    );
};

export default ForgotPassword;
