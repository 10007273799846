import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonFooter, IonList, IonCard, IonCardContent, IonItem, IonLabel, IonListHeader, IonText, IonButtons, IonMenuButton, IonInput } from '@ionic/react';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { Toast } from "@capacitor/toast";
import UserProfile from '../lib/userProfile';

export default function PushNotificationPage() {
    const nullEntry: any[] = []
    const [notifications, setnotifications] = useState(nullEntry);
    const [myToken, setMyToken] = useState<Token>();
    const myTopic = `${UserProfile?.UserId?.toString()}-OwnerDocumentUpload`; // to be decided with Ionut

    useEffect(()=>{
        PushNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
              PushNotifications.requestPermissions().then((res) => {
                if (res.receive === 'denied') {
                  showToast('Push Notification permission denied');
                }
                else {
                  showToast('Push Notification permission granted');
                  register();
                }
              });
            }
            else {
              register();
            }
          });
    },[])
    
    const register = () => {
        console.log('Initializing HomePage');

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register().then((_) => {
            FCM.subscribeTo({ topic: myTopic })
              .then((r) => alert(`subscribed to topic ${myTopic}`))
              .catch((err) => console.log(err));
          })
          .catch((err) => alert(JSON.stringify(err)));

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                setMyToken(token);
                showToast('Push registration success');
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                alert('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            }
        );
    }

    const showToast = async (msg: string) => {
        await Toast.show({
            text: msg
        })
    }

    return (
        <IonPage id='main'>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle slot="start"> Push Notifications</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <div>
                    <IonList>
                        <IonCard>
                            <IonCardContent>
                              If your registration is successful you'll see your token below:<br/>
                              <IonInput value={myToken?.value}></IonInput> <br/><br/>
                              {/* <IonButton color="success" expand="full" onClick={getToken}>Get FCM token</IonButton><br/>
                              Check your FCM token below:<br/>
                              <IonInput value={myFCMToken}></IonInput> */}
                            </IonCardContent>
                        </IonCard>
                    </IonList>

                </div>
                <IonListHeader mode="ios" lines="full">
                    <IonLabel>Notifications</IonLabel>
                </IonListHeader>
                {notifications.length !== 0 &&
                    <IonList>
                        {notifications.map((notif: any) =>
                            <IonItem key={notif.id}>
                                <IonLabel>
                                    <IonText>
                                        <h3 className="notif-title">{notif.title}</h3>
                                    </IonText>
                                    <p>{notif.body}</p>
                                    {notif.type==='foreground' && <p>This data was received in foreground</p>}
                                    {notif.type==='action' && <p>This data was received on tap</p>}
                                </IonLabel>
                            </IonItem>
                        )}
                    </IonList>}
            </IonContent>
            {/* <IonFooter>
                <IonToolbar>
                    <IonButton color="success" expand="full" onClick={register}>Register for Push</IonButton>
                </IonToolbar>
            </IonFooter> */}
        </IonPage >
    )
}
