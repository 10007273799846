import http from '../lib/http';

export default class Service {
    client: any;
    constructor() {
        this.client = http;
    }

    reload(field: string) {
        window.localStorage.setItem(field, 'true');
    }
}
