import GeneralLayout from '../components/core/GeneralLayout';
import NoAccessPage from '../components/core/NoAccessPage';
import Dashboard from '../components/dashboard/Dashboard';
import useDocumentTitle from '../hooks/useDocumentTitle';
import UserProfile from '../lib/userProfile';

const DashboardPage: React.FC = () => {
    useDocumentTitle();
    return UserProfile.AgentAccessInfo ? (
        <NoAccessPage />
    ) : (
        <GeneralLayout>
            <Dashboard />
        </GeneralLayout>
    );
};

export default DashboardPage;
