import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import XHR from 'i18next-http-backend';

const lng = localStorage.getItem('LanguageId') || 'en-GB';
var initialized = false;

i18n.use(initReactI18next)
    .use(Backend)
    .init(
        {
            lng,
            fallbackLng: 'en-GB',
            load: 'currentOnly',
            crossDomain: true,
            backend: {
                backends: [LocalStorageBackend, XHR],
                backendOptions: [
                    {
                        prefix: 'i18next_res_',
                        expirationTime: 7 * 24 * 60 * 60 * 1000
                    },
                    {
                        loadPath: `https://webapi.whise.eu/api/v2/translations?languageId={{lng}}`
                    }
                ]
            },
            react: {
                useSuspense: false
            }
        },
        () => {
            initialized = true;
        }
    );

export const translationInitialized = () => {
    return initialized;
};
export default i18n;
