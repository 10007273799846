import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonButton, IonCard, IonCardContent, IonIcon, IonText } from '@ionic/react';
import { arrowBackOutline, callOutline, mailOutline, informationCircleOutline } from 'ionicons/icons';
import './EstateMain.scss';
import EstateDesktopNav from './EstateDesktopNav';
import Document from './document/Document';
import Offers from './offers/Offers';
import Activities from './activities/Activities';
import MarketInfo from './view/MarketInfo';
import MyProperty from './view/MyProperty';
import ImageSwiper from '../base/ImageSwiper';
import useSafeEffect from '../../hooks/useSafeEffect';
import estateService from '../../services/estate';
import { capitalize, isMobile, toFullName } from '../../utils/util';
import { ClientLogo, PictureIcon } from '../../assets/svg-icons';
import IEstate from '../../interfaces/IEstate';
import ImageFallback from '../core/ImageFallback';
import EstateMainContext from '../../contexts/EstateMainContext';
import IEstatePicture from '../../interfaces/IEstatePicture';
import UserProfile from '../../lib/userProfile';

const Estate: React.FC<{ estateId: number; view: string }> = ({ estateId, view }) => {
    const [estateData, setEstateData] = useState<IEstate>({} as IEstate);
    const [displayPictures, setDisplayPictures] = useState<boolean>(false);
    const { t } = useTranslation();
    const history = useHistory();

    useSafeEffect(() => {
        if (estateId) {
            load();
        } else {
            setEstateData({} as IEstate);
            setDisplayPictures(false);
        }
        //eslint-disable-next-line
    }, [estateId]);

    const load = async () => {
        let estateData = await estateService.getEstateById(estateId);
        if (estateData && estateData.estates && estateData.estates[0]) {
            setEstateData(estateData.estates[0]);
        }
    };

    const onSeePicturesClick = () => {
        setDisplayPictures(true);
    };

    const getCoverPicture = (pictures: IEstatePicture[]) => {
        if (!pictures?.length) {
            return '';
        }
        return pictures[0].urlXXL || pictures[0].urlLarge || pictures[0].urlSmall || '';
    };

    return (
        <div className={'estate-main-wrapper' + (isMobile() ? ' mobile' : '')}>
            <ImageSwiper
                urls={estateData?.pictures}
                imagesPerView={1}
                fullScreenMode={{ isVisible: displayPictures, onClose: () => setDisplayPictures(false) }}
                initialSlide={0}
            />
            <div className="estate-header">
                <ImageFallback
                    className="estate-img"
                    alt="estate-img"
                    src={getCoverPicture(estateData?.pictures)}
                    fallback="./assets/img/house-noimage.png"
                />
                <div className="nav-button-wrapper">
                    <div className="nav-button">
                        <span className="back-nav-button">
                            <IonButton
                                className="back-button"
                                size="small"
                                disabled={UserProfile.AgentAccessInfo ? true : false}
                                onClick={() => {
                                    history.replace('/dashboard');
                                }}
                            >
                                <IonIcon slot="icon-only" icon={arrowBackOutline}></IonIcon>
                            </IonButton>
                            {!isMobile() && <span className="button-label">{t('MRS_BackHome')}</span>}
                        </span>
                        {estateData?.pictures?.length && (
                            <>
                                {isMobile() ? (
                                    <IonButton className="picture-button-mobile" size="small" onClick={onSeePicturesClick}>
                                        <PictureIcon />
                                    </IonButton>
                                ) : (
                                    <IonButton className="picture-button-desktop" fill="clear" onClick={onSeePicturesClick}>
                                        {t('MRS_SeeEstatePictures')}
                                    </IonButton>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="estate-header-card-wrapper">
                <IonCard className="agent-card no-margin">
                    <IonCardContent>
                        <div className="profile-data">
                            <div className="agent-info">
                                <ImageFallback
                                    className="agent-img"
                                    alt="agent-picture"
                                    src={estateData?.officeLogoUrl || estateData?.clientLogoUrl}
                                    fallbackComponent={<ClientLogo />}
                                />
                                <div className={'agent-data' + (isMobile() ? ' mobile' : '')}>
                                    {estateData.representatives && estateData.representatives[0] && (
                                        <div className="agent-contact-info-line">
                                            <div className="agent-name trim-overflow">
                                                {toFullName(
                                                    estateData?.representatives[0]?.firstName,
                                                    estateData?.representatives[0]?.name
                                                )}
                                            </div>
                                            {estateData?.representatives[0]?.mobile && (
                                                <div className="header-info-item">
                                                    <a href={`tel:${estateData.representatives[0].mobile}`}>
                                                        <IonIcon className="tel" icon={callOutline} />
                                                        {isMobile() ? '' : estateData.representatives[0].mobile}
                                                    </a>
                                                </div>
                                            )}
                                            {estateData?.representatives[0]?.email && (
                                                <div className="header-info-item">
                                                    <a href={`mailto:${estateData.representatives[0].email}`}>
                                                        <IonIcon icon={mailOutline} />
                                                        {isMobile() ? '' : estateData.representatives[0].email}
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="agent-contact-info-line">
                                        <span className="agent-office-label">{capitalize(t('Office'))}</span>
                                        <div className="agent-office-name trim-overflow">{estateData.office}</div>
                                        {estateData?.officePhone && (
                                            <div className="header-info-item">
                                                <a href={`tel:${estateData.officePhone}`}>
                                                    <IonIcon className="tel" icon={callOutline} />
                                                    {isMobile() ? '' : estateData.officePhone}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <EstateDesktopNav />
                        <p className="tab-details">
                            <IonText color="primary">
                                <IonIcon icon={informationCircleOutline}></IonIcon>
                                {view === 'overview' && <span>{t('MRS_TabDetails_Overview')}</span>}
                                {view === 'view' && <span>{t('MRS_TabDetails_Property')}</span>}
                                {view === 'document' && <span>{t('MRS_TabDetails_Document')}</span>}
                                {view === 'offers' && <span>{t('MRS_TabDetails_Offers')}</span>}
                                {view === 'activity' && <span>{t('MRS_TabDetails_Activity')}</span>}
                            </IonText>
                        </p>
                    </IonCardContent>
                </IonCard>
            </div>
            <div className="estate-main-content">
                <EstateMainContext.Provider value={estateData}>
                    {view === 'overview' && <MarketInfo reloadEstateData={load} />}
                    {view === 'view' && <MyProperty reloadEstateData={load} />}
                    {view === 'document' && <Document estateId={estateId} />}
                    {view === 'offers' && <Offers estateId={estateId} />}
                    {view === 'activity' && <Activities estateId={estateId} />}
                </EstateMainContext.Provider>
            </div>
        </div>
    );
};

export default Estate;
