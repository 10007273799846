import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';
import { CheckIcon, TrashIcon } from '../../assets/svg-icons';
import { capitalize, isMobile } from '../../utils/util';
import './ActionItem.scss';
import UserProfile from '../../lib/userProfile';

const ActionItem: React.FC<{
    icon: any;
    name: string;
    description?: string;
    isComplete: boolean | null | undefined;
    attachmentName: string | null | undefined;
    onDeleteAttachment?: any;
    seeAttachment?: any;
    onComplete: any;
    displayAttachmentName: boolean | null | undefined;
    isUpload?: boolean;
    descriptionCompleted?: string;
    style?: any;
    isDeleteAllowed?: boolean;
}> = ({
    icon,
    name,
    description,
    isComplete,
    attachmentName,
    onDeleteAttachment,
    seeAttachment,
    onComplete,
    displayAttachmentName = true,
    isUpload = false,
    descriptionCompleted = '',
    style = {},
    isDeleteAllowed = true
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={isMobile() ? 'mobile action-item' : 'action-item'}
            style={!isMobile() ? { flexWrap: 'nowrap', ...style } : { ...style }}
        >
            <div className="action" style={isMobile() ? { alignItems: 'unset' } : {}}>
                <div className="action-icon icon" style={!(descriptionCompleted || description) ? { height: 'unset' } : {}}>
                    {icon}
                </div>
                <div className="action-data">
                    <div className={'action-name' + (isMobile() ? ' mobile' : '')}>
                        {capitalize(name)}
                        {isMobile() && <CheckIcon className="check-icon" color={isComplete ? '#49B22A' : ''} />}
                    </div>
                    {attachmentName && displayAttachmentName && (
                        <div className="attachment-web">
                            <div className="attachment-name">{attachmentName}</div>
                            {isDeleteAllowed && onDeleteAttachment && isMobile() && (
                                <div
                                    className={'delete-attachment' + (UserProfile.AgentAccessInfo && ' disabled')}
                                    onClick={onDeleteAttachment}
                                >
                                    <TrashIcon color="#DB1B24" />
                                </div>
                            )}
                        </div>
                    )}
                    {(descriptionCompleted || description) && <div>{isComplete ? descriptionCompleted : description}</div>}
                </div>
            </div>
            <div className="button" style={isMobile() ? { width: '100%', marginTop: '16px' } : {}}>
                {isDeleteAllowed && attachmentName && !isMobile() && displayAttachmentName && onDeleteAttachment && (
                    <div
                        className={'delete-attachment' + (UserProfile.AgentAccessInfo && ' disabled')}
                        onClick={onDeleteAttachment}
                        style={{ marginRight: '20px' }}
                    >
                        <TrashIcon color="#DB1B24" />
                    </div>
                )}
                {isComplete ? (
                    <IonButton
                        fill="solid"
                        className="see-button"
                        style={isMobile() ? { width: '100%' } : {}}
                        onClick={seeAttachment}
                    >
                        {t('MRS_See')}
                    </IonButton>
                ) : (
                    <IonButton
                        fill="outline"
                        className="complete-button"
                        style={isMobile() ? { width: '100%' } : {}}
                        onClick={onComplete}
                        disabled={UserProfile.AgentAccessInfo ? true : false}
                    >
                        {t(!isUpload ? 'Complete' : 'Upload')}
                    </IonButton>
                )}
                {!isMobile() && (
                    <CheckIcon style={{ marginLeft: '24px', minWidth: '24px' }} color={isComplete ? '#49B22A' : ''} />
                )}
            </div>
        </div>
    );
};

export default ActionItem;
