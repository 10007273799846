import {
    IonPage,
    IonInput,
    IonItem,
    IonLabel,
    IonAlert,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    RefresherEventDetail,
    useIonLoading
} from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserProfile from '../../lib/userProfile';
import { ConnektoLogoWhite } from '../../assets/svg-icons';
import initService from '../../services/init';
import PoweredBy from '../base/PoweredBy';
import ButtonLoad from '../core/ButtonLoad';
import PasswordInput from '../core/PasswordInput';
import './Login.scss';

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [alert, setAlert] = useState<any>({ visible: false, message: '' });
    const [waitRequest, setWaitRequest] = useState<boolean>(false);
    const [present] = useIonLoading();
    const { t } = useTranslation();
    const token = new URLSearchParams(window.location.search).get('token');

    useEffect(() => {
        if (token) {
            present({ message: t('Loading'), spinner: 'circles' });
            handleLoginWithToken(token);
        }
        //eslint-disable-next-line
    }, [token]);

    const handleLoginWithToken = async (token: string) => {
        let response = await new initService().loginByToken(token);
        if (response && UserProfile.AgentAccessInfo?.estateId) {
            window.location.href = `/estate/${UserProfile.AgentAccessInfo.estateId.toString()}/overview`;
            // setWaitRequest(false); // no need to set waitRequest to false
        } else {
            setWaitRequest(false);
            setAlert({ visible: true, message: t('WrongCredentials') });
        }
    };

    const resetStates = () => {
        setEmail('');
        setPassword('');
        setAlert({ visible: false, message: '' });
        setWaitRequest(false);
    };

    const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setTimeout(() => {
            // Any calls to load data go here
            resetStates();
            event.detail.complete();
        }, 500);
    };

    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    };

    const handleLogin = async () => {
        if (!email) {
            setAlert({ visible: true, message: 'Please enter a valid email' });
            return;
        }
        if (validateEmail(email) === false) {
            setAlert({ visible: true, message: 'Your email is invalid' });
            return;
        }

        if (!password || password.length < 3) {
            setAlert({ visible: true, message: 'Please enter your password' });
            return;
        }
        setWaitRequest(true);
        let response = await new initService().loginByCredentials(email, password);
        if (response) {
            window.location.href = '/dashboard';
            // setWaitRequest(false); // no need to set waitRequest to false
        } else {
            setWaitRequest(false);
            setAlert({ visible: true, message: t('WrongCredentials') });
        }
    };

    const checkSubmit = (e: any) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    const handleForgotPassword = () => (window.location.href = '/forgot-password');

    const pageHeight = useMemo(() => Math.max(document.documentElement.clientHeight, window.innerHeight || 0) + 'px', []);

    return (
        <IonPage className="login-page" style={{ height: pageHeight }}>
            <IonAlert
                isOpen={alert.visible}
                onDidDismiss={() => setAlert({ visible: false })}
                cssClass="my-custom-class"
                header={t('A_TitleError')}
                message={alert.message}
                buttons={[t('Close')]}
            />
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div className="login-header" data-cy="connekto-logo">
                    <ConnektoLogoWhite />
                </div>

                <div className="login-form-wrapper">
                    <IonItem lines="none" data-cy="email-field">
                        <IonLabel position="stacked">{t('MRS_Email')}</IonLabel>
                        <IonInput
                            data-cy="input-email-field"
                            type="email"
                            value={email}
                            onIonChange={(e) => setEmail(e.detail.value!)}
                            placeholder={t('MRS_EnterEmail')}
                            autofocus={true}
                            onKeyDown={checkSubmit}
                        ></IonInput>
                    </IonItem>
                    <PasswordInput
                        value={password}
                        onKeyDown={checkSubmit}
                        onChange={(e) => setPassword(e.detail.value!)}
                        dataCy="password-field"
                    />
                    <ButtonLoad dataCy="login-btn" className="btn-login" onClick={handleLogin} loading={waitRequest}>
                        {t('Login')}
                    </ButtonLoad>
                    <div className="forgot-password" onClick={handleForgotPassword} data-cy="forgot-password-link-button">
                        {t('FPWForgotPass')}
                    </div>
                </div>
                <PoweredBy />
            </IonContent>
        </IonPage>
    );
};

export default Login;
