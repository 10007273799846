import { useState } from 'react';
import { GoogleMap, useJsApiLoader, Circle, Marker, InfoWindow } from '@react-google-maps/api';
import './GMap.scss';
import { isMobile } from '../../utils/util';
import useSafeEffect from '../../hooks/useSafeEffect';
import IMarker from '../../interfaces/IMarker';

interface IGMap {
    initialLocation: { lat: number; lng: number };
    range: number;
    markers: IMarker[];
    onMapLoaded?: Function;
    onMapUpdated?: Function;
    refresh?: boolean;
}

const containerStyle = {
    height: isMobile() ? '100vw' : 'inherit',
    minHeight: '400px'
};

const GMap: React.FC<IGMap> = ({ range, markers, initialLocation, onMapLoaded, onMapUpdated, refresh }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCYj2lBZq4Ekr9kKu5eXrg1HKeCq4pYse0'
    });

    const [map, setMap] = useState<any>();
    const [zoom, setZoom] = useState<number>();
    const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
    const [mapLoaded, setMapLoaded] = useState<boolean>(false);

    useSafeEffect(() => {
        setZoom((10 - range) * (3 / 9) + 11);
    }, [range]);

    useSafeEffect(() => {
        if (!refresh || !mapLoaded) {
            return;
        }
        const bounds = new google.maps.LatLngBounds();
        //fit within bounds the similar estates
        if (markers) {
            markers.forEach((x) => bounds.extend({ lat: x.lat, lng: x.lng }));
        }
        const selectedMarker = markers.find((x) => x.isSelected);
        if (selectedMarker) {
            let currentLocation = { lat: selectedMarker.lat, lng: selectedMarker.lng };
            //fit within bounds the main estate
            bounds.extend(currentLocation);
            map.fitBounds(bounds);
        } else {
            setCenter(initialLocation);
        }
        onMapUpdated && onMapUpdated();
    }, [mapLoaded, refresh]);

    const onLoad = (map: any) => {
        setMap(map);
        setMapLoaded(true);
        onMapLoaded && onMapLoaded();
    };

    const onUnmount = (map: any) => {
        setMap(null);
    };

    const handleZoomChange = () => {
        let newZoom = map?.getZoom();
        newZoom && setZoom(newZoom);
    };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onZoomChanged={handleZoomChange}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                gestureHandling: 'cooperative',
                keyboardShortcuts: !isMobile(),
                mapTypeControlOptions: isMobile()
                    ? {}
                    : {
                          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                          position: google.maps.ControlPosition.TOP_RIGHT
                      }
            }}
        >
            {/* Child components, such as markers, info windows, etc. */}
            <Circle
                radius={range * 1000} //convert to meters
                center={center}
                options={{
                    strokeOpacity: 0.8,
                    strokeWeight: 0,
                    fillColor: '#6495ED',
                    fillOpacity: 0.15
                }}
            ></Circle>
            {markers.map((x, index) => (
                <Marker
                    key={index}
                    position={{ lat: x.lat, lng: x.lng }}
                    onClick={x.onClick}
                    icon={x.icon}
                    options={x.options}
                    zIndex={x.isSelected ? 999 : undefined}
                >
                    {x.isSelected && x.infoMessage && (
                        <InfoWindow
                            onCloseClick={() => {
                                x.onCloseClick && x.onCloseClick();
                            }}
                        >
                            <div className={'gmap-property-name-tooltip' + (x.infoWindowClassName || '')} onClick={x.onClick}>
                                {x.infoMessage}
                            </div>
                        </InfoWindow>
                    )}
                </Marker>
            ))}
        </GoogleMap>
    ) : (
        <></>
    );
};
export default GMap;
