import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, useIonAlert } from '@ionic/react';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import moment from 'moment';
import { capitalize, isMobile } from '../../../utils/util';
import estateService from '../../../services/estate';
import { DocumentIcon } from '../../../assets/svg-icons';
import Questionnaire from './Questionnaire';
import ActionItem from '../../core/ActionItem';
import IDocument from '../../../interfaces/IDocument';
import './Document.scss';
import FileUpload from '../../core/FileUpload';
import { BaseDocumentType } from '../../../commons';
import pdfGenerator from '../../../services/pdfGenerator';
import documentService from '../../../services/document';
import IUploadDocumentRequest from '../../../interfaces/IUploadDocumentRequest';
import useDialog from '../../../hooks/useDialog';
import useIonRefresher from '../../../hooks/useIonRefresher';
import useSafeEffect from '../../../hooks/useSafeEffect';
import UserProfile from '../../../lib/userProfile';

const Document: React.FC<{ estateId: number }> = ({ estateId }) => {
    const [docsFilledIn, setDocsFilledIn] = useState<IDocument[]>([]);
    const [docsToUpload, setDocsToUpload] = useState<IDocument[]>([]);
    const [docsProvided, setDocsProvided] = useState<IDocument[]>([]);
    const [docsFilledInVisible, setDocsFilledInVisible] = useState(true);
    const [docsToUploadVisible, setDocsToUploadVisible] = useState(true);
    const [docsProvidedVisible, setDocsProvidedVisible] = useState(true);
    const [questionnaire, setQuestionnaire] = useState<{ name: string; baseDocumentTypeId: number; isVisible: boolean }>({
        name: '',
        baseDocumentTypeId: 0,
        isVisible: false
    });
    const [uploadingDocumentBaseType, setUploadingDocumentBaseType] = useState<{ id: number; name: string } | null>(null);
    const { t } = useTranslation();
    const setDialog = useDialog();
    const [showAlert] = useIonAlert();
    const DocumentSource = { Owner: 2 };

    useSafeEffect(() => {
        loadDocuments(estateId);
        //eslint-disable-next-line
    }, [estateId]);

    const loadDocuments = async (estateId: number) => {
        let docsResponse = await estateService.getEstateDocuments(estateId);
        processDocuments(docsResponse);
    };

    useIonRefresher(
        async (seRefreshDone: Function) => {
            await loadDocuments(estateId);
            seRefreshDone();
        },
        'estate',
        'document'
    );

    const processDocuments = (response: any) => {
        if (response?.isValidRequest) {
            setDocsProvided((response.providedByAgent || []).filter((x: IDocument) => ![14, 15].includes(x.baseDocumentType.id)));
            // hide questionnaires for now
            // setDocsFilledIn(response.questionnaire || []);
            setDocsFilledIn([]);
            setDocsToUpload(response.providedByOwner || []);
        }
    };
    const onUpload = (baseDocumentTypeId: any) => {
        setUploadingDocumentBaseType(baseDocumentTypeId);
        const uploadDocumentElement = document.getElementById('upload-document');
        uploadDocumentElement && uploadDocumentElement.click();
    };

    const deleteFile = async (documentId: number | null | undefined) => {
        if (documentId) {
            let response = await estateService.deleteEstateDocument(estateId, documentId);
            if (response.isValidRequest) {
                await loadDocuments(estateId);
            } else {
                console.log('error');
            }
        }
    };

    const openFile = (url: string) => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const onSelectFiles = async (filesData: any) => {
        if (filesData.hasErrors) {
            await setDialog({
                type: 'error',
                message:
                    t('MRS_DocUploadError') +
                    ' ' +
                    ['png', 'gif', 'jpg', 'jpeg', 'webp', 'heic', 'pdf', 'doc', 'docx'].join(', .')
            });
            setUploadingDocumentBaseType(null);
            return;
        }
        let files: File[] = filesData?.files?.filter((x: File) => x.size > 0)?.reverse() || [];

        if (!(files.length > 0) || !uploadingDocumentBaseType) {
            setUploadingDocumentBaseType(null);
            return;
        }
        const firstFileType = files[0].type.split('/')[0];
        const sameExtension = files.every((x: File) => x.type.includes(firstFileType));
        if (!sameExtension || (firstFileType === 'application' && files.length > 1)) {
            await setDialog({ type: 'error', message: t('MRS_UploadDocumentsInfo') });
            setUploadingDocumentBaseType(null);
            return;
        }

        let document: any;
        let documentModel: IUploadDocumentRequest;
        documentModel = {
            baseDocumentTypeId: uploadingDocumentBaseType.id,
            isPublic: false,
            name: files[0].name
        };

        if (firstFileType === 'image') {
            //upload generated document with selected images
            document = await trackPromise(pdfGenerator.generatePdfFromImages(files));
            documentModel.name = uploadingDocumentBaseType.name + '.pdf';
        } else {
            //upload selected document
            document = files[0];
            //set correct name to pdf generated by DocumentScanner
            if (document.name === 'uploadedDoc.pdf') {
                documentModel.name = uploadingDocumentBaseType.name + '.pdf';
            }
        }

        let result = await documentService.uploadDocument(estateId, [documentModel], [document]);
        //update data
        if (result.isValidRequest && result?.documents?.length) {
            let newDocuments = [...docsToUpload];
            let index = newDocuments.findIndex((x) => x.baseDocumentType.id === uploadingDocumentBaseType.id);
            if (index >= 0) {
                newDocuments[index] = result?.documents[0];
            }
            setDocsToUpload(newDocuments);
        } else {
            let message = capitalize(t('ErrorOperationFailed'));
            if (result && result.validationErrors) {
                message = result.validationErrors.map((x: any) => '<div>' + x.message + '</div>').join('');
            }
            showAlert({ header: 'Error', message, buttons: ['OK'] });
        }
        setUploadingDocumentBaseType(null);
    };

    const onDeleteDocument = async (id: number | null | undefined) => {
        if (await setDialog({ type: 'confirm', title: t('A_TitleWarn'), message: t('T_A_ConfirmDeleteDocument') })) {
            deleteFile(id);
        }
    };

    const updateQuestionnaires = (response: any) => {
        setDocsFilledIn((prevState) => {
            let nextState = [...prevState];
            let item = nextState.find((x) => x.baseDocumentType.id === response.baseDocumentType.id);
            if (item) {
                item.id = response.id;
                item.private = response.private;
                item.name = response.name;
                item.size = response.size;
                item.url = response.url;
                item.updateDateTime = response.updateDateTime;
            }
            return nextState;
        });
    };

    const getDocumentDescription = (doc: IDocument) => {
        let momentDate = doc.updateDateTime ? moment(doc.updateDateTime) : null;
        let desc;
        if (momentDate) {
            desc = t('MRS_UploadedOn') + ' ' + momentDate.format('DD/MM/YYYY - HH:mm');
        } else {
            switch (doc.baseDocumentType.id) {
                case BaseDocumentType.Other:
                    desc = t('MRS_UploadOtherDocument');
                    break;
                default:
                    desc = t('MRS_NeedToUpload');
                    break;
            }
        }
        return desc;
    };

    return (
        <>
            <FileUpload
                maxFileSize="20Mb"
                validExtensions={['png', 'gif', 'jpg', 'jpeg', 'webp', 'heic', 'pdf', 'doc', 'docx']}
                accept="image/png, image/gif, image/jpg, image/jpeg, image/webp, image/heic, application/pdf, application/doc, application/docx;capture=camera"
                onFileSelect={onSelectFiles}
                multiple={true}
                canScan={true}
            >
                <div id="upload-document"></div>
            </FileUpload>
            <div className={!isMobile() ? 'document-cards-wrapper' : ''}>
                {docsFilledIn?.length > 0 && (
                    <IonCard
                        className={
                            'page-section document-card' +
                            (isMobile() ? ' mobile' : '') +
                            (docsFilledInVisible ? ' expanded-docs' : '')
                        }
                    >
                        <IonCardHeader onClick={() => setDocsFilledInVisible(!docsFilledInVisible)}>
                            <IonCardTitle>{t('MRS_DocsFilledIn')}</IonCardTitle>
                            <IonIcon icon={docsFilledInVisible ? chevronUpOutline : chevronDownOutline}></IonIcon>
                        </IonCardHeader>
                        <IonCardContent className="no-padding">
                            {docsFilledIn.map((x, index) => {
                                return (
                                    <ActionItem
                                        icon={<DocumentIcon />}
                                        name={x.baseDocumentType.name || ''}
                                        description={getDocumentDescription(x)}
                                        isComplete={x.name ? true : false}
                                        key={'doc-filled-in-' + index}
                                        attachmentName={x.name || ''}
                                        onDeleteAttachment={() => onDeleteDocument(x.id)}
                                        seeAttachment={() => {
                                            openFile(x.url || '');
                                        }}
                                        onComplete={() => {
                                            setQuestionnaire({
                                                isVisible: false,
                                                name: x.baseDocumentType.name || 'Questionnaire',
                                                baseDocumentTypeId: x.baseDocumentType.id
                                            });
                                        }}
                                        displayAttachmentName={false}
                                    />
                                );
                            })}
                        </IonCardContent>
                    </IonCard>
                )}
                {docsToUpload?.length > 0 && (
                    <IonCard
                        className={
                            'page-section document-card' +
                            (isMobile() ? ' mobile' : '') +
                            (docsToUploadVisible ? ' expanded-docs' : '') +
                            (docsProvided?.length > 0 ? ' column' : '')
                        }
                    >
                        <IonCardHeader onClick={() => setDocsToUploadVisible(!docsToUploadVisible)}>
                            <IonCardTitle>{t('MRS_DocsToUpload')}</IonCardTitle>
                            <IonIcon icon={docsToUploadVisible ? chevronUpOutline : chevronDownOutline}></IonIcon>
                        </IonCardHeader>
                        <IonCardContent className="no-padding">
                            {docsToUpload.map((x, index) => {
                                return (
                                    <ActionItem
                                        icon={<DocumentIcon />}
                                        name={x.baseDocumentType.name || ''}
                                        description={getDocumentDescription(x)}
                                        isComplete={x.name ? true : false}
                                        key={'doc-to-upload-' + index}
                                        attachmentName={x.name || ''}
                                        onDeleteAttachment={() => onDeleteDocument(x.id)}
                                        isDeleteAllowed={x.sourceId === DocumentSource.Owner || UserProfile.AgentAccessInfo}
                                        seeAttachment={() => {
                                            openFile(x.url || '');
                                        }}
                                        onComplete={() => onUpload(x.baseDocumentType)}
                                        isUpload={true}
                                        displayAttachmentName={true}
                                    />
                                );
                            })}
                        </IonCardContent>
                    </IonCard>
                )}
                {docsProvided?.length > 0 && (
                    <IonCard
                        className={
                            'page-section document-card' +
                            (isMobile() ? ' mobile' : '') +
                            (docsProvidedVisible ? ' expanded-docs' : '') +
                            (docsToUpload?.length > 0 ? ' column' : '')
                        }
                    >
                        <IonCardHeader onClick={() => setDocsProvidedVisible(!docsProvidedVisible)}>
                            <IonCardTitle>{t('MRS_DocsProvidedByAgent')}</IonCardTitle>
                            <IonIcon icon={docsProvidedVisible ? chevronUpOutline : chevronDownOutline}></IonIcon>
                        </IonCardHeader>
                        <IonCardContent className="no-padding">
                            {docsProvided.map((x, index) => {
                                return (
                                    <ActionItem
                                        icon={<DocumentIcon />}
                                        name={x.baseDocumentType.name || ''}
                                        description={getDocumentDescription(x)}
                                        isComplete={x.name ? true : false}
                                        key={'doc-provided-' + index}
                                        attachmentName={x.name || ''}
                                        seeAttachment={() => {
                                            openFile(x.url || '');
                                        }}
                                        onComplete={() => {}}
                                        displayAttachmentName={true}
                                    />
                                );
                            })}
                        </IonCardContent>
                    </IonCard>
                )}
            </div>
            <Questionnaire
                estateId={estateId}
                baseDocumentTypeId={questionnaire.baseDocumentTypeId}
                questionnaireName={questionnaire.name}
                isVisible={questionnaire.isVisible}
                onClose={() => {
                    setQuestionnaire({
                        isVisible: false,
                        name: '',
                        baseDocumentTypeId: 0
                    });
                }}
                onQuestionnaireLoaded={() => {
                    setQuestionnaire((prevState) => {
                        let nextState = { ...prevState };
                        nextState.isVisible = true;
                        return nextState;
                    });
                }}
                onGeneratePdf={updateQuestionnaires}
            />
        </>
    );
};
export default Document;
