import axios from 'axios';
import { getToken, setToken, getLocalStorage } from '../utils/localStorageUtil';
import Logger from '../services/logger';
import { formatAPIErrorMessages, isInside } from '../utils/util';

// const clearOverflow = () => {
//     setTimeout(() => {
//         if (document.body.style.overflow) {
//             document.body.style.removeProperty('overflow');
//         }
//     }, 100);
// };

const formatItemForValidation = (item: any, config: any): any => {
    return {
        location: window.location.pathname + window.location.hash,
        baseUrl: config.baseURL,
        url: config.url,
        data: config.data,
        code: item.code,
        property: item.property,
        resource: item.resource
    };
};

const MyLogger: Logger = new Logger();

let options = {
    baseURL: window.env.REACT_APP_API_URL
};

const client = axios.create(options);

client.interceptors.request.use(
    (config) => {
        let appVersion = getLocalStorage('Version');
        if (appVersion !== window.env.REACT_APP_VERSION && config.url !== '/token') {
            setToken('');
        }

        const token = getToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        // config.headers['Content-Type'] = 'application/json';

        if (config.method === 'put' && config.params && config.params.correlationId) {
            config.headers['x-correlation-id'] = config.params.correlationId;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

client.interceptors.response.use(
    function (response) {
        if (response.data && response.data.errors && response.data.errors.length > 0) {
            response.data.errors.forEach((item: any) => {
                MyLogger.warn('Validation from API', formatItemForValidation(item, response.config));
            });
            throw formatAPIErrorMessages(response.data.errors);
        }
        if (response.data && response.data.warnings && response.data.warnings.length > 0) {
            response.data.warnings.forEach((item: any) => {
                MyLogger.warn('Validation from API', formatItemForValidation(item, response.config));
            });
        }
        // clearOverflow();
        return response;
    },
    (error) => {
        if (error && error.response) {
            if (error.response.status === 401) {
                // setToken('');
                MyLogger.warn('Token expiration', error.response);

                // the redirect should happen only if we are in the whiseadmin
                // the condition is ignoring the public page and login
                if (isInside()) {
                    window.location.href = '/#/login';
                }
            } else if (error.response.status === 400) {
                MyLogger.warn('Invalid request', error.response);
                return error.response;
            } else {
                MyLogger.error('Unhandled from API', error.response);
            }
        }
    }
);

export default client;
