import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, useIonAlert, IonButton } from '@ionic/react';
import moment from 'moment';
import { OfferIcon } from '../../../assets/svg-icons';
import documentService from '../../../services/document';
import { capitalize, isMobile } from '../../../utils/util';
import ActionItem from '../../core/ActionItem';
import NoResults from '../../base/NoResults';
import useSafeEffect from '../../../hooks/useSafeEffect';
import FileUpload from '../../core/FileUpload';
import IUploadDocumentRequest from '../../../interfaces/IUploadDocumentRequest';
import { trackPromise } from 'react-promise-tracker';
import pdfGenerator from '../../../services/pdfGenerator';
import useDialog from '../../../hooks/useDialog';
import useIonRefresher from '../../../hooks/useIonRefresher';
import UserProfile from '../../../lib/userProfile';

interface IBaseDocument {
    id: number;
    name: string;
}
interface IOffer {
    id: number;
    baseDocumentType: IBaseDocument;
    name: string;
    private: boolean;
    size: number;
    updateDateTime: string;
    url: string;
}

const Offers: React.FC<{ estateId: number }> = ({ estateId }) => {
    const [offers, setOffers] = useState<IOffer[]>([]);
    const [acceptedOffers, setAcceptedOffers] = useState<IOffer[]>([]);
    const { t } = useTranslation();
    const setDialog = useDialog();
    const [showAlert] = useIonAlert();
    const uploadingDocumentBaseType = { id: 15, name: 'documents owner - offer accepted' };

    useSafeEffect(() => {
        load();
        //eslint-disable-next-line
        return () => {
            setOffers([]);
            setAcceptedOffers([]);
        };
        //eslint-disable-next-line
    }, []);

    useIonRefresher(
        async (seRefreshDone: Function) => {
            await load();
            seRefreshDone();
        },
        'estate',
        'offers'
    );

    const load = async () => {
        let data = await documentService.getOffers(estateId);
        setOffers(data?.offers?.filter((x: IOffer) => x.baseDocumentType.id === 14) || []);
        setAcceptedOffers(data?.offers?.filter((x: IOffer) => x.baseDocumentType.id === 15) || []);
    };

    const onUpload = () => {
        const uploadDocumentElement = document.getElementById('upload-document');
        uploadDocumentElement && uploadDocumentElement.click();
    };

    const onSelectFiles = async (filesData: any) => {
        if (filesData.hasErrors) {
            await setDialog({
                type: 'error',
                message:
                    t('MRS_DocUploadError') +
                    ' ' +
                    ['png', 'gif', 'jpg', 'jpeg', 'webp', 'heic', 'pdf', 'doc', 'docx'].join(', .')
            });
            return;
        }
        let files: File[] = filesData?.files?.filter((x: File) => x.size > 0)?.reverse() || [];

        if (!(files.length > 0)) {
            return;
        }
        const firstFileType = files[0].type.split('/')[0];
        const sameExtension = files.every((x: File) => x.type.includes(firstFileType));
        if (!sameExtension || (firstFileType === 'application' && files.length > 1)) {
            await setDialog({ type: 'error', message: t('MRS_UploadDocumentsInfo') });
            return;
        }

        let document: any;
        let documentModel: IUploadDocumentRequest;
        documentModel = {
            baseDocumentTypeId: uploadingDocumentBaseType.id,
            isPublic: false,
            name: files[0].name
        };

        if (firstFileType === 'image') {
            //upload generated document with selected images
            document = await trackPromise(pdfGenerator.generatePdfFromImages(files));
            documentModel.name = uploadingDocumentBaseType.name + '.pdf';
        } else {
            //upload selected document
            document = files[0];
        }

        let result = await documentService.uploadDocument(estateId, [documentModel], [document]);
        //update data
        if (result?.isValidRequest && result?.documents?.length) {
            let newDocuments = [...acceptedOffers];
            newDocuments.push(result?.documents[0]);
            setAcceptedOffers(newDocuments);
        } else {
            let message = capitalize(t('ErrorOperationFailed'));
            if (result && result.validationErrors) {
                message = result.validationErrors.map((x: any) => '<div>' + x.message + '</div>').join('');
            }
            showAlert({ header: 'Error', message, buttons: ['OK'] });
        }
    };

    const seeAttachment = (url: string) => window.open(url, '_blank');

    return (
        <>
            <FileUpload
                id="upload-document"
                maxFileSize="20Mb"
                validExtensions={['png', 'gif', 'jpg', 'jpeg', 'webp', 'heic', 'pdf', 'doc', 'docx']}
                accept="image/png, image/gif, image/jpg, image/jpeg, image/webp, image/heic, application/pdf, application/doc, application/docx"
                onFileSelect={onSelectFiles}
                multiple={true}
                canScan={true}
            />

            <div className={!isMobile() ? 'document-cards-wrapper' : ''}>
                <IonCard className={'page-section' + (isMobile() ? ' mobile' : '') + (offers?.length > 0 ? ' column' : '')}>
                    <IonCardHeader>
                        <IonCardTitle>{t('MRS_Offers')}</IonCardTitle>
                    </IonCardHeader>
                    {offers?.length > 0 ? (
                        <>
                            <IonCardContent className="no-padding">
                                {offers.map((x: IOffer, index: number) => {
                                    return (
                                        <ActionItem
                                            icon={<OfferIcon />}
                                            name={x.baseDocumentType.name}
                                            descriptionCompleted={
                                                t('UpdatedOn') + ' ' + moment(x.updateDateTime).format('DD/MM/YY - HH:MM')
                                            }
                                            isComplete={true}
                                            key={`offer-${index}`}
                                            attachmentName={x.name || ''}
                                            seeAttachment={() => seeAttachment(x.url)}
                                            onComplete={() => {}}
                                            displayAttachmentName={true}
                                        />
                                    );
                                })}
                            </IonCardContent>
                        </>
                    ) : (
                        <IonCardContent>
                            <NoResults icon={<OfferIcon />} info={t('MRS_NoOffers')} />
                        </IonCardContent>
                    )}
                </IonCard>
                {offers?.length > 0 && (
                    <IonCard className={'page-section column' + (isMobile() ? ' mobile' : '')}>
                        <IonCardHeader>
                            <IonCardTitle>{t('MRS_OffersAccepted')}</IonCardTitle>
                        </IonCardHeader>
                        {acceptedOffers?.length > 0 ? (
                            <>
                                <IonCardContent className="no-padding">
                                    {acceptedOffers.map((x: IOffer, index: number) => {
                                        return (
                                            <ActionItem
                                                icon={<OfferIcon />}
                                                name={x.baseDocumentType.name}
                                                descriptionCompleted={
                                                    t('UpdatedOn') + ' ' + moment(x.updateDateTime).format('DD/MM/YY - HH:MM')
                                                }
                                                isComplete={true}
                                                key={`offer-accepted-${index}`}
                                                attachmentName={x.name || ''}
                                                seeAttachment={() => seeAttachment(x.url)}
                                                onComplete={() => {}}
                                                displayAttachmentName={true}
                                            />
                                        );
                                    })}
                                </IonCardContent>
                            </>
                        ) : (
                            <IonCardContent className="no-padding no-offer-content">
                                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                                    {t('MRS_NoAcceptedOffersMessage')}
                                </div>
                                <IonButton
                                    fill="outline"
                                    style={isMobile() ? { width: '100%' } : {}}
                                    onClick={onUpload}
                                    disabled={UserProfile.AgentAccessInfo ? true : false}
                                >
                                    {t('MRS_UploadSignedOffer')}
                                </IonButton>
                            </IonCardContent>
                        )}
                    </IonCard>
                )}
            </div>
        </>
    );
};
export default Offers;
