import { getLocalStorage, setLocalStorage } from '../utils/localStorageUtil';

class ProfileData {
    get WhiseUrl(): string {
        return getLocalStorage('WhiseUrl');
    }
    set WhiseUrl(value: string) {
        setLocalStorage('WhiseUrl', value);
    }
    get AccessToken(): string {
        return getLocalStorage('AccessToken');
    }
    set AccessToken(value: string) {
        setLocalStorage('AccessToken', value);
    }

    get UserId(): number {
        return Number(getLocalStorage('UserId'));
    }
    set UserId(value: number) {
        setLocalStorage('UserId', value);
    }

    get LanguageId(): string {
        return getLocalStorage('LanguageId');
    }
    set LanguageId(value: string) {
        setLocalStorage('LanguageId', value);
    }

    get FirstName(): string {
        return getLocalStorage('FirstName');
    }
    set FirstName(value: string) {
        setLocalStorage('FirstName', value);
    }

    get LastName(): string {
        return getLocalStorage('LastName');
    }
    set LastName(value: string) {
        setLocalStorage('LastName', value);
    }

    get FullName(): string {
        return getLocalStorage('FullName');
    }
    set FullName(value: string) {
        setLocalStorage('FullName', value);
    }

    get Email(): string {
        return getLocalStorage('Email');
    }
    set Email(value: string) {
        setLocalStorage('Email', value);
    }

    get Picture(): string {
        return getLocalStorage('Picture');
    }
    set Picture(value: string) {
        setLocalStorage('Picture', value);
    }

    get Phone(): string {
        return getLocalStorage('Phone');
    }
    set Phone(value: string) {
        setLocalStorage('Phone', value);
    }

    get AgentAccessInfo(): any {
        return JSON.parse(getLocalStorage('AgentAccessInfo'));
    }
    set AgentAccessInfo(value: any) {
        setLocalStorage('AgentAccessInfo', JSON.stringify(value));
    }
}

const UserProfile = new ProfileData();
export default UserProfile;
