import { useParams } from 'react-router-dom';
import GeneralLayout from '../components/core/GeneralLayout';
import EstateMain from '../components/estate/EstateMain';
import useDocumentTitle from '../hooks/useDocumentTitle';
import NoAccessPage from '../components/core/NoAccessPage';
import UserProfile from '../lib/userProfile';

const EstatePage: React.FC = () => {
    useDocumentTitle();
    const { estateId, type } = useParams<{ estateId: string; type: string }>();
    return UserProfile.AgentAccessInfo && UserProfile.AgentAccessInfo?.estateId?.toString() !== estateId ? (
        <NoAccessPage />
    ) : (
        <GeneralLayout>
            <EstateMain view={type} estateId={parseInt(estateId)} />
        </GeneralLayout>
    );
};

export default EstatePage;
