import axios, { AxiosRequestConfig } from 'axios';
// import md5 from 'md5';
import i18n from '../lib/i18n';

import { removeLocalStorage, setLocalStorage } from '../utils/localStorageUtil';
import { decodeBase64 } from '../utils/cryptoUtil';
import { setUserProfile } from '../utils/profileUtil';
import IProfile from '../interfaces/IProfile';

class Init {
    public async loginByCredentials(
        username: string,
        password: string,
        impersonated?: string,
        passwordIsEncoded?: boolean,
        languageId?: string
    ): Promise<boolean> {
        let client = axios.create({
            baseURL: window.env.REACT_APP_API_URL
        });

        let encodedPassword = password; // passwordIsEncoded ? password : md5(password);
        let response: any;

        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const requestData = {
            UserName: username,
            Password: encodedPassword
        };
        try {
            response = await client.post('users/token', requestData, config);
        } catch {
            return false;
        }

        return await this.initUserProfile(response, languageId, impersonated);
    }

    public async loginByToken(customToken: string, impersonated?: string, languageId?: string): Promise<boolean> {
        let client = axios.create({
            baseURL: window.env.REACT_APP_API_URL
        });

        let response: any;

        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const requestData = {
            CustomToken: encodeURIComponent(customToken)
        };
        try {
            response = await client.post('users/agentaccesstoken', requestData, config);
        } catch {
            return false;
        }

        return await this.initUserProfile(response, languageId, impersonated);
    }

    private async initUserProfile(response: any, languageId?: string, impersonated?: string) {
        debugger;
        if (response && response.data && response.data.token) {
            let token = response.data.token;
            let language: string = languageId || 'en-GB';
            if (impersonated) {
                setLocalStorage('OriginalData', impersonated);
                let originalData = JSON.parse(decodeBase64(impersonated));
                originalData.languageId && (language = originalData.languageId);
            } else removeLocalStorage('OriginalData');

            response = await this.getUserProfile(token, language);
            if (response) {
                setLocalStorage('AccessToken', token);
                setLocalStorage('Version', window.env.REACT_APP_VERSION);
                this.initializeCulture(response?.languageId || language);
                return true;
            }
        }
        return false;
    }

    private initializeCulture(languageId: string) {
        localStorage.removeItem('i18next_res_en-GB-translation');
        if (languageId !== 'en-GB') {
            localStorage.removeItem(`i18next_res_${languageId}-translation`);
        }

        i18n.changeLanguage(languageId);
    }

    private async getUserProfile(token: string, language: string): Promise<any> {
        let client = axios.create({
            baseURL: window.env.REACT_APP_API_URL,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        let response;
        try {
            debugger
            response = await client.get('users/profile');
            console.log({response});
        } catch {
            console.error('Could not load user profile');
            return;
        }

        let profile: IProfile = response.data;
        const timeStamp = new Date().getTime();
        profile.pictureUrl = profile.pictureUrl + '?t=' + timeStamp;
        let WhiseUrl;
        if (window.env.REACT_APP_ENV?.indexOf('prd') > -1) {
            WhiseUrl = `https://prd.storagewhise.eu/public/whise/Images`;
        } else {
            WhiseUrl = `https://stg.storagewhise.eu/public/whise/Images`;
        }
        profile.WhiseUrl = WhiseUrl;

        if (!profile || !profile.userId) {
            return;
        }
        setUserProfile(profile);
        return profile;
    }
}

export default Init;
