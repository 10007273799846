import IProfile from '../interfaces/IProfile';
import UserProfile from '../lib/userProfile';

export const setUserProfile = (profile: IProfile): void => {
    UserProfile.UserId = profile.userId;
    UserProfile.LanguageId = profile.languageId;
    UserProfile.FirstName = profile.firstName || '';
    UserProfile.LastName = profile.lastName || '';
    UserProfile.FullName = (profile?.firstName ? profile?.firstName + ' ' : '') + profile?.lastName || '';
    UserProfile.Email = profile.email;
    UserProfile.Picture = profile.pictureUrl || '';
    UserProfile.Phone = profile.phone || '';
    UserProfile.AgentAccessInfo = profile.agentAccessInfo || '';
    UserProfile.WhiseUrl = profile.WhiseUrl || '';
};
