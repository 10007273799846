/**
 * Create a new state object based on the previous state, by updating only the fields from the data object
 * example: getUpdatedState(prevState, { field1: newValue1, field2: newValue2})
 * @param {object} prevState The previous state
 * @param {object} data the new state data
 * @returns string
 */
export const getUpdatedState = (prevState: any, data: any) => {
    let draft = { ...prevState };
    Object.keys(data).forEach(function (key) {
        draft[key] = data[key];
    });
    return draft;
};
