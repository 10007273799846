import React, { useEffect, useState } from 'react';
import { IonIcon, isPlatform } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import './SmartBanner.scss';
import { isIOS } from '../../utils/util';

const Smartbanner: React.FC<{ t: any }> = ({ t }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isPlatform('mobileweb') && !localStorage.getItem('smart-banner-displayed')) {
            setIsVisible(true);
        }
    }, []);

    const onClose = () => {
        localStorage.setItem('smart-banner-displayed', 'true');
        setIsVisible(false);
    };
    const redirect = () => {
        if (isIOS()) {
            window.open(`https://apps.apple.com/be/app/connekto/id6446782282`, '_blank');
        } else {
            window.open(`http://play.google.com/store/apps/details?id=eu.connekto.ionic`, '_blank');
        }
        onClose();
    };

    if (!isVisible) {
        return <></>;
    }
    return (
        <div className="smart-banner">
            <div className="close" onClick={onClose}>
                <IonIcon icon={closeOutline} />
            </div>
            <img src="../../assets/icon/iOS_Connekto.png" alt="logo" />
            <div className="descriptions">
                <div className="app-name">Connekto</div>
                <div className="company-name">WHISE Organisation</div>
                <div className="text">{t(isIOS() ? 'SB_OnAppStore' : 'SB_OnGooglePlay')}</div>
            </div>
            <div className="action" onClick={redirect}>
                {t('View')}
            </div>
        </div>
    );
};
export default Smartbanner;
