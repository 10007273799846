import './EstateListItem.scss';
import EstateDetail from './EstateDetail';
import StatusLabel from '../base/StatusLabel';
import { capitalize, formatUserPrice, isMobile } from '../../utils/util';
import IEstate from '../../interfaces/IEstate';
import { getEstateDetails, getEstateStatus } from '../../utils/estateUtil';
import ImageFallback from '../core/ImageFallback';
import UserProfile from '../../lib/userProfile';

interface IEstateItem {
    t: any;
    estate: IEstate;
    onClick: any;
    onMouseOver: any;
}

const EstateListItem: React.FC<IEstateItem> = ({ t, estate, onClick, onMouseOver }) => {
    const status = getEstateStatus(estate);
    const details = getEstateDetails(estate);
    let energyClassUrl;
    if (estate.energyClass) {
        energyClassUrl = `${UserProfile.WhiseUrl}/ec/small_${estate.energyClass}`;
    }
    const getPictureIcon = (estate: IEstate) => {
        if (!estate?.pictures?.length) {
            return '';
        }
        return estate?.pictures[0]?.urlSmall || estate?.pictures[0]?.urlLarge || estate?.pictures[0]?.urlXXL || '';
    };
    return (
        <div className={'estate-list-item' + (isMobile() ? ' mobile' : '')} onMouseOver={onMouseOver} onClick={onClick}>
            <div className="estate-picture-block">
                <ImageFallback
                    className="estate-picture"
                    src={getPictureIcon(estate)}
                    fallback="./assets/img/house-noimage.png"
                    alt="estate"
                />
                {energyClassUrl && <img className="energy-class" src={energyClassUrl} alt=""></img>}
            </div>
            <div className="info">
                <div className="title trim-overflow">{capitalize(estate?.subCategory?.name)}</div>
                <div className="details">
                    {details
                        .filter((x: any) => x.label !== 'Office')
                        .map((x: any, index: number) => (
                            <EstateDetail key={index} t={t} detail={x} noLabels />
                        ))}
                </div>
                <div className="price-status">
                    <div className="price">
                        {estate.price
                            ? formatUserPrice(estate.price, null, estate.currency)
                            : capitalize(t('MRS_ConfidentialPrice'))}
                    </div>
                    <StatusLabel label={status.name} color={status.color} />
                </div>
            </div>
        </div>
    );
};
export default EstateListItem;
