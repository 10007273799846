import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../contexts/AppContext';
import IEstate from '../../interfaces/IEstate';
import estateService from '../../services/estate';
import { isMobile, img } from '../../utils/util';
import useSafeEffect from '../../hooks/useSafeEffect';
import useIonRefresher from '../../hooks/useIonRefresher';
import EstateCard from './EstateCard';
import './Dashboard.scss';
import NoResults from '../base/NoResults';
import { IonIcon } from '@ionic/react';
import { homeOutline } from 'ionicons/icons';

const Dashboard: React.FC = () => {
    const appState = useContext(AppContext);
    const [estates, setEstates] = useState<IEstate[]>();
    const { t } = useTranslation();

    useSafeEffect(() => {
        load();
        //eslint-disable-next-line
    }, []);

    const load = async () => {
        let estates = await estateService.getEstatesData();
        setEstates(estates);
    };

    useIonRefresher(async (seRefreshDone: Function) => {
        await load();
        seRefreshDone();
    }, 'dashboard');

    return (
        <>
            <div className="dashboard-header" style={!isMobile() ? { height: '190px' } : {}}>
                <div className="dashboard-text" style={!isMobile() ? { left: '10%', top: '65px' } : {}}>
                    <div className="dashboard-user">
                        {t('MRS_Greetings')} {appState?.state?.user?.firstName},
                    </div>
                    <div>{t('MRS_DashboardFind')}</div>
                </div>
                <img className="dashboard-img" src={img('MRS-background-people.jpg')} alt=""></img>
            </div>
            <div className={'estates-wrapper' + (!estates?.length ? ' no-estates-activated' : '')}>
                {estates?.length ? (
                    <div className="estates">
                        {estates.map((x, index) => (
                            <EstateCard key={`estate-${index}`} {...x} />
                        ))}
                    </div>
                ) : (
                    <NoResults
                        title={t('MRS_EmptyDashboard')}
                        info={t('MRS_EmptyDashboardInfo')}
                        icon={<IonIcon icon={homeOutline} />}
                    />
                )}
            </div>
        </>
    );
};

export default Dashboard;
