import { format } from './util';

/**
 * Returns the extension  of a given file path, without the `.`, or empty string if no extension was found or file starts with `.`
 * @param filePath: string
 * @returns string
 */
export const getFileExtension = (filePath: string): string => {
    let basename: string = filePath.split(/[\\/]/).pop() || ''; // extract file name from full path ...
    let pos = basename.lastIndexOf('.'); // get last position of `.`
    if (basename === '' || pos < 1) {
        // if file name is empty or `.` not found dot or dot is in the first position of the file name (0)
        return '';
    }

    return basename.slice(pos + 1);
};

/**
 * get file name without extension from a path
 * @param {string} filePath
 * @returns
 */
export const getFileNameWithoutExtension = (filePath: string): string => {
    let basename: string = filePath.split(/[\\/]/).pop() || ''; // extract file name from full path ...
    let pos = basename.lastIndexOf('.'); // get last position of `.`
    if (basename === '' || pos < 1) {
        // if file name is empty or ...
        return basename; //  `.` not found (-1) or comes first (0)
    }
    return basename.slice(0, pos);
};

/**
 * get file name from a path
 * @param {string} filePath
 * @returns
 */
export const getFileName = (filePath: string): string => {
    let basename = filePath.split(/[\\/]/).pop() || ''; // extract file name from full path ...
    return basename;
};

/**
 * Check if the given file extension is valid
 * @param {string} fileName : file to check extension
 * @param {array} validExtensions : array with the accepted extensions
 * @returns {bool}
 */
export const matchExtension = (fileName: string, validExtensions: string[]): boolean => {
    let isValidExtension = true;
    if (validExtensions) {
        let ext = getFileExtension(fileName.toLowerCase());
        if (validExtensions.indexOf(ext) === -1) {
            isValidExtension = false;
        }
    }
    return isValidExtension;
};

/**
 * Check if the given file size is bigger than the specified maxFileSize
 * @param {number} fileSize : file size to check
 * @param {number} maxFileSize : max allowed file size
 * @returns {bool} true if fileSize is less or equal to maxFileSize, false otherwise
 */
export const checkFileSize = (fileSize: number, maxFileSize: number): boolean => {
    let isValidFileSize = true;
    if (maxFileSize && fileSize > maxFileSize) {
        isValidFileSize = false;
    }
    return isValidFileSize;
};

/**
 * Check if the given file size is smaller than the specified minFileSize
 * @param {number} fileSize : file size to check
 * @param {number} minFileSize : min allowed file size
 * @returns {bool} true if fileSize is bigger or equal to minFileSize, false otherwise
 */
export const checkMinFileSize = (fileSize: number, minFileSize: number): boolean => {
    let isValidFileSize = true;
    if (minFileSize !== undefined && fileSize < minFileSize) {
        isValidFileSize = false;
    }
    return isValidFileSize;
};

/**
 * transform a numeric size into human readable format
 * @param {number} size : file size
 * @returns {string}
 */
export const humanFileSize = (size: number): string => {
    if (!size || size === 0) {
        return '0 kB';
    }
    let i = Math.floor(Math.log(size) / Math.log(1000));
    return (size / Math.pow(1000, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

/**
 * get current attachments files size in mega bytes
 * @returns fileSize
 */
export const getAttachmentsSize = (attachments: any[]) => {
    let filesSize = 0;
    if (attachments && attachments.length > 0) {
        for (let file of attachments) {
            if (file.sizeMb) {
                filesSize += file.sizeMb;
            }
        }
    }
    return filesSize;
};

/**
 * format the attachment items for display, translate the errors from filesData and set the total size of the attachments
 * @param {array} filesData object which contains files to attach and error messages if any: { errors, files, hasErrors }
 * @param {array} attachments existing attachments
 * @param {object} t translation object
 * @returns {array} list with the newly attached files
 */
export const prepareFilesForAdd = (filesData: any, attachments: any[], t: any): any[] => {
    if (!filesData.files || filesData.files.length === 0) {
        return [];
    }

    let list = [];
    let attachmentItem;
    let currentAttachmentsSize = getAttachmentsSize(attachments);
    let newAttachmentsSize = 0;
    for (let file of filesData.files) {
        attachmentItem = {
            name: file.name,
            size: humanFileSize(file.size),
            sizeMb: file.size / (1 << 20),
            file: file
        };
        list.push(attachmentItem);
        newAttachmentsSize += attachmentItem.sizeMb;
    }
    if (filesData.hasErrors) {
        setUploadErrorsTranslation(filesData.errors, t);
    }
    filesData.totalFilesSize = currentAttachmentsSize + newAttachmentsSize;
    return list;
};

/**
 * Perfoms the validation for extension and size for the give files, extensions and maxFileSize
 * and returns an object with validFileList, invalidFileList, hasErrors
 * @param {array} files array with the files data
 * @param {array} validExtensions array with the accepted extensions
 * @param {number|string} minSize number or a string of format '45 Kb|Mb|Gb' specifying the max allowed size in bytes for upload
 * @param {number|string} maxSize number or a string of format '45 Kb|Mb|Gb' specifying the max allowed size in bytes for upload
 * @param {boolean} preventEmpty if true, will return error
 * @returns {Object}
 */
export const validateFiles = (files: any[], validExtensions: any[], minSize: any, maxSize: any, preventEmpty: boolean) => {
    let response: any = { validFileList: [], invalidFileList: [], hasErrors: false, errors: null };
    for (let file of files) {
        let validExtension = matchExtension(file.name, validExtensions);
        if (typeof minSize === 'string') {
            minSize = fromHumanFileSize(minSize);
        }
        if (typeof maxSize === 'string') {
            maxSize = fromHumanFileSize(maxSize);
        }
        let validMaxSize = checkFileSize(file.size, maxSize);
        let validMinSize = checkMinFileSize(file.size, minSize);
        let validEmptyFile = preventEmpty ? file.size > 0 : true;

        if (validExtension && validMinSize && validMaxSize && validEmptyFile) {
            response.validFileList.push(file);
        } else {
            if (!validExtension) {
                file.invalidExtension = true;
            }
            if (!validMinSize) {
                file.invalidMinSize = true;
            }
            if (!validMaxSize) {
                file.invalidMaxSize = true;
            }
            if (!validEmptyFile) {
                file.invalidEmptyFile = true;
            }
            response.invalidFileList.push(file);
        }
    }

    response.hasErrors = response.invalidFileList.length > 0;
    if (response.hasErrors) {
        response.errors = getUploadErrors(response.invalidFileList, minSize, maxSize);
    }

    return response;
};

const getErrorDescription = (error: any) => {
    let errorDescription;
    switch (error.code) {
        case 'invalid_extension':
            errorDescription = { key: 'GI_FileUploadInvalidExtension', fileName: error.fileName };
            break;
        case 'invalid_max_file_size':
            errorDescription = {
                key: 'GI_FileUploadInvalidSize',
                fileName: error.fileName,
                fileSize: humanFileSize(error.fileSize),
                maxFileSize: humanFileSize(error.maxFileSize)
            };
            break;
        case 'invalid_min_file_size':
            errorDescription = {
                key: 'GI_FileUploadInvalidMinSize',
                fileName: error.fileName,
                fileSize: humanFileSize(error.fileSize),
                maxFileSize: humanFileSize(error.maxFileSize)
            };
            break;
        case 'invalid_empty_file':
            errorDescription = { key: 'GI_FileUploadEmpty', fileName: error.fileName };
            break;
        default:
            break;
    }
    return errorDescription;
};

/**
 * Returns the translated errors occurred on verifying the files extension and size (if specified)
 * @param {array} files files with errors
 * @param {number} minFileSize number specifying the min allowed size in bytes for upload
 * @param {number} maxFileSize number specifying the max allowed size in bytes for upload
 * @returns {Array}
 */
const getUploadErrors = (files: any[], minFileSize: number, maxFileSize: number) => {
    let errors: any = [];
    let error: any;
    for (let file of files) {
        if (file.invalidExtension) {
            errors.push({ code: 'invalid_extension', fileName: file.name });
        }
        if (file.invalidMaxSize) {
            error = { code: 'invalid_max_file_size', fileName: file.name, fileSize: file.size, maxFileSize };
            error.description = getErrorDescription(error);
            errors.push(error);
        }
        if (file.invalidMinSize) {
            error = { code: 'invalid_min_file_size', fileName: file.name, fileSize: file.size, minFileSize };
            error.description = getErrorDescription(error);
            errors.push(error);
        }
        if (file.invalidEmptyFile) {
            error = { code: 'invalid_empty_file', fileName: file.name };
            error.description = getErrorDescription(error);
            errors.push(error);
        }
    }
    return errors;
};

/**
 * set upload errors translations
 * @param {array} errors array of errors
 * @param {object} t translations object
 */
const setUploadErrorsTranslation = (errors: any[], t: any): any[] => {
    const errorMessage: any = [];
    for (let error of errors) {
        switch (error.code) {
            case 'invalid_extension':
                error.message = format(t(error.description.key), error.description.fileName);
                break;
            case 'invalid_empty_file':
                error.message = format(t(error.description.key), error.description.fileName);
                break;
            case 'invalid_file_size':
            case 'invalid_max_file_size':
                error.message = format(
                    t(error.description.key),
                    error.description.fileName,
                    error.description.fileSize,
                    error.description.maxFileSize
                );
                break;
            default:
                break;
        }
    }
    return errorMessage;
};

/**
 * transform a file size from string to a number
 * @param {string} humanFileSize : file to check extension
 * @returns {number} file size in bytes
 */
export const fromHumanFileSize = (humanFileSize: string): number => {
    let match = humanFileSize.match(/((?:[0-9]*[.])?[0-9]+)\s*(\w+)/);
    if (!match || match.length < 2) {
        return 0;
    }
    let size = parseFloat(match[1]);
    let unit = match[2].toLowerCase();

    let i = ['b', 'kb', 'mb', 'gb', 'tb'].indexOf(unit);
    if (i === -1) {
        return 0;
    } else {
        return size * Math.pow(1000, i);
    }
};

/**
 * read a blob and get the base64 data from i
 * @param {Blob} imageBlob
 * @returns {Promise<string | ArrayBuffer | null>}
 */
export const getBase64DataFromBlob = async (imageBlob: Blob): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
            let base64data = reader.result;
            resolve(base64data?.toString() || '');
        };
        reader.onerror = () => {
            reject(null);
        };
    });
};

export const getImageSize = async (imageBlob: Blob) => {
    return new Promise(async (resolve, reject) => {
        let img: HTMLImageElement = new Image();
        let base64Data = await getBase64DataFromBlob(imageBlob);
        img.onload = () => {
            let size = { width: img.width, height: img.height };
            resolve(size);
        };
        img.onerror = () => {
            reject(null);
        };
        img.src = base64Data;
    });
};

export const getImageSizeFromBase64Data = async (base64Data: string): Promise<{ width: number; height: number } | null> => {
    return new Promise(async (resolve, reject) => {
        let img = new Image();
        img.onload = () => {
            let size = { width: img.width, height: img.height };
            resolve(size);
        };
        img.onerror = () => {
            reject(null);
        };
        img.src = base64Data;
    });
};

export const getBlobAsText = async (blob: Blob): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result?.toString() || '');
        };
        reader.onerror = () => {
            reject(null);
        };
        reader.readAsText(blob);
    });
};

/**
 * resize an image to a new size
 * @param {Blob} imageBlob
 * @param {object} size { percent } or { width, height }
 * @returns
 */
export const resizeImage = (imageBlob: Blob, size: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let canvas: any = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let img = new Image();

        let base64Data = await getBase64DataFromBlob(imageBlob);
        img.onload = () => {
            var newWidth = 0;
            var newHeight = 0;
            if (size.percent) {
                if (img.width >= img.height) {
                    newWidth = Math.floor((img.width * size.percent) / 100);
                    newHeight = Math.floor(calculateDimension(img.width, img.height, newWidth));
                } else {
                    newHeight = Math.floor((img.height * size.percent) / 100);
                    newWidth = Math.floor(calculateDimension(img.height, img.width, newHeight));
                }
            } else if (size.width && size.height) {
                if (size.height === '*') {
                    if (size.width >= img.width) {
                        newWidth = img.width;
                        newHeight = img.height;
                    } else {
                        newWidth = size.width;
                        newHeight = Math.floor(calculateDimension(img.width, img.height, newWidth));
                    }
                } else if (size.width === '*') {
                    if (size.height >= img.height) {
                        newWidth = img.width;
                        newHeight = img.height;
                    } else {
                        newHeight = size.height;
                        newWidth = Math.floor(calculateDimension(img.height, img.width, newHeight));
                    }
                } else {
                    // fixed size
                    newWidth = size.width;
                    newHeight = size.height;
                }
            }
            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.clearRect(0, 0, newWidth, newHeight);
            ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
            canvas.toBlob((blob: Blob) => {
                resolve(blob);
            }, 'image/png');
        };

        img.onerror = () => {
            reject();
        };

        img.src = base64Data;
    });
};

/**
 * resize an image to a new size
 * @param {Blob} imageBlob
 * @param {object} size { percent } or { width, height }
 * @returns
 */
export const resizeImageFromFile = (file: File, size: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let canvas: any = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let img = new Image();

        img.onload = () => {
            var newWidth = 0;
            var newHeight = 0;
            if (size.percent) {
                if (img.width >= img.height) {
                    newWidth = Math.floor((img.width * size.percent) / 100);
                    newHeight = Math.floor(calculateDimension(img.width, img.height, newWidth));
                } else {
                    newHeight = Math.floor((img.height * size.percent) / 100);
                    newWidth = Math.floor(calculateDimension(img.height, img.width, newHeight));
                }
            } else if (size.width && size.height) {
                if (size.height === '*') {
                    if (size.width >= img.width) {
                        newWidth = img.width;
                        newHeight = img.height;
                    } else {
                        newWidth = size.width;
                        newHeight = Math.floor(calculateDimension(img.width, img.height, newWidth));
                    }
                } else if (size.width === '*') {
                    if (size.height >= img.height) {
                        newWidth = img.width;
                        newHeight = img.height;
                    } else {
                        newHeight = size.height;
                        newWidth = Math.floor(calculateDimension(img.height, img.width, newHeight));
                    }
                } else {
                    // fixed size
                    newWidth = size.width;
                    newHeight = size.height;
                }
            }
            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.clearRect(0, 0, newWidth, newHeight);
            ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
            img.onload = () => {
                resolve(img);
            };
            img.src = canvas.toDataURL();
        };

        img.onerror = () => {
            reject();
        };

        img.src = URL.createObjectURL(file);
    });
};

/**
 * Convert angle from degrees to radians
 *
 * @param {number} degreeValue degrees value
 * @return {number}
 */
const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
};

/**
 * Get width and height considering a rotation angle
 *
 * @param {number} width
 * @param {number} height
 * @param {number} rotation in degrees
 * @return {number}
 */
export const rotateSize = (width: number, height: number, rotation: number) => {
    const rotRad = getRadianAngle(rotation);
    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
    };
};

/**
 * crop an image using the specified crop information.
 * @param {Blob} imageSrc the source of the image
 * @param {object} cropInfo { x, y, width, height, rotation }
 * @param {object} flip { horizontal: boolean, vertical: boolean }
 * @returns
 */
export const cropImage = (imageSrc: Blob, cropInfo: any, flip = { horizontal: false, vertical: false }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        let canvas: any = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let img = new Image();

        let base64Data = await getBase64DataFromBlob(imageSrc);
        img.onload = () => {
            const rotRad = getRadianAngle(cropInfo.rotation);
            const { width: bBoxWidth, height: bBoxHeight } = rotateSize(img.width, img.height, cropInfo.rotation);
            // set canvas size to match the bounding box
            canvas.width = bBoxWidth;
            canvas.height = bBoxHeight;

            ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
            ctx.rotate(rotRad);
            ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
            ctx.translate(-img.width / 2, -img.height / 2);
            ctx.drawImage(img, 0, 0);

            const data = ctx.getImageData(cropInfo.x, cropInfo.y, cropInfo.width, cropInfo.height);
            canvas.width = cropInfo.width;
            canvas.height = cropInfo.height;
            ctx.putImageData(data, 0, 0);

            canvas.toBlob((blob: Blob) => {
                resolve(blob);
            }, 'image/png');
        };

        img.onerror = () => {
            reject();
        };
        img.src = base64Data;
    });
};

/**
 * calculate one dimenison of an image (width or height), in order to preserve the aspect ration when rescaling
 * example: let newHeight = calculateDimension(img.width, img.height, newWidth);
 * @param {number} d1 first dimension of the initial image (width or height)
 * @param {number} d2 second dimension of the initial image (height or width)
 * @param {number} newD1 first dimension of the new image (width or height)
 * @returns {number} the second dimension of the new image (height or width)
 */
const calculateDimension = (d1: number, d2: number, newD1: number) => {
    var p = (100 * newD1) / d1;
    var newHeight = (p * d2) / 100;
    return newHeight;
};

export const formatDocumentsUrlFriendly = (documentsModels: any) => {
    let fileExt = '';
    let fileName = '';
    for (let doc of documentsModels) {
        fileExt = getFileExtension(doc.url);
        fileName = getFileNameWithoutExtension(doc.url);
        // fileName = removeDiacritics(fileName);
        if (fileExt !== '') {
            fileName += '.' + fileExt;
        }
        doc.url = fileName;
    }
};
