// import moment from 'moment';
// import LingerManager from './lingerManager';
// import { getToken } from '../lib/auth';

export default class Logger {
    warn = (message: string, context: string) => {
        this.log(message, context, 'warn');
    };

    error = (message: string, context: string) => {
        this.log(message, context, 'error');
    };

    log = (message: string, context: string, level: string) => {
        console.log(message, context, level);
    };
}

//export default new Logger();
