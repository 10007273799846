import { capitalize } from '../../utils/util';
import './EstateDetail.scss';
interface IEstateDetail {
    t: any;
    detail: any;
    noLabels?: boolean;
}

const EstateDetail: React.FC<IEstateDetail> = ({ t, detail, noLabels }) => {
    return (
        <div className="estate-detail-item">
            <div>{detail.icon()}</div>
            &nbsp;&nbsp;
            {detail.valueFirst ? (
                <>
                    <div className="value">{detail.value}</div>&nbsp;
                    {!noLabels && <div className="label trim-overflow">{capitalize(t(detail.label))}</div>}
                </>
            ) : (
                <>
                    {!noLabels && (
                        <>
                            <div className="trim-overflow">{capitalize(t(detail.label)) + ': '}</div>&nbsp;
                        </>
                    )}
                    <div style={{ whiteSpace: 'nowrap' }}>{detail.value}</div>
                </>
            )}
        </div>
    );
};
export default EstateDetail;
