import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenuToggle } from '@ionic/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getMenuItems } from '../../utils/appUtil';
import IMenuItem from '../../interfaces/IMenuItem';

import './Menu.scss';
import { isMobile, isiPhone } from '../../utils/util';

const Menu: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const menuItems: IMenuItem[] = getMenuItems(t);

    return (
        <IonContent className={'app-menu' + (isMobile() ? ' mobile' : '') + (isiPhone() ? ' iphone' : '')}>
            <IonList id="inbox-list">
                {menuItems.map((menuItem, index) => {
                    return (
                        <IonMenuToggle key={index} autoHide={false}>
                            <IonItem
                                className={location.pathname === menuItem.url ? 'selected' : ''}
                                routerLink={menuItem.url}
                                routerDirection="forward"
                                lines="none"
                                detail={false}
                                onClick={() => menuItem.onClick && menuItem.onClick()}
                                disabled={location.pathname === menuItem.url || menuItem.disabled}
                            >
                                <IonIcon slot="start" ios={menuItem.iosIcon} md={menuItem.mdIcon} />
                                <IonLabel>{menuItem.title}</IonLabel>
                            </IonItem>
                        </IonMenuToggle>
                    );
                })}
            </IonList>
        </IonContent>
    );
};

export default Menu;
