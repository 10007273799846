import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon, IonModal } from '@ionic/react';
import { useHistory, useLocation } from 'react-router-dom';
import { cameraOutline, cloudUploadOutline, scanOutline } from 'ionicons/icons';
import './AndroidFileUploader.scss';
import { validateFiles } from '../../utils/fileUtil';
import { isIOS, isMobile } from '../../utils/util';
import DocumentScanner from './DocumentScanner';
import useSafeEffect from '../../hooks/useSafeEffect';

enum CaptureType {
    Unset = 0,
    User = 1,
    Environment = 2,
    Scan = 3
}

/**
 * Component used to handle basic file uploads
 * @category Base Components
 * @param {*} onFileSelect method called when the user selects a file
 * @param {*} children object used to display the content of the component
 * @param {boolean} multiple specifies whether the component accepts multiple file uploads
 * @param validExtensions array with the accepted extensions
 * @param minFileSize number or a string of format '45 Kb|Mb|Gb' specifying the min allowed size in bytes for upload
 * @param maxFileSize number or a string of format '45 Kb|Mb|Gb' specifying the max allowed size in bytes for upload
 * @param {boolean} preventEmptyFiles if specified, will check for the file to not be empty
 * @param accept The accept attribute takes as its value a comma-separated list of one or more file types, or unique file type specifiers, describing which file types to allow.
 * @param {string} id the id of the input element
 * @param {boolean} canScan include scan functionality
 * @return component used to handle basic files uploads
 */
const FileUpload = ({
    onFileSelect,
    children,
    multiple,
    validExtensions,
    minFileSize,
    maxFileSize,
    preventEmptyFiles,
    accept,
    isDrop = false,
    id,
    canScan
}: any) => {
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [showScanner, setShowScanner] = useState(false);
    const { t } = useTranslation();
    const inputRef: any = useRef(null);
    const history = useHistory();
    const location = useLocation();

    useSafeEffect(() => {
        if (showScanner && !location.search.includes('documentScannerOpen=true')) {
            setShowScanner(false);
        }
    }, [location]);

    const handleFileChange = (ev: any) => {
        let targetFileList = ev.target.files;
        addFiles(targetFileList);
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const handleDropFiles = (ev: any) => {
        let targetFileList = ev.dataTransfer.files;
        addFiles(targetFileList);

        ev.preventDefault();
    };

    const triggerFileChange = (ev: any) => {
        if (!isMobile()) {
            inputRef.current.click();
        } else {
            setOptionsVisible(true);
        }
        ev.stopPropagation();
    };

    /**
     * Validate files and add them in the state file
     * then triggers the fileChanged callback
     * @param files list of files to add to state
     *
     */
    const addFiles = (files: File[]) => {
        let validation = { hasErrors: false, errors: null, validFileList: [] };
        if (files && files.length) {
            validation = validateFiles(files, validExtensions, minFileSize, maxFileSize, preventEmptyFiles);
        }

        onFileSelect({ files: validation.validFileList, hasErrors: validation.hasErrors, errors: validation.errors });
    };

    const preventDefault = (ev: any) => {
        ev.preventDefault();
    };

    const onSelectOption = (captureType: CaptureType) => {
        setOptionsVisible(false);
        switch (captureType) {
            case CaptureType.User:
                inputRef.current.multiple = false;
                inputRef.current.accept = 'image/*';
                inputRef.current.capture = 'user';
                break;

            case CaptureType.Environment:
                inputRef.current.multiple = multiple;
                inputRef.current.accept = accept;
                delete inputRef.current.capture;
                break;

            case CaptureType.Scan:
                setShowScanner(true);
                history.push(history.location.pathname + '?documentScannerOpen=true');
                return;
        }
        inputRef.current.click();
    };

    const onCloseOptions = () => {
        setOptionsVisible(false);
    };

    const onScanComplete = (document: File) => {
        addFiles([document]);
        onCloseOptions();
        onCloseScanner();
    };

    const onCloseScanner = () => history.goBack();

    const inputProps = isIOS() || !isMobile() ? { multiple, accept } : {};

    return (
        <>
            {!isDrop && (
                <>
                    <div onClick={triggerFileChange}>
                        <input id={id} type="file" ref={inputRef} hidden {...inputProps} onChange={handleFileChange} />
                        {children}
                    </div>
                    <IonModal
                        className="android-file-uploader"
                        isOpen={optionsVisible}
                        initialBreakpoint={canScan ? 0.3 : 0.2}
                        breakpoints={canScan ? [0, 0.2, 0.3] : [0, 0.2]}
                        onDidDismiss={onCloseOptions}
                    >
                        <div className="file-upload-list">
                            <div className="file-upload-option" onClick={() => onSelectOption(CaptureType.User)}>
                                <div className="file-upload-label">{t('MRS_TakePicture')}</div>
                                <IonIcon icon={cameraOutline} />
                            </div>
                            {canScan && (
                                <div className="file-upload-option" onClick={() => onSelectOption(CaptureType.Scan)}>
                                    <div className="file-upload-label">{t('MRS_ScanDocument')}</div>
                                    <IonIcon icon={scanOutline} />
                                </div>
                            )}
                            <div className="file-upload-option" onClick={() => onSelectOption(CaptureType.Environment)}>
                                <div className="file-upload-label">{t('MRS_UploadFiles')}</div>
                                <IonIcon icon={cloudUploadOutline} />
                            </div>
                        </div>
                    </IonModal>
                </>
            )}
            {isDrop && (
                <div onDrop={handleDropFiles} onDragOver={preventDefault} onDragEnter={preventDefault}>
                    {children}
                </div>
            )}
            <DocumentScanner
                isOpen={showScanner}
                minFileSize={minFileSize}
                maxFileSize={maxFileSize}
                validExtensions={validExtensions}
                onConfirm={onScanComplete}
                onCancel={onCloseScanner}
            />
        </>
    );
};
export default FileUpload;
