import { useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
    IonCard,
    IonRange,
    IonLabel,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonIcon,
    IonButton,
    IonItem,
    IonInput,
    IonInfiniteScrollContent,
    IonInfiniteScroll
} from '@ionic/react';
import { IonInfiniteScrollCustomEvent } from '@ionic/core';
import { chevronDownOutline, chevronUpOutline, homeOutline, warning } from 'ionicons/icons';
import './MarketInfo.scss';
import { capitalize, formatDistance, isMobile } from '../../../utils/util';
import { getMapMarkers } from '../../../utils/estateUtil';
import EstateListItem from '../EstateListItem';
import EstatePreviewModal from '../EstatePreviewModal';
import GMap from '../../core/GMap';
import estateService from '../../../services/estate';
import IEstate from '../../../interfaces/IEstate';
import EstateMainContext from '../../../contexts/EstateMainContext';
import NoResults from '../../base/NoResults';
import useSafeEffect from '../../../hooks/useSafeEffect';
import TooltipInfo from '../../base/TooltipInfo';
import Separator from '../../base/Separator';
import useDebounce from '../../../hooks/useDebounce';
import useIonRefresher from '../../../hooks/useIonRefresher';

interface IMarketInfoQV {
    priceMin: number;
    priceMax: number;
    roomsMin: number;
    // roomsMax: number;
    areaMin: number;
    areaMax: number;
    distanceRange: number;
}

const defaultQueryValues: IMarketInfoQV = {
    priceMin: 0,
    priceMax: 0,
    areaMin: 0,
    areaMax: 0,
    roomsMin: 0,
    // roomsMax: 0,
    distanceRange: 1
};

interface IMarketInfo {
    reloadEstateData: () => void;
}

const MarketInfo: React.FC<IMarketInfo> = ({ reloadEstateData }) => {
    const estateListRef = useRef<any>();
    const [appliedQueryValues, setAppliedQueryValues] = useState<IMarketInfoQV>(defaultQueryValues);
    const [selectedQueryValues, setSelectedQueryValues] = useState<IMarketInfoQV>(defaultQueryValues);
    const [filterValuesChanged, setFilterValuesChanged] = useState<string[]>([]);
    const [matchCount, setMatchCount] = useState<number>(0);
    const [expandedFilters, setExpandedFilters] = useState<Boolean>(true);
    const [estatePreview, setEstatePreview] = useState<IEstate | null>(null);
    const [nearbyEstates, setNearbyEstates] = useState<IEstate[]>([]);
    const [mapNearbyEstates, setMapNearbyEstates] = useState<IEstate[]>([]);
    const [nearbyEstatesTotalCount, setNearbyEstatesTotalCount] = useState<number>(0);
    const [selectedProperty, setSelectedProperty] = useState<IEstate | null>(null);
    const [filterTriggered, setFilterTriggered] = useState<boolean>(true);
    const [primaryMarkerSelected, setPrimaryMarkerSelected] = useState<boolean>(false);
    const [refreshMap, setRefreshMap] = useState<boolean>(false);
    const [highlightEstate, setHighlightEstate] = useState<IEstate | null>();
    const [endOfList, setEndOfList] = useState<boolean>();
    const [showList, setShowlist] = useState<boolean>(false);
    const [needListReload, setNeedListReload] = useState<boolean>(false);

    const resultsRef = useRef<HTMLInputElement>(null);
    const estateData = useContext(EstateMainContext);
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    useSafeEffect(() => {
        estateData?.id && load();
        //eslint-disable-next-line
    }, [estateData?.id]);

    useIonRefresher(
        async (seRefreshDone: Function) => {
            await reloadEstateData();
            await load();
            seRefreshDone();
        },
        'estate',
        'overview'
    );

    useSafeEffect(() => {
        if (estatePreview && !location.search.includes('modalOpened=true')) {
            setEstatePreview(null);
        }
    }, [location]);

    useSafeEffect(() => {
        let valuesChanged: string[] = [];

        Object.keys(selectedQueryValues).forEach(function (key) {
            let currentKey = key as keyof IMarketInfoQV;
            if (selectedQueryValues[currentKey] !== appliedQueryValues[currentKey]) {
                valuesChanged.push(key);
            }
        });

        setFilterValuesChanged(valuesChanged);
    }, [selectedQueryValues, appliedQueryValues]);

    const setDefaultQueryValues = () => {
        let qv: IMarketInfoQV = { ...defaultQueryValues };
        if (estateData.price) {
            qv.priceMin = Math.ceil(estateData.price * 0.9);
            qv.priceMax = Math.ceil(estateData.price * 1.1);
        }
        if (estateData.rooms) {
            qv.roomsMin = estateData.rooms - 1 > 0 ? estateData.rooms - 1 : 0;
            // qv.roomsMax = estateData.rooms + 1;
        }
        if (estateData.area) {
            qv.areaMin = Math.ceil(estateData.area * 0.9);
            qv.areaMax = Math.ceil(estateData.area * 1.1);
        }
        setAppliedQueryValues(qv);
        setSelectedQueryValues(qv);
        return qv;
    };

    const resetEstateListScroll = () => {
        if (!estateListRef.current) {
            return;
        }
        try {
            estateListRef.current.scrollTop = 0;
        } catch (e) {}
    };

    const load = async () => {
        let defaultQueryValues = setDefaultQueryValues();
        await filterMapSearch(defaultQueryValues);
    };

    const getMatchCount = async (data: any) => {
        let matchCountResult = await estateService.getMatchCount(data);
        if (matchCountResult?.isValidRequest) {
            setMatchCount(matchCountResult.matchCount);
        } else {
            setMatchCount(0);
        }
    };

    const loadEstatesList = async (data: any, oldData: IEstate[] = []) => {
        let estatesResult = await estateService.getNearbyEstates(data);
        if (estatesResult && estatesResult.isValidRequest && estatesResult.estates) {
            setNearbyEstates([...oldData, ...estatesResult.estates]);
            setNearbyEstatesTotalCount(estatesResult.totalCount);
            setEndOfList(
                oldData.length + estatesResult.estates.length === estatesResult.totalCount || estatesResult.estates.length < 10
            );
        } else {
            setEndOfList(true);
            setNearbyEstates([...oldData]);
            setNearbyEstatesTotalCount(oldData.length);
        }
    };

    const loadEstatesMap = async (data: any) => {
        delete data.page;
        let mapEstatesResponse = await estateService.getNearbyEstatesMap(data);
        if (mapEstatesResponse && mapEstatesResponse.isValidRequest && mapEstatesResponse.estates) {
            setMapNearbyEstates(mapEstatesResponse.estates);
            setRefreshMap(!refreshMap);
            setNearbyEstatesTotalCount(mapEstatesResponse.totalCount);
        } else {
            setMapNearbyEstates([]);
            setNearbyEstatesTotalCount(mapNearbyEstates.length);
        }
    };

    const searchMapEstates = async (data: any) => {
        await loadEstatesMap(data);
        setHighlightEstate(null);
        setSelectedProperty(null);
    };

    const getNearbyEstatesPayload = (pageOffset: number, _defaultQueryValues?: IMarketInfoQV) => {
        let _queryValues = _defaultQueryValues || appliedQueryValues;
        let data: any = {
            filter: {
                priceRange: {},
                roomsRange: {},
                areaRange: {},
                radius: _queryValues.distanceRange,
                purposeIds: [],
                statusIds: [],
                categoryIds: []
            },
            estateId: estateData?.id,
            page: { limit: 10, offset: pageOffset }
        };

        if (_queryValues.priceMin) {
            data.filter.priceRange.min = _queryValues.priceMin;
        }
        if (_queryValues.priceMax) {
            data.filter.priceRange.max = _queryValues.priceMax;
        }
        if (_queryValues.roomsMin) {
            data.filter.roomsRange.min = _queryValues.roomsMin;
        }
        // if (_queryValues.roomsMax) {
        //     data.filter.roomsRange.max = _queryValues.roomsMax;
        // }
        if (_queryValues.areaMin) {
            data.filter.areaRange.min = _queryValues.areaMin;
        }
        if (_queryValues.areaMax) {
            data.filter.areaRange.max = _queryValues.areaMax;
        }
        return data;
    };

    const filterMapSearch = async (_defaultQueryValues?: IMarketInfoQV) => {
        resetEstateListScroll();
        let data = getNearbyEstatesPayload(0, _defaultQueryValues);

        setNeedListReload(true);
        setShowlist(false);
        await getMatchCount(data);
        await searchMapEstates(data);
    };

    const updateQueryValue = (field: string, value: any) => {
        let _value = null;
        if (!value) {
            _value = 0;
        } else {
            let onlyNumbers = value.replace(/\D/g, '');
            if (parseInt(onlyNumbers) >= 0) {
                _value = parseInt(onlyNumbers);
            } else {
                _value = 0;
            }
        }
        setSelectedQueryValues({ ...selectedQueryValues, [field]: _value });
    };

    const onInputBlur = (field: string) => {
        let data = {
            minField: '',
            maxField: '',
            minValue: 0,
            maxValue: 0
        };

        switch (field) {
            case 'priceMin':
            case 'priceMax':
                data.minField = 'priceMin';
                data.maxField = 'priceMax';
                data.minValue = selectedQueryValues.priceMin;
                data.maxValue = selectedQueryValues.priceMax;
                break;

            case 'areaMin':
            case 'areaMax':
                data.minField = 'areaMin';
                data.maxField = 'areaMax';
                data.minValue = selectedQueryValues.areaMin;
                data.maxValue = selectedQueryValues.areaMax;
                break;
        }
        if (field === data.minField && data.minValue && data.maxValue && data.minValue > data.maxValue) {
            setSelectedQueryValues({ ...selectedQueryValues, [data.maxField]: data.minValue + 1 });
            return;
        }
        if (field === data.maxField && data.minValue && data.maxValue && data.minValue > data.maxValue) {
            setSelectedQueryValues({ ...selectedQueryValues, [data.minField]: data.maxValue - 1 > 0 ? data.maxValue - 1 : 0 });
            return;
        }
    };

    const onApplyFilters = async () => {
        setAppliedQueryValues(selectedQueryValues);
        await filterMapSearch(selectedQueryValues);
        if (isMobile()) {
            setExpandedFilters(false);
            //scroll to map
            resultsRef.current?.scrollIntoView(false);
        }
        setFilterTriggered(!filterTriggered);
    };

    const openEstatePreviewModal = async (estate: IEstate, fromMap: boolean = false) => {
        if (fromMap) {
            if (!estate) {
                setPrimaryMarkerSelected(false);
                return;
            }
        } else {
            if (estate.coordinates) {
                if (isMobile()) {
                    setSelectedProperty(estate);
                }
            }
        }
        try {
            let response = await estateService.getNearbyEstateInfo({
                estateId: estate.id,
                clientId: estate.clientId,
                originalEstateClientId: estateData.clientId
            });
            if (response?.isValidRequest) {
                estate = { ...estate, ...response.estate };
            }
            history.push(history.location.pathname + '?modalOpened=true');
            setEstatePreview(estate);
        } catch (e) {}
    };

    const highlightEstateOnMap = async ({ estate }: { estate: IEstate }) => {
        if (isMobile()) {
            return;
        }
        if (estate?.coordinates) {
            setSelectedProperty(estate);
        }
    };

    useDebounce(highlightEstateOnMap, { estate: highlightEstate }, 200);

    const closeEstatePreviewModal = () => {
        setHighlightEstate(null);
        setSelectedProperty(null);
        history.goBack();
    };

    const formatInputDisplayValue = (number: number) => {
        if (!number) {
            return null;
        }
        return number.toLocaleString().replace(/,/g, '.');
    };

    const onMapLoaded = () => {
        setTimeout(() => {
            setPrimaryMarkerSelected(true);
        }, 100);
    };

    const onMapUpdated = () => {
        setRefreshMap(false);
    };

    const preventScroll = (ele: Element) => {
        const content = ele.closest('ion-content');
        if (content) {
            content.style.setProperty('--overflow', 'hidden');
        }
    };

    const allowScroll = (ele: Element) => {
        const content = ele.closest('ion-content');
        if (content) {
            content.style.setProperty('--overflow', 'inherit');
        }
    };

    const loadNewPage = async (ev: IonInfiniteScrollCustomEvent<void>) => {
        localStorage.setItem('showNotification', '');
        let data = getNearbyEstatesPayload(nearbyEstates.length);
        await loadEstatesList(data, nearbyEstates);
        ev.target.complete();
        localStorage.setItem('showNotification', 'true');
    };

    const toggleShowList = async () => {
        const newValue = !showList;
        if (newValue && needListReload) {
            setNeedListReload(false);
            await loadEstatesList(getNearbyEstatesPayload(0));
        }
        setShowlist(newValue);
    };

    const propertyFilters = (
        <div className={'filter-items-wrapper' + (isMobile() ? ' mobile' : '')}>
            <div className="filter-item">
                <div className="input-range">
                    <IonItem lines="none">
                        <IonLabel position="stacked">{capitalize(t('Price')) + ' ' + t('From')}</IonLabel>
                        <div
                            className={'input-with-unit-wrapper' + (filterValuesChanged?.includes('priceMin') ? ' warning' : '')}
                        >
                            <IonInput
                                placeholder="Min"
                                inputMode="numeric"
                                value={formatInputDisplayValue(selectedQueryValues.priceMin)}
                                onIonChange={({ detail }) => updateQueryValue('priceMin', detail.value)}
                                onIonBlur={() => onInputBlur('priceMin')}
                            ></IonInput>
                            <div className="measurement-unit">{estateData.currency || '€'}</div>
                        </div>
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel position="stacked">{t('To')}</IonLabel>
                        <div
                            className={'input-with-unit-wrapper' + (filterValuesChanged?.includes('priceMax') ? ' warning' : '')}
                        >
                            <IonInput
                                placeholder="Max"
                                inputMode="numeric"
                                value={formatInputDisplayValue(selectedQueryValues.priceMax)}
                                onIonChange={({ detail }) => updateQueryValue('priceMax', detail.value)}
                                onIonBlur={() => onInputBlur('priceMax')}
                            ></IonInput>
                            <div className="measurement-unit">{estateData.currency || '€'}</div>
                        </div>
                    </IonItem>
                </div>
            </div>
            <Separator />
            <div className="filter-item">
                <div className="input-range">
                    <IonItem lines="none">
                        <IonLabel position="stacked">{t('Surface') + ' ' + t('From')}</IonLabel>
                        <div className={'input-with-unit-wrapper' + (filterValuesChanged?.includes('areaMin') ? ' warning' : '')}>
                            <IonInput
                                placeholder="Min"
                                inputMode="numeric"
                                value={formatInputDisplayValue(selectedQueryValues.areaMin)}
                                onIonChange={({ detail }) => updateQueryValue('areaMin', detail.value)}
                                onIonBlur={() => onInputBlur('areaMin')}
                            ></IonInput>
                            <div className="measurement-unit small-font">m²</div>
                        </div>
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel position="stacked">{t('To')}</IonLabel>
                        <div className={'input-with-unit-wrapper' + (filterValuesChanged?.includes('areaMax') ? ' warning' : '')}>
                            <IonInput
                                placeholder="Max"
                                inputMode="numeric"
                                value={formatInputDisplayValue(selectedQueryValues.areaMax)}
                                onIonChange={({ detail }) => updateQueryValue('areaMax', detail.value)}
                                onIonBlur={() => onInputBlur('areaMax')}
                            ></IonInput>
                            <div className="measurement-unit small-font">m²</div>
                        </div>
                    </IonItem>
                </div>
            </div>
            <Separator />
            <div className="filter-item">
                <div className="input-range">
                    <IonItem lines="none">
                        <IonLabel position="stacked">{t('MRS_RoomsMinNr')}</IonLabel>
                        <IonInput
                            placeholder={t('Rooms')}
                            inputMode="numeric"
                            value={selectedQueryValues.roomsMin || null}
                            onIonChange={({ detail }) => updateQueryValue('roomsMin', detail.value)}
                            className={filterValuesChanged?.includes('roomsMin') ? 'warning' : ''}
                        ></IonInput>
                    </IonItem>
                </div>
            </div>
            <Separator />
            <div className="filter-item">
                <div className="input-range">
                    <IonItem lines="none">
                        <IonLabel position="stacked">{t('MRS_DistanceRadius')}</IonLabel>
                        <IonRange
                            min={1}
                            max={10}
                            step={0.5}
                            value={selectedQueryValues?.distanceRange}
                            onIonChange={({ detail }) => {
                                setSelectedQueryValues({
                                    ...selectedQueryValues,
                                    distanceRange: detail.value as number
                                });
                            }}
                            onIonKnobMoveStart={(e) => preventScroll(e.target)}
                            onIonKnobMoveEnd={(e) => allowScroll(e.target)}
                            pin={true}
                            pinFormatter={formatDistance}
                            color={filterValuesChanged?.includes('distanceRange') ? 'warning' : ''}
                        ></IonRange>
                    </IonItem>
                </div>
            </div>
            <Separator />
            <div className="filter-button-wrapper">
                <IonButton
                    expand={isMobile() ? 'block' : undefined}
                    onClick={onApplyFilters}
                    style={{ width: '146px' }}
                    className="app-tooltip"
                    data-tooltip-html={filterValuesChanged?.length > 0 ? 'You have filters that are not applied!' : ''}
                    data-tooltip-variant="info"
                >
                    {filterValuesChanged?.length > 0 && <IonIcon icon={warning} color="warning" className="warning-icon" />}
                    {t('MRS_ApplyFilters')}
                </IonButton>
            </div>
        </div>
    );

    const hasResults = nearbyEstates.length || mapNearbyEstates.length ? true : false;
    const hasListResults = nearbyEstates.length ? true : false;
    const hasMapResults = mapNearbyEstates.length ? true : false;

    const mapMarkers =
        mapNearbyEstates.length > 0
            ? getMapMarkers(mapNearbyEstates, selectedProperty?.id, openEstatePreviewModal, {
                  title: t('MRS_YourPropertyPin'),
                  lat: estateData?.coordinates.lat,
                  lng: estateData?.coordinates.lon,
                  selected: primaryMarkerSelected
              })
            : [];

    return (
        <>
            <EstatePreviewModal
                estate={estatePreview}
                onClose={closeEstatePreviewModal}
                showDescription={estateData.clientId === estatePreview?.clientId}
            />
            {isMobile() && (
                <IonCard
                    className={
                        'page-section filter-card' + (isMobile() ? ' mobile' : '') + (expandedFilters ? ' expanded-filters' : '')
                    }
                >
                    <IonCardHeader onClick={() => setExpandedFilters(!expandedFilters)}>
                        <IonCardTitle>{t('Filters')}</IonCardTitle>
                        <IonIcon icon={expandedFilters ? chevronUpOutline : chevronDownOutline}></IonIcon>
                    </IonCardHeader>
                    <IonCardContent>{propertyFilters}</IonCardContent>
                </IonCard>
            )}
            <div ref={resultsRef}></div>
            <div style={{ display: (isMobile() && !expandedFilters) || !isMobile() ? 'unset' : 'none' }}>
                {isMobile() && (
                    <>
                        <IonCard className={'page-section mobile'}>
                            <IonCardContent className={'search-counts mobile'}>
                                <div className="count-info">
                                    <div className="value">{matchCount || 0}</div>
                                    <IonLabel>{t('MRS_PotentialBuyers')}</IonLabel>
                                    <TooltipInfo text={t('MRS_PotentialBuyersInfo')} />
                                </div>
                            </IonCardContent>
                        </IonCard>
                        {hasResults && (
                            <IonCard className={'page-section mobile'}>
                                <IonCardContent className={'search-counts mobile'}>
                                    <div className="count-info">
                                        <div className="value">{nearbyEstatesTotalCount}</div>
                                        <IonLabel>{capitalize(t('MRS_Estates'))}</IonLabel>
                                        <TooltipInfo
                                            text={t('MRS_PropertiesInfo') + `<br/><br/>` + t('MRS_NoCoordsProperties')}
                                        />
                                    </div>
                                </IonCardContent>
                            </IonCard>
                        )}
                    </>
                )}
                <IonCard className={'page-section' + (isMobile() ? ' mobile' : '')}>
                    {!isMobile() && <div className="filter-card">{propertyFilters}</div>}
                    {!isMobile() && (
                        <div className="counts-wrapper">
                            {hasResults && (
                                <IonCardContent className="search-counts">
                                    <div className="count-info">
                                        <div className="value">{nearbyEstatesTotalCount}</div>
                                        <IonLabel>{capitalize(t('MRS_Estates'))}</IonLabel>
                                        <TooltipInfo
                                            text={t('MRS_PropertiesInfo') + `<br/><br/>` + t('MRS_NoCoordsProperties')}
                                        />
                                    </div>
                                </IonCardContent>
                            )}
                            <IonCardContent className="search-counts">
                                <div className="count-info">
                                    <div className="value">{matchCount || 0}</div>
                                    <IonLabel>{t('MRS_PotentialBuyers')}</IonLabel>
                                    <TooltipInfo text={t('MRS_PotentialBuyersInfo')} />
                                </div>
                            </IonCardContent>
                        </div>
                    )}
                    <div
                        className={'search-results' + (!hasResults ? ' no-results' : '')}
                        onMouseLeave={() => {
                            setHighlightEstate(null);
                            setSelectedProperty(null);
                        }}
                    >
                        {showList && (
                            <div
                                className={'estates-list' + (isMobile() ? ' mobile' : ' ion-content-scroll-host')}
                                ref={estateListRef}
                            >
                                {hasListResults ? (
                                    <>
                                        {nearbyEstates.map((x, index) => (
                                            <EstateListItem
                                                key={index}
                                                t={t}
                                                estate={x}
                                                onMouseOver={() => setHighlightEstate(x)}
                                                onClick={() => openEstatePreviewModal(x)}
                                            />
                                        ))}
                                        {!endOfList && (
                                            <IonInfiniteScroll
                                                onIonInfinite={(ev) => {
                                                    loadNewPage(ev);
                                                }}
                                            >
                                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                            </IonInfiniteScroll>
                                        )}
                                    </>
                                ) : (
                                    <NoResults
                                        icon={<IonIcon icon={homeOutline}></IonIcon>}
                                        info={t(estateData?.coordinates ? 'NoEstatesFound' : 'MRS_MissingEstateCoords')}
                                    />
                                )}
                            </div>
                        )}
                        {isMobile() && !showList && (
                            <IonButton className="toggle-list-btn-mobile" onClick={toggleShowList}>
                                {t('MRS_CompleteListing')}
                            </IonButton>
                        )}
                        {estateData?.coordinates && hasMapResults && (
                            <div className="market-info-gmap-wrapper">
                                {!isMobile() && (
                                    <IonButton className="toggle-list-btn" onClick={toggleShowList}>
                                        {t(!showList ? 'MRS_CompleteListing' : 'MRS_HideListing')}
                                    </IonButton>
                                )}
                                <GMap
                                    initialLocation={{ lat: estateData.coordinates?.lat, lng: estateData.coordinates?.lon }}
                                    range={selectedQueryValues.distanceRange}
                                    markers={mapMarkers}
                                    onMapLoaded={onMapLoaded}
                                    onMapUpdated={onMapUpdated}
                                    refresh={refreshMap}
                                />
                            </div>
                        )}
                    </div>
                </IonCard>
            </div>
        </>
    );
};
export default MarketInfo;
