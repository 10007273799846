/**
 * Component to display an image with fallback.
 * Use this instead of EstateImage, if you need to handle onError function (if image fails to load)
 *
 * If image does not exists, display as fallback the category icon
 */
import { useEffect, useState, useRef } from 'react';
import './ImageFallback.scss'
import { isMobile } from '../../utils/util';

/**
 * EstateImage functional component, returns an image
 * @param {string} src: image source
 * @param {string} alt: image alt text, optional
 */
const ImageFallback: React.FC<{ src: string; alt: string; className?: any; fallback?: string; fallbackComponent?: any; showBackground?: boolean; }> = ({
    src,
    alt,
    className,
    fallback,
    fallbackComponent,
    showBackground
}) => {
    const [imageSrc, setImageSrc] = useState(src);
    const [imageLoadError, setImageLoadError] = useState(false);
    const [showFallbackComponent, setShowFallbackComponent] = useState(false);
    const initialImage = useRef(src);

    useEffect(() => {
        const fallbackImage: string = fallback || './assets/img/no-photo-available.png';
        if (!src || (imageLoadError && imageSrc !== fallbackImage)) {
            if (fallbackComponent) {
                if (!showFallbackComponent) {
                    setShowFallbackComponent(true);
                }
            } else {
                setImageSrc(fallbackImage);
            }
            setImageLoadError(false);
        } else if (src && initialImage.current !== src) {
            // handle images which initiallly are null and then they change after some data is loaded (i.e.: EstateMain)
            setImageSrc(src);
            setShowFallbackComponent(false);
            initialImage.current = src;
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageLoadError, src]);

    return (
        <>
            {!showFallbackComponent && (
                <>
                    {showBackground && <img
                        className={isMobile() ? "image-background mobile" : "image-background"}
                        src={imageSrc}
                        alt={alt + '-background'}
                        onError={() => {
                            setImageLoadError(true);
                        }}
                    />}
                    <img
                        className={className + ' image'}
                        src={imageSrc}
                        alt={alt}
                        onError={() => {
                            setImageLoadError(true);
                        }}
                    />
                </>
            )}
            {showFallbackComponent && fallbackComponent}
        </>
    );
};
export default ImageFallback;
