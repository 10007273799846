import IMenuItem from '../interfaces/IMenuItem';
import { logOutOutline, personOutline, newspaperOutline } from 'ionicons/icons';
import { setLocalStorage } from './localStorageUtil';
import UserProfile from '../lib/userProfile';
import { capitalize } from './util';

/**
 * wait for menu to be closed
 */
const waitMenuClose = () => {
    let element = document.getElementById('main');
    // if element is not found (can happen if someone changes the id of IonRouterOutlet), then logout after 600 ms
    if (!element) {
        setTimeout(() => {
            logout();
        }, 600);
    } else if (element.classList.contains('menu-content-open')) {
        setTimeout(() => {
            waitMenuClose();
        }, 200);
    } else {
        logout();
    }
};

export const logout = () => {
    window.localStorage.clear();
    setLocalStorage('Version', window.env.REACT_APP_VERSION);
    window.location.assign('/login');
};

export const getMenuItems = (t: any): IMenuItem[] => {
    const items: IMenuItem[] = [
        {
            title: capitalize(t('Profile')),
            url: '/profile',
            iosIcon: personOutline,
            mdIcon: personOutline,
            disabled: UserProfile.AgentAccessInfo ? true : false
        },
        {
            title: (
                <div className="menu-title-extra">
                    <p>{t('Version')}</p> <span>{window.env.REACT_APP_VERSION}</span>
                </div>
            ),
            url: '#',
            iosIcon: newspaperOutline,
            mdIcon: newspaperOutline
        },
        {
            title: t('Logout'),
            url: '#',
            iosIcon: logOutOutline,
            mdIcon: logOutOutline,
            onClick: async () => {
                // wait for menu to be closed, or else login page will be disabled due to the class .menu-content-open wich has pointerEvents: none
                // menuRef?.current?.close();
                waitMenuClose();
            }
        }
    ];
    return items;
};
