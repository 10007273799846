import { IonButton, IonSpinner } from '@ionic/react';
import { MouseEventHandler } from 'react';

const ButtonLoad: React.FC<{
    dataCy?: string;
    className?: string | undefined;
    loading: boolean;
    disabled?: boolean;
    onClick: MouseEventHandler<HTMLIonButtonElement> | undefined;
    children: any;
}> = ({ dataCy, className, loading, disabled, onClick, children }) => {
    return (
        <div className="loading-btn-wrapper">
            <IonButton
                data-cy={dataCy}
                className={className}
                expand="block"
                strong={true}
                onClick={onClick}
                disabled={loading || disabled}
            >
                {loading && <IonSpinner className="wait-icon" />}
                {children}
            </IonButton>
        </div>
    );
};

export default ButtonLoad;
