import { useContext, useState } from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
    RefresherEventDetail
} from '@ionic/react';
import { caretDown } from 'ionicons/icons';
import './GeneralLayout.scss';
import PoweredBy from '../base/PoweredBy';
import EstateMobileNav from '../estate/EstateMobileNav';
import EstateDesktopNav from '../estate/EstateDesktopNav';
import { ConnektoLogo } from '../../assets/svg-icons';
import { isMobile } from '../../utils/util';
import Menu from '../../components/core/Menu';
import ImageFallback from '../core/ImageFallback';
import AppContext from '../../contexts/AppContext';
import { AvatarIcon } from '../../assets/svg-icons';

interface IGeneralLayoutData {
    children: any;
    footer?: any;
}

const GeneralLayout: React.FC<IGeneralLayoutData> = ({ children }) => {
    const [scrollTop, setScrollTop] = useState(0);
    const appState = useContext(AppContext);
    // fix popver not working when changing page via history.push
    const popoverTrigger = 'click-trigger-' + new Date().getTime();
    const IsEstateView = window.location.pathname.substring(1, 7) === 'estate';

    const updateScrollTop = (e: CustomEvent) => {
        if (!IsEstateView) {
            return;
        }
        setScrollTop(e.detail.scrollTop);
    };

    const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        appState?.setState({ ...appState.state, refreshEvent: event });
    };

    return (
        <IonPage className="general-layout">
            <IonHeader>
                <IonToolbar style={!isMobile() ? { padding: '0px 10%' } : {}}>
                    <div className="logo-nav-wrapper">
                        <div className={'logo' + (isMobile() ? ' mobile' : '')}>
                            <ConnektoLogo />
                        </div>
                        {IsEstateView && scrollTop > 340 && <EstateDesktopNav />}
                        {/* for spacing, don't remove */}
                        <div></div>
                    </div>

                    {isMobile() ? (
                        <IonButtons slot="end">
                            <IonMenuButton />
                        </IonButtons>
                    ) : (
                        <IonItem slot="end" lines="none">
                            <IonLabel className="user-name">{appState?.state.user?.fullName}</IonLabel>
                            <div className="image-wrapper">
                                <ImageFallback
                                    alt="UserAvatar"
                                    src={appState?.state.user?.pictureUrl || ''}
                                    fallbackComponent={<AvatarIcon />}
                                />
                            </div>

                            <IonButton data-cy="dropdown-menu" id={popoverTrigger} fill="clear">
                                <IonIcon slot="icon-only" icon={caretDown} className="user-menu-button"></IonIcon>
                            </IonButton>
                            {/* Desktop menu */}
                            <IonPopover trigger={popoverTrigger} triggerAction="click" dismissOnSelect>
                                <Menu />
                            </IonPopover>
                        </IonItem>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={!isMobile()} onIonScroll={updateScrollTop}>
                <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {children}
                <PoweredBy />
            </IonContent>
            <IonFooter>
                <EstateMobileNav />
            </IonFooter>
        </IonPage>
    );
};

export default GeneralLayout;
