import { useIonLoading } from '@ionic/react';
import { usePromiseTracker } from 'react-promise-tracker';
import useDebounce from './useDebounce';

const useAppLoader = (t: any) => {
    const [present, dismiss] = useIonLoading();
    const promiseTracker = usePromiseTracker();

    const handlePromiseTracker = () => {
        let showNotification = localStorage.getItem('showNotification');
        if (promiseTracker.promiseInProgress && showNotification) {
            present({
                message: t('Loading'),
                spinner: 'circles'
            });
        } else {
            dismiss();
        }
    };

    return useDebounce(handlePromiseTracker, { promiseInProgress: promiseTracker.promiseInProgress }, 500);
};
export default useAppLoader;
