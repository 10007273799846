import { useEffect, useState, useRef, useContext, RefObject } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    IonButton,
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonItem,
    IonInput,
    useIonAlert,
    IonSelect,
    IonSelectOption,
    IonNavLink
} from '@ionic/react';
import { arrowBackOutline, notifications } from 'ionicons/icons';
import './Profile.scss';
import ImageFallback from '../core/ImageFallback';
import ImageCrop from '../core/ImageCrop';
import ButtonLoad from '../core/ButtonLoad';
import FileUpload from '../core/FileUpload';
import { Item } from '../core/AppTypeahead';
import ModalSelectInput from '../core/ModalSelectInput';
import { AvatarIcon, Edit24 } from '../../assets/svg-icons';
import useIonRefresher from '../../hooks/useIonRefresher';
import AppContext from '../../contexts/AppContext';
import UserProfile from '../../lib/userProfile';
import { setUserProfile } from '../../utils/profileUtil';
import { getBase64DataFromBlob, cropImage, resizeImage } from '../../utils/fileUtil';
import { getUpdatedState } from '../../utils/stateUtil';
import { capitalize, isMobile } from '../../utils/util';
import profileService from '../../services/profile';

interface IProfileData {
    firstName: string;
    lastName: string;
    phone: string;
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
    pictureSrc: string;
    picture: { fileName: string; fileData: Blob | null };
    languageId: string[];
}

const Profile: React.FC = () => {
    const history = useHistory();
    const [profile, setProfile] = useState<IProfileData>();
    const [initialProfile, setInitialProfile] = useState<IProfileData>();
    const [profileDataChanged, setProfileDataChanged] = useState<boolean>(false);
    const [profilePasswordChanged, setProfilePasswordChanged] = useState<boolean>(false);
    const [cropData, setCropData] = useState<{ isOpen: boolean; imageData: string | null; fileData: Blob | null }>({
        isOpen: false,
        imageData: null,
        fileData: null
    });
    const { t } = useTranslation();
    const [showAlert] = useIonAlert();
    const [waitRequest, setWaitRequest] = useState<{ updateProfile: boolean; changePassword: boolean; disableButtons: boolean }>({
        updateProfile: false,
        changePassword: false,
        disableButtons: false
    });

    const filesInput: any = useRef(null);
    const inputFirstName = useRef<HTMLIonInputElement>(null);
    const inputLastName = useRef<HTMLIonInputElement>(null);
    const appState = useContext(AppContext);

    useEffect(() => {
        load();
    }, []);

    useIonRefresher(async (seRefreshDone: Function) => {
        load();
        seRefreshDone();
    }, 'profile');

    useEffect(() => {
        let hasChanges = false;
        if (profile && initialProfile) {
            if (
                (profile.currentPassword !== initialProfile.currentPassword ||
                    profile.newPassword !== initialProfile.newPassword ||
                    profile.repeatPassword !== initialProfile.repeatPassword) &&
                profile.currentPassword !== '' &&
                profile.newPassword !== '' &&
                profile.repeatPassword !== '' &&
                profile.newPassword === profile.repeatPassword
            ) {
                hasChanges = true;
            }
            setProfilePasswordChanged(hasChanges);
        }
        //eslint-disable-next-line
    }, [profile?.currentPassword, profile?.newPassword, profile?.repeatPassword]);

    useEffect(() => {
        let hasChanges = false;
        if (profile && initialProfile) {
            if (
                profile.firstName !== initialProfile.firstName ||
                profile.lastName !== initialProfile.lastName ||
                profile.phone !== initialProfile.phone ||
                profile.languageId[0] !== initialProfile.languageId[0]
            ) {
                hasChanges = true;
            }
            setProfileDataChanged(hasChanges);
        }
        //eslint-disable-next-line
    }, [profile?.firstName, profile?.lastName, profile?.phone, profile?.languageId, initialProfile]);

    const load = () => {
        let initialData = {
            firstName: UserProfile.FirstName,
            lastName: UserProfile.LastName,
            phone: UserProfile.Phone,
            currentPassword: '',
            repeatPassword: '',
            newPassword: '',
            pictureSrc: UserProfile.Picture,
            picture: { fileName: UserProfile.Picture, fileData: null },
            languageId: [UserProfile.LanguageId]
        };
        setProfile(initialData);
        setInitialProfile(initialData);
    };

    const onSaveProfile = async () => {
        let shouldRefresh = false;
        setWaitRequest({ updateProfile: true, changePassword: false, disableButtons: true });
        let data: any = {
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            phone: profile?.phone,
            languageId: profile?.languageId[0]
        };
        if (profile?.languageId[0] !== initialProfile?.languageId[0]) {
            shouldRefresh = true;
        }
        let response = await profileService.updateProfile(data);
        if (response && response.isValidRequest && response.profile) {
            setUserProfile(response.profile);
            appState?.updateStateData({
                firstName: UserProfile.FirstName,
                lastName: UserProfile.LastName,
                fullName: UserProfile.FullName
            });
            setInitialProfile(profile);
            showNotification(
                'Success',
                capitalize(t('OperationCompletedSuccessfully')),
                shouldRefresh ? () => window.location.reload() : null
            );
        } else {
            showNotification('Error', capitalize(t('ErrorOperationFailed')));
        }
        setWaitRequest({ updateProfile: false, changePassword: false, disableButtons: false });
    };

    const onChangePassword = async () => {
        setWaitRequest({ updateProfile: false, changePassword: true, disableButtons: true });
        let data: any = {
            oldPassword: profile?.currentPassword,
            password: profile?.newPassword,
            confirmPassword: profile?.repeatPassword
        };
        let response = await profileService.changePassword(data);
        if (response && response.isValidRequest) {
            showNotification('Success', capitalize(t('OperationCompletedSuccessfully')));
            updateProfile({ currentPassword: '', newPassword: '', repeatPassword: '' });
        } else {
            let message = capitalize(t('ErrorOperationFailed'));
            if (response && response.validationErrors) {
                message = response.validationErrors.map((x: any) => '<div>' + x.message + '</div>').join('');
            }
            showNotification('Error', message);
        }
        setWaitRequest({ updateProfile: false, changePassword: false, disableButtons: false });
    };

    const checkAndUpdateProfile = (oldValue: any, newValueData: any, key: string) => {
        if (oldValue !== newValueData[key]) {
            updateProfile(newValueData);
        }
    };

    const updateProfile = (data: any) => {
        setProfile((prevState) => {
            let nextState = getUpdatedState(prevState, data);
            return nextState;
        });
    };

    const checkPhone = (ev: any) => {
        let expr = '[0-9]';
        var regex = new RegExp(expr);

        // if input char is not a digit and is not ('+' and only on first position and no other '+' character exists)
        if (!regex.test(ev.data) && !(ev.data === '+' && ev.target.selectionStart === 0 && ev.target.value.indexOf('+') === -1)) {
            ev.preventDefault();
        }
    };

    const onSelectFiles = async (filesData: any) => {
        if (filesData && filesData.files && filesData.files.length > 0) {
            let imageBase64 = await getBase64DataFromBlob(filesData.files[0]);
            if (imageBase64) {
                setCropData({ isOpen: true, imageData: imageBase64, fileData: filesData.files[0] });
            }

            if (filesInput && filesInput.current) {
                filesInput.current?.clear();
            }
        }
    };

    const onCropConfirm = async (cropInfo: any) => {
        let processSuccess = false;
        if (cropData.fileData) {
            let imageCropped = await cropImage(cropData.fileData, cropInfo);
            if (imageCropped) {
                let imageResized = await resizeImage(imageCropped, { width: 500, height: 500 });
                if (imageResized) {
                    let imageBase64 = await getBase64DataFromBlob(imageResized);
                    if (imageBase64) {
                        let fileName = 'user_' + UserProfile.UserId + '.png';
                        updateProfile({
                            picture: { fileName, fileData: imageResized },
                            pictureSrc: imageBase64
                        });
                        let response;
                        try {
                            response = await profileService.uploadPicture(fileName, imageResized);
                        } catch (error) {}
                        if (response && response.isValidRequest) {
                            processSuccess = true;
                            showNotification('Success', capitalize(t('OperationCompletedSuccessfully')));
                            const timeStamp = new Date().getTime();
                            UserProfile.Picture = response.fileInfo.path + '?t=' + timeStamp;
                            appState?.updateStateData({ pictureUrl: UserProfile.Picture });
                        }
                    }
                }
            }
            setCropData({ isOpen: false, imageData: null, fileData: null });
        }
        if (!processSuccess) {
            showNotification('Error', capitalize(t('ErrorUploading')));
        }
    };

    const onCropCancel = () => {
        setCropData({ isOpen: false, imageData: null, fileData: null });
    };

    const showNotification = (type: string, message: string, onDidDismiss?: any) => {
        showAlert({ header: type, message, buttons: ['OK'], onDidDismiss });
    };

    const checkAlphanumericInput = (ev: Event, ionInputEl: RefObject<HTMLIonInputElement>) => {
        const value = (ev.target as HTMLIonInputElement).value as string;

        const filteredValue = value.replace(
            /[^a-zA-Z0-9\sàáâäãåèéêëìíîïòóôöõøùúûüÿýñçčšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð'_]+/g,
            ''
        );

        const inputCmp = ionInputEl.current;
        if (inputCmp !== null) {
            inputCmp.value = filteredValue;
        }
        return filteredValue;
    };
    const filterItems: Item[] = [
        { text: t('language_en-GB'), value: 'en-GB' },
        { text: t('language_fr-BE'), value: 'fr-BE' },
        { text: t('language_nl-BE'), value: 'nl-BE' }
    ];

    return (
        <div className="profile-page">
            <div className="navbar">
                <div className="nav-button">
                    <span className="back-nav-button">
                        <IonButton
                            className="back-button"
                            size="small"
                            onClick={() => {
                                history.replace('/dashboard');
                            }}
                        >
                            <IonIcon slot="icon-only" icon={arrowBackOutline}></IonIcon>
                        </IonButton>
                    </span>
                </div>
            </div>

            <ImageCrop
                isOpen={cropData.isOpen}
                imageData={cropData.imageData}
                cropShape="round"
                onConfirm={onCropConfirm}
                onCancel={onCropCancel}
            ></ImageCrop>
            <IonCard className={'user-info' + (isMobile() ? ' isMobile' : '')}>
                <IonCardContent>
                    <div className="avatar-wrapper">
                        <div className="image-wrapper">
                            <ImageFallback alt="UserAvatar" src={profile?.pictureSrc || ''} fallbackComponent={<AvatarIcon />} />
                        </div>
                        <FileUpload
                            id="upload-avatar"
                            onFileSelect={onSelectFiles}
                            maxFileSize="20Mb"
                            preventEmptyFiles={true}
                            accept=".png, .jpg, .jpeg, .gif, .webp, .heic"
                            validExtensions={['png', 'jpg', 'jpeg', 'gif', 'webp', 'heic']}
                        >
                            <div className={'edit-icon'}>
                                <Edit24 />
                            </div>
                        </FileUpload>
                    </div>
                    <div>{UserProfile.FullName}</div>
                    <div><IonButton
                            size="small"
                            onClick={() => {
                                history.replace('/notifications');
                            }}
                        >
                            <IonIcon slot="icon-only" icon={notifications}></IonIcon>
                    </IonButton>   
                    </div> 
                </IonCardContent>
            </IonCard>

            <IonGrid>
                <IonRow>
                    <IonCol size="12" sizeLg="6">
                        <IonCard className={isMobile() ? ' isMobile' : ''}>
                            <IonCardHeader>
                                <IonCardTitle>{capitalize(t('MRS_PersonalDetails'))}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonItem
                                    lines={isMobile() ? 'full' : 'none'}
                                    counter={true}
                                    counterFormatter={(inputLength, maxLength) =>
                                        `${maxLength - inputLength} ${t('MRS_CharactersRemaining')}`
                                    }
                                >
                                    <IonLabel position="stacked">{capitalize(t('FirstName'))}</IonLabel>
                                    <IonInput
                                        ref={inputFirstName}
                                        placeholder={capitalize(t('FirstName'))}
                                        value={profile?.firstName}
                                        onIonInput={(ev: any) =>
                                            checkAndUpdateProfile(
                                                profile?.firstName,
                                                { firstName: checkAlphanumericInput(ev, inputFirstName) },
                                                'firstName'
                                            )
                                        }
                                        maxlength={50}
                                    ></IonInput>
                                </IonItem>
                                <IonItem
                                    lines={isMobile() ? 'full' : 'none'}
                                    counter={true}
                                    counterFormatter={(inputLength, maxLength) =>
                                        `${maxLength - inputLength} ${t('MRS_CharactersRemaining')}`
                                    }
                                >
                                    <IonLabel position="stacked">{capitalize(t('LastName'))}</IonLabel>
                                    <IonInput
                                        ref={inputLastName}
                                        placeholder={capitalize(t('LastName'))}
                                        value={profile?.lastName}
                                        onIonInput={(ev: any) =>
                                            checkAndUpdateProfile(
                                                profile?.lastName,
                                                { lastName: checkAlphanumericInput(ev, inputLastName) },
                                                'lastName'
                                            )
                                        }
                                        maxlength={50}
                                    ></IonInput>
                                </IonItem>
                                <IonItem
                                    lines={isMobile() ? 'full' : 'none'}
                                    counter={true}
                                    counterFormatter={(inputLength, maxLength) =>
                                        `${maxLength - inputLength} ${t('MRS_CharactersRemaining')}`
                                    }
                                >
                                    <IonLabel position="stacked">{capitalize(t('Phone'))}</IonLabel>
                                    <IonInput
                                        type="tel"
                                        placeholder={capitalize(t('Phone'))}
                                        value={profile?.phone}
                                        inputmode="tel"
                                        onBeforeInput={checkPhone}
                                        onIonChange={(ev: any) =>
                                            checkAndUpdateProfile(profile?.phone, { phone: ev.target.value }, 'phone')
                                        }
                                        maxlength={50}
                                    ></IonInput>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonLabel position="stacked">{capitalize(t('Language'))}</IonLabel>
                                    {isMobile() ? (
                                        <ModalSelectInput
                                            filters={profile?.languageId}
                                            setFilters={(value) =>
                                                checkAndUpdateProfile(profile?.languageId, { languageId: value }, 'languageId')
                                            }
                                            items={filterItems}
                                            id="filter-activity-overview"
                                            placeholder={capitalize(t('Language'))}
                                            modalTitle={capitalize(t('Language'))}
                                            isMultiSelect={false}
                                        />
                                    ) : (
                                        <IonSelect
                                            className="filter-component"
                                            placeholder={capitalize(t('Language'))}
                                            onIonChange={(e) => {
                                                checkAndUpdateProfile(
                                                    profile?.languageId,
                                                    { languageId: [e.detail.value] },
                                                    'languageId'
                                                );
                                            }}
                                            interface="popover"
                                            value={profile?.languageId[0]}
                                        >
                                            <IonSelectOption value="en-GB">{t('language_en-GB')}</IonSelectOption>
                                            <IonSelectOption value="fr-BE">{t('language_fr-BE')}</IonSelectOption>
                                            <IonSelectOption value="nl-BE">{t('language_nl-BE')}</IonSelectOption>
                                        </IonSelect>
                                    )}
                                </IonItem>
                                <IonItem lines="none">
                                    <ButtonLoad
                                        className="apply-button-wrapper"
                                        onClick={onSaveProfile}
                                        loading={waitRequest.updateProfile}
                                        disabled={waitRequest.disableButtons || !profileDataChanged}
                                    >
                                        {capitalize(t('MRS_UpdateProfile'))}
                                    </ButtonLoad>
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>

                    <IonCol size="12" sizeLg="6">
                        <IonCard className={isMobile() ? ' isMobile' : ''}>
                            <IonCardHeader>
                                <IonCardTitle>{capitalize(t('MRS_ChangePassword'))}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonItem lines={isMobile() ? 'full' : 'none'}>
                                    <IonLabel position="stacked">{capitalize(t('MRS_CurrentPassword'))}</IonLabel>
                                    <IonInput
                                        type="password"
                                        autocomplete="current-password"
                                        placeholder={capitalize(t('MRS_CurrentPassword'))}
                                        value={profile?.currentPassword}
                                        clearOnEdit={false}
                                        security="password"
                                        onIonChange={(ev: any) =>
                                            checkAndUpdateProfile(
                                                profile?.currentPassword,
                                                { currentPassword: ev.target.value },
                                                'currentPassword'
                                            )
                                        }
                                    ></IonInput>
                                </IonItem>
                                <IonItem lines={isMobile() ? 'full' : 'none'}>
                                    <IonLabel position="stacked">{capitalize(t('MRS_NewPassword'))}</IonLabel>
                                    <IonInput
                                        type="password"
                                        placeholder={capitalize(t('MRS_NewPassword'))}
                                        value={profile?.newPassword}
                                        clearOnEdit={false}
                                        onIonChange={(ev: any) =>
                                            checkAndUpdateProfile(
                                                profile?.newPassword,
                                                { newPassword: ev.target.value },
                                                'newPassword'
                                            )
                                        }
                                    ></IonInput>
                                </IonItem>
                                <IonItem lines={isMobile() ? 'full' : 'none'}>
                                    <IonLabel position="stacked">{capitalize(t('MRS_RepeatPassword'))}</IonLabel>
                                    <IonInput
                                        type="password"
                                        placeholder={capitalize(t('MRS_RepeatPassword'))}
                                        value={profile?.repeatPassword}
                                        clearOnEdit={false}
                                        onIonChange={(ev: any) =>
                                            checkAndUpdateProfile(
                                                profile?.repeatPassword,
                                                { repeatPassword: ev.target.value },
                                                'repeatPassword'
                                            )
                                        }
                                    ></IonInput>
                                </IonItem>
                                <IonItem lines="none">
                                    <ButtonLoad
                                        className="apply-button-wrapper"
                                        onClick={onChangePassword}
                                        loading={waitRequest.changePassword}
                                        disabled={waitRequest.disableButtons || !profilePasswordChanged}
                                    >
                                        {capitalize(t('MRS_ChangePassword'))}
                                    </ButtonLoad>
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    );
};

export default Profile;
